import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { Character } from "../../types";
import CharacterTrending from "../Character/CharacterTrending";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
interface ExploreGridProps {
  characters: Character[];
  onLoadMore: () => void;
  handleCharacterSelect: (char: Character) => void;
}
export default function ExploreGrid({
  characters,
  onLoadMore,
  handleCharacterSelect,
}: ExploreGridProps) {
  const [gridXsValue, setGridXsValue] = useState<number>(1.7);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 450) {
        setGridXsValue(6); // Fewer items per page on very small screens
      } else if (window.innerWidth >= 450 && window.innerWidth < 768) {
        setGridXsValue(4); // Fewer items per page on small screens
      } else {
        setGridXsValue(2); // More items per page on larger screens
      }
    };

    // Set initial items per page based on current screen width
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      <Grid container spacing={2}>
        {characters.map((character) => (
          <Grid item xs={gridXsValue} key={character.character_id}>
            <CharacterTrending
              explore={true}
              character={character}
              key={character.character_id}
              theme={false}
              onClick={() => handleCharacterSelect(character)}
            />
          </Grid>
        ))}
      </Grid>
      {characters.length > 0 && (
        <Button className="load-more-button" onClick={onLoadMore}>
          Load More
        </Button>
      )}
    </div>
  );
}
