import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from "react";
import axios, { CancelTokenSource } from "axios";
import { RequestName } from "../apis/types";

export enum RequestStatus {
  LOADING = "loading",
  SUCCESS = "success",
  ERROR = "error",
}

type RequestInfo<T> = {
  status: RequestStatus;
  response?: T;
  cancelTokenSource?: CancelTokenSource;
};

type RequestContextContent = {
  addRequest: (
    key: RequestName,
    promise: Promise<any>,
    cancelTokenSource: CancelTokenSource
  ) => void;
  cancelRequest: (key: RequestName) => void;
  request: (key: RequestName) => RequestInfo<any> | undefined;
};

const RequestContext = createContext<RequestContextContent>({
  addRequest: () => {},
  cancelRequest: () => {},
  request: () => undefined,
});

export const useRequestContext = () => useContext(RequestContext);

export const RequestContextProvider = ({ children }: PropsWithChildren<{}>) => {
  const [requests, setRequests] = useState<Map<RequestName, RequestInfo<any>>>(
    new Map()
  );

  const addRequest = useCallback(
    (
      key: RequestName,
      promise: Promise<any>,
      cancelTokenSource: CancelTokenSource
    ) => {
      setRequests(
        (prevRequests) =>
          new Map(
            prevRequests.set(key, {
              status: RequestStatus.LOADING,
              cancelTokenSource,
            })
          )
      );

      promise
        .then((response) => {
          setRequests(
            (prevRequests) =>
              new Map(
                prevRequests.set(key, {
                  status: RequestStatus.SUCCESS,
                  response,
                })
              )
          );
        })
        .catch((error) => {
          setRequests(
            (prevRequests) =>
              new Map(prevRequests.set(key, { status: RequestStatus.ERROR }))
          );
          if (!axios.isCancel(error)) {
            console.error("Request cancelled:", error);
          }
          console.error("Request error", error);
        });
    },
    []
  );

  const cancelRequest = (key: RequestName) => {
    const requestInfo = requests.get(key);
    if (requestInfo && requestInfo.cancelTokenSource) {
      requestInfo.cancelTokenSource.cancel("Request cancelled by user");
      setRequests((prevRequests) => {
        const newRequests = new Map(prevRequests);
        newRequests.delete(key);
        return newRequests;
      });
    }
  };

  const request = useCallback(
    (key: RequestName): RequestInfo<any> | undefined => {
      return requests.get(key);
    },
    [requests]
  );

  return (
    <RequestContext.Provider value={{ request, addRequest, cancelRequest }}>
      {children}
    </RequestContext.Provider>
  );
};

export default RequestContextProvider;
