import React, { useState, useEffect, useContext, useCallback, FC } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import SelectorCharacterCard from "./SelectorCharacterCard";
import { Character } from "../../types";
import { PlatformContext } from "../../contexts/PlatformContext";
import { useAuth0 } from "@auth0/auth0-react";
import ArrowBackIosNewRounded from "@mui/icons-material/ArrowBackIosNewRounded";
import { Button } from "@mui/material";
import BotcastCharacterSelector from "./BotcastCharacterSelector";
import "./Botcasts.css";
import "../styles.css";

export interface CreateBotcastProps {
  character: Character;
}
export interface SelectorProps {
  image_url: string;
  text: string;
  character_id: string;
}
export interface CharacterSubProps {
  character_id: string;
  image_url: string;
  name: string;
}

interface CharacterIDs {
  character_id1: string;
  character_id2: string;
}

export type SelectedCharacters = {
  [character_id: string]: number | null;
};
function CreateBotcast() {
  //   const [characters, setCharacters] = useState<Character[]>([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [generateDisabled, setGenerateDisabled] = useState<boolean>(true);
  const [itemsPerPage, setItemsPerPage] = useState<number>(18); // default value
  const [image_url1, setImage_url1] = useState<string>(
    "https://4thwall-assets.s3.amazonaws.com/placeholder_botcast.png"
  );
  const [image_url2, setImage_url2] = useState<string>(
    "https://4thwall-assets.s3.amazonaws.com/placeholder_botcast.png"
  );
  const [text1, setText1] = useState<string>("");
  const [text2, setText2] = useState<string>("");
  const [character_ids, setCharacter_ids] = useState<CharacterIDs>({
    character_id1: "",
    character_id2: "",
  });

  const [selectedCharacters, setSelectedCharacters] =
    useState<SelectedCharacters>({});
  const navigate = useNavigate();

  const handleCharacterSelect = (char: CharacterSubProps) => {
    console.log("selected/deselected character:", char);
    setCharacter_ids((prevState) => {
      // Destructure the current state for clarity
      const { character_id1, character_id2 } = prevState;

      // Check if the incoming character's id matches one of the existing ids
      if (char.character_id === character_id1) {
        setImage_url1(image_url2);
        setText1(text2);
        setImage_url2(
          "https://4thwall-assets.s3.amazonaws.com/placeholder_botcast.png"
        );
        setText2("");
        console.log("character_id1:", character_id1);
        return { character_id1: prevState.character_id2, character_id2: "" }; // Or handle as necessary for your use case
      } else if (char.character_id === character_id2) {
        setImage_url2(
          "https://4thwall-assets.s3.amazonaws.com/placeholder_botcast.png"
        );
        setText2("");
        console.log("character_id2:", character_id2);
        return { ...prevState, character_id2: "" }; // Or handle as necessary for your use case
      } else {
        // Assign to character_id1 if it's empty
        if (character_id1 === "") {
          setImage_url1(char.image_url);
          setText1(char.name);
          return { character_id1: char.character_id, character_id2 };
        } else if (character_id2 === "") {
          setImage_url2(char.image_url);
          setText2(char.name);
          return { ...prevState, character_id2: char.character_id };
        } else {
          return prevState;
        }
      }
    });
    setSelectedCharacters((prevSelected) => {
      const isSelected = prevSelected[char.character_id];
      const alreadySelected = Object.values(prevSelected).filter(Boolean);

      if (isSelected) {
        // Deselect the character
        const { [char.character_id]: _, ...remainingSelected } = prevSelected;
        // Update other character's selection number if necessary
        Object.entries(remainingSelected).forEach(([id, num]) => {
          if (num && num > isSelected) {
            remainingSelected[id] = num - 1;
          }
        });
        return remainingSelected;
      } else if (alreadySelected.length < 2) {
        // Select a new character with next number
        return {
          ...prevSelected,
          [char.character_id]: alreadySelected.length + 1,
        };
      }
      return prevSelected;
    });
  };

  // Getting characters using context;
  const context = useContext(PlatformContext);
  if (!context) {
    throw new Error(
      "useContext must be used within a CharactersContext.Provider"
    );
  }

  // Now proceed with the logic using combinedCharacters instead of characters
  // const filteredCharacters = combinedCharacters.filter((char: Character) =>
  //   char.name.toLowerCase().includes(searchTerm.toLowerCase())
  // );

  // const totalPages = Math.ceil(filteredCharacters.length / itemsPerPage);

  // const displayedCharacters = filteredCharacters.slice(
  //   (currentPage - 1) * itemsPerPage,
  //   currentPage * itemsPerPage
  // );

  const placeholder_image_url =
    "https://4thwall-assets.s3.amazonaws.com/placeholder_botcast.png";

  return (
    <div className="background">
      <div style={{ marginBottom: "10%" }}>
        {/* <Header /> */}

        <div className="selected-section">
          <h1>
            <ArrowBackIosNewRounded
              onClick={() => navigate("/")}
              style={{ cursor: "pointer", transition: "transform 0.2s" }}
              onMouseOver={(e) =>
                (e.currentTarget.style.transform = "scale(1.2)")
              } // Scale up on hover
              onMouseOut={(e) => (e.currentTarget.style.transform = "scale(1)")}
            />
            Create a Botcast
          </h1>
          <div className="character-select-placeholders">
            <SelectorCharacterCard
              handleCharacterDeselect={handleCharacterSelect}
              data={{
                image_url: image_url1,
                text: text1,
                character_id: character_ids.character_id1,
              }}
            />
            {/* <AddIcon className="plus-icon" /> */}
            <Button
              style={{
                backgroundColor: "var(--button-color)",
                color: "var(--primary-text-color)",
                borderRadius: "1rem",
                fontFamily: "Transducer Regular",
                fontSize: "1rem",
                fontWeight: "bold",
                marginTop: "5rem",
                width: "5rem",
                cursor: "pointer",
                marginBottom: "5rem",
              }}
              disabled={
                image_url1 === placeholder_image_url ||
                image_url2 === placeholder_image_url
              }
              className="generate-button"
              variant="contained"
              onClick={() =>
                navigate(
                  `/botcast/view/${character_ids.character_id1}/${character_ids.character_id2}`
                )
              }
            >
              NEXT
            </Button>
            <SelectorCharacterCard
              handleCharacterDeselect={handleCharacterSelect}
              data={{
                image_url: image_url2,
                text: text2,
                character_id: character_ids.character_id1,
              }}
            />
          </div>
        </div>
        <BotcastCharacterSelector
          handleCharacterSelect={handleCharacterSelect}
          selectedCharacters={selectedCharacters}
        />
        {/* <div className="main-section">
        <h2>Choose 2 characters to create a botcast!</h2>
        <div className="search-bar">
          <PersonSearchIcon className="search-icon" />
          <DebounceInput
            debounceTimeout={300}
            type="text"
            placeholder="Search for a character..."
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="characters-grid">
          {displayedCharacters.map((char: Character) => (
            <div
              className={`choose-character-card ${
                selectedCharacters[char.character_id] ? "selected" : ""
              }`}
              key={char.character_id}
              onClick={() => handleCharacterSelect(char)}
            >
              <CharacterImage image_url={char.image_url} name={char.name} />
              <p className="choose-char-info">{char.name}</p>
              {selectedCharacters[char.character_id] && (
                <span className="subscript">
                  {selectedCharacters[char.character_id]}
                </span>
              )}
            </div>
          ))}
        </div>
        {totalPages > 1 && (
          <div className="pagination">
            <Stack spacing={2}>
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={(event, value) => setCurrentPage(value)}
                showFirstButton
                showLastButton
                siblingCount={1}
                boundaryCount={0}
                color="primary"
              />
            </Stack>
            {/* {Array.from({ length: totalPages }).map((_, index) => (

              // <button
              //   className={`pagination-button ${
              //     currentPage === index + 1 ? "active" : ""
              //   }`}
              //   key={index}
              //   onClick={() => setCurrentPage(index + 1)}
              // >
              //   {index + 1}
              // </button>
            ))} 
          </div>
        )}
      </div> */}
        {/* <Footer /> */}
      </div>
    </div>
  );
}

export default CreateBotcast;
