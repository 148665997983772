import React, {
  useRef,
  useState,
  useEffect,
  FC,
  forwardRef,
  useContext,
} from "react";
import { motion } from "framer-motion";
import { PlatformContext } from "../../contexts/PlatformContext";
import { Swiper as SwiperInstance } from "swiper/types"; // Importing the type for Swiper instance
import { Swiper, SwiperSlide } from "swiper/react";
import {
  FreeMode,
  Mousewheel,
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Virtual,
} from "swiper/modules";
import "swiper/swiper-bundle.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "swiper/swiper-bundle.css";
import { MapName } from "../../data/maps";
interface FeaturedSwiperProps {
  maps: { name: string; image: string; mapName: MapName }[];
  handleMapSelect: (map: {
    name: string;
    image: string;
    mapName: MapName;
  }) => void;
  username?: string;
}
type ArrowProps = React.HTMLAttributes<HTMLButtonElement>;

const CustomPrevArrow = forwardRef<HTMLButtonElement, ArrowProps>(
  (props, ref) => (
    <button ref={ref} className="custom-swiper-button-prev" {...props}>
      &#10094; {/* Left arrow symbol */}
    </button>
  )
);

const CustomNextArrow = forwardRef<HTMLButtonElement, ArrowProps>(
  (props, ref) => (
    <button ref={ref} className="custom-swiper-button-next" {...props}>
      &#10095; {/* Right arrow symbol */}
    </button>
  )
);

const PLAYAI_ALLOWED_USERS = [
  "gokul8967",
  "joemama",
  "shreyko",
  "ramz",
  "dtsines",
];
CustomPrevArrow.displayName = "CustomPrevArrow";
CustomNextArrow.displayName = "CustomNextArrow";

export const MapCard = ({
  map,
  onClick,
  selectedMap,
}: {
  map: { name: string; image: string };
  onClick: () => void;
  selectedMap: { name: string; image: string } | null;
}) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -10 }}
      transition={{ duration: 0.3 }}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      onClick={onClick}
      className="cursor-pointer w-[140px] sm:w-[180px] relative rounded-2xl"
    >
      <div
        className={`
          aspect-[16/9] w-full h-full rounded-2xl
          box-border border-2 sm:border-3 border-solid
          ${selectedMap?.name === map.name ? "border-orange-500" : "border-black/40"}
        `}
        style={{
          backgroundImage: `url(${map.image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div
          className={`
            bg-black bg-opacity-40
            w-full h-full
            flex justify-center items-center
            rounded-2xl
            text-white text-xs sm:text-sm
            font-main font-bold
          `}
        >
          {map.name}
        </div>
      </div>
    </motion.div>
  );
};
function FeaturedSwiper({ maps, handleMapSelect }: FeaturedSwiperProps) {
  const [slidesPerGroup, setSlidesPerGroup] = useState(1);
  const [selectedMap, setSelectedMap] = useState<{
    name: string;
    image: string;
  } | null>({
    name: "Halloween Town",
    image: "/halloween.png",
  });
  useEffect(() => {
    const updateSlidesPerGroup = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setSlidesPerGroup(2);
      } else if (width < 1024) {
        setSlidesPerGroup(3);
      } else {
        setSlidesPerGroup(5);
      }
    };

    updateSlidesPerGroup();
    window.addEventListener("resize", updateSlidesPerGroup);

    return () => {
      window.removeEventListener("resize", updateSlidesPerGroup);
    };
  }, []);
  const onMapSelect = (map: {
    name: string;
    image: string;
    mapName: MapName;
  }) => {
    setSelectedMap(map);
    handleMapSelect(map);
  };
  const prevRef = useRef<HTMLButtonElement>(null);
  const nextRef = useRef<HTMLButtonElement>(null);
  const context = useContext(PlatformContext);
  if (!context) {
    throw new Error("MapCard must be used within a PlatformContext");
  }
  const { username } = context;

  const filteredMaps = maps.filter((map) => {
    if (map.mapName === "playai") {
      return username && PLAYAI_ALLOWED_USERS.includes(username);
    }
    return true;
  });

  return (
    <div>
      <div className="flex items-center">
        <h3 className="font-main text-white">Choose a map</h3>
      </div>

      <Swiper
        modules={[
          FreeMode,
          Mousewheel,
          Navigation,
          Pagination,
          Scrollbar,
          A11y,
          Virtual,
        ]}
        spaceBetween={14}
        slidesPerView={"auto"}
        touchRatio={1}
        speed={600}
        mousewheel={{
          forceToAxis: true,
          releaseOnEdges: true,
        }}
        freeMode={true}
        slidesPerGroup={2}
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        }}
        onBeforeInit={(swiper: SwiperInstance) => {
          if (typeof swiper.params.navigation !== "boolean") {
            // Ensure it's not the boolean 'false'
            (swiper.params.navigation as any).prevEl = prevRef.current;
            (swiper.params.navigation as any).nextEl = nextRef.current;
          }
        }}
        className="mySwiper"
        touchEventsTarget="container"
        simulateTouch={true}
        threshold={5}
      >
        <CustomPrevArrow ref={prevRef} />
        <CustomNextArrow ref={nextRef} />
        {filteredMaps.map((map) => (
          <SwiperSlide
            key={map.name}
            onClick={() => onMapSelect(map)}
            className="my-1"
          >
            <MapCard
              map={map}
              onClick={() => onMapSelect(map)}
              selectedMap={selectedMap}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default FeaturedSwiper;
