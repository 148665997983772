import { useState, FC, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";
import { useAuth0 } from "@auth0/auth0-react";
import { PlatformContext } from "../contexts/PlatformContext";
import { Avatar } from "@mui/joy";
import Loading from "../common/Loading";
import { Character, Chat } from "../types";
import FeaturedSwiper from "./HomepageComponents/FeaturedSwiper";
import Explore from "./HomepageComponents/Explore";
import { Button } from "@mui/joy";
import NewsCarousel from "./HomepageComponents/NewsCarousel";
import SearchBar from "./HomepageComponents/SearchBar";
import Welcome from "./HomepageComponents/Welcome";
import { RevealBento } from "./HomepageComponents/LandingBento";

export interface CharacterGroup {
  character: Chat;
  chats: Chat[];
}

const HomePage: FC = () => {
  const context = useContext(PlatformContext);
  if (!context) {
    throw new Error(
      "useContext must be used within a CharactersContext.Provider"
    );
  }
  const { featuredLists, username } = context;
  const isMobile = window.innerWidth < 768;
  const isDynamicTheme = false;

  const [activeTab, setActiveTab] = useState<string>("featured");
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const navigate = useNavigate();

  const handleCharacterSelect = (character: Character) => {
    if (!isAuthenticated) {
      loginWithRedirect({
        appState: {
          returnTo: `/chat/${character.character_id}`,
        },
      });
    }
    navigate(`/chat/${character.character_id}`);
  };

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    document.title = "4Wall AI | Chat with your favorite characters!";
  }, []);

  if (isLoading) {
    return <Loading />;
  }
  const headerAreaDesktop = (
    <>
      {/* {!isAuthenticated && (
        <div className="w-full h-12 flex justify-between ">
          <div className="flex  items-center  space-x-2">
            <h2
              onClick={() => navigate("/")}
              className="cursor-pointer font-main text-[var(--primary-text-color)]  hover:text-fourwall-orange items-center"
            >
              4WALL AI{" "}
            </h2>
            <Button
              className="!bg-white !text-black !rounded-full !px-5 !py-2  !font-main"
              onClick={() => loginWithRedirect()}
            >
              Login
            </Button>
            <Button
              className="!bg-fourwall-orange text-white !rounded-full !p-2 !px-5 !font-main "
              onClick={() =>
                loginWithRedirect({
                  authorizationParams: {
                    screen_hint: "signup",
                  },
                })
              }
            >
              Sign Up
            </Button>
          </div>
          {!isMobile && <SearchBar />}
        </div>
      )} */}
      {isAuthenticated && (
        <div className="w-full h-12 flex justify-end m-1 items-end">
          <SearchBar />
        </div>
      )}
    </>
  );
  const headerAreaMobile = (
    <>
      <div className="w-full h-10 flex justify-end">
        {/* <h2 className="text-white">Welcome, {username}</h2> */}
        {isAuthenticated ? (
          <SearchBar />
        ) : (
          <div className="flex items-center space-x-2">
            <Button
              className="!bg-white !text-black !rounded-full !px-5 !py-2  !font-main"
              onClick={() => loginWithRedirect()}
            >
              Login
            </Button>
            <Button
              className="!bg-fourwall-orange text-white !rounded-full !p-2 !px-5 !font-main "
              onClick={() =>
                loginWithRedirect({
                  authorizationParams: {
                    screen_hint: "signup",
                  },
                })
              }
            >
              Sign Up
            </Button>
          </div>
        )}
      </div>
    </>
  );
  const mobileContainer = (
    <>
      <FeaturedSwiper
        featuredCharacters={Object.values(featuredLists)
          .flat()
          .sort(() => Math.random() - 0.5)}
        name="FEATURED"
        loaded={true}
        handleCharacterSelect={handleCharacterSelect}
      />
      <RevealBento />

      {/* <NewsCarousel /> */}

      <Explore
        handleCharacterSelect={handleCharacterSelect}
        isDynamicTheme={isDynamicTheme}
      />
    </>
  );

  if (!isAuthenticated) {
    return <Welcome />;
  }
  return (
    <div>
      <div className="flex flex-col">
        <div className="flex flex-col p-2 overflow-x-hidden">
          {isMobile ? headerAreaMobile : headerAreaDesktop}
          <div className={`${isMobile ? "p-0" : "p-3"}`}>{mobileContainer}</div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default HomePage;
