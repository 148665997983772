import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

interface AppState {
  returnTo?: string;
}

const AuthHandler: React.FC = () => {
  const {
    isAuthenticated,
    getIdTokenClaims,
    getAccessTokenSilently,
    handleRedirectCallback,
    isLoading,
  } = useAuth0();
  const navigate = useNavigate();
  const [appState, setAppState] = useState<AppState | null>(null);

  // useEffect(() => {s  ss
  //   if (!isLoading) {
  //     handleRedirectCallback()
  //       .then((redirectResult) => {
  //         setAppState(redirectResult.appState);
  //       })
  //       .catch((error) => {
  //         console.error("Error during redirect callback handling:", error);
  //       });
  //   }
  // }, [handleRedirectCallback, isLoading]);

  useEffect(() => {
    const handleRedirection = async () => {
      if (isAuthenticated) {
        const claims = await getIdTokenClaims();
        const isNewUser =
          claims && claims["https://chat.4wall.ai/new_social_user"];
        const newUserName = claims && claims["https://chat.4wall.ai/username"];
        if (isNewUser || !newUserName) {
          navigate("/username", { state: { returnTo: appState?.returnTo } });
        }
      }
    };

    handleRedirection();
  }, [isAuthenticated, getIdTokenClaims, getAccessTokenSilently, navigate]);

  return <></>;
};

export default AuthHandler;
