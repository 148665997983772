import { useEffect } from "react";

const useBackgroundUpdate = () => {
  const updateBackground = () => {
    const hour = new Date().getHours();
    let imagePath, mobileImagePath;

    const isLocalhost =
      window.location.hostname === "localhost" ||
      window.location.hostname === "127.0.0.1";

    const baseUrl = isLocalhost ? "" : "/"; // No need for full URL if the static files are accessible from the root

    if (hour >= 5 && hour < 12) {
      imagePath = `${baseUrl}background_images/desert_wide.png`;
      mobileImagePath = `${baseUrl}background_images/desert_long.png`;
    } else if (hour >= 12 && hour < 17) {
      imagePath = `${baseUrl}background_images/ruins_wide.png`;
      mobileImagePath = `${baseUrl}background_images/ruins_long.png`;
    } else if (hour >= 17 && hour < 20) {
      imagePath = `${baseUrl}background_images/knight_wide.png`;
      mobileImagePath = `${baseUrl}background_images/knight_long.png`;
    } else {
      imagePath = `${baseUrl}background_images/comet_wide.png`;
      mobileImagePath = `${baseUrl}background_images/comet_long.png`;
    }

    document.documentElement.style.setProperty("--image", `url(${imagePath})`);
    document.documentElement.style.setProperty(
      "--image-mobile",
      `url(${mobileImagePath})`
    );
  };

  useEffect(() => {
    updateBackground();
    const interval = setInterval(updateBackground, 60000); // Update every minute
    return () => clearInterval(interval);
  }, []);
};

export default useBackgroundUpdate;
