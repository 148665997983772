export enum RequestMethod {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
}

export enum RequestName {
  GET_USER = "GET_USER",
  GET_USER_ASSETS = "GET_USER_ASSETS",
  GET_CHAT_MESSAGES = "GET_CHAT_MESSAGES",
  GET_BOTCAST_MESSAGES = "GET_BOTCAST_MESSAGES",
  GET_CHAT_METADATA = "GET_CHAT_METADATA",
  DELETE_CHAT = "DELETE_CHAT",
  GET_FEATURED_CHARACTERS = "GET_FEATURED_CHARACTERS",
  UPDATE_MESSAGE = "UPDATE_MESSAGE",
  DELETE_MESSAGE = "DELETE_MESSAGE",
  GET_CHARACTER = "GET_CHARACTER",
  GET_TOWN = "GET_TOWN",
  GET_TOWNS = "GET_TOWNS",
  CREATE_TOWN = "CREATE_TOWN",
  UPDATE_TOWN = "UPDATE_TOWN",
  GET_TOWN_CHARACTER_DATA = "GET_TOWN_CHARACTER_DATA",
  DELETE_TOWN = "DELETE_TOWN",
  DELETE_USER = "DELETE_USER",
  CREATE_PERSONA = "CREATE_PERSONA",
  UPDATE_PERSONA = "UPDATE_PERSONA",
  GET_PERSONA = "GET_PERSONA",
  DELETE_PERSONA = "DELETE_PERSONA",
  ADD_TO_WAITLIST = "ADD_TO_WAITLIST",
  GET_USER_WAITLIST = "GET_USER_WAITLIST",
  GET_WAITLIST_ACCESS = "GET_WAITLIST_ACCESS",
  UPLOAD_IMAGE = "UPLOAD_IMAGE",
  CREATE_CHARACTER = "CREATE_CHARACTER",
  UPDATE_CHARACTER = "UPDATE_CHARACTER",
  DELETE_CHARACTER = "DELETE_CHARACTER",
  UPDATE_SCENARIO = "UPDATE_SCENARIO",
  AUX_LLM = "AUX_LLM",
  CHARACTER_SEARCH = "CHARACTER_SEARCH",
}

export type RequestDefinition = {
  name: RequestName;
  path: string;
  method: RequestMethod;
  queryParams?: { [key: string]: string | number | undefined };
  shouldChangeContext: boolean;
  stubAlways?: boolean;
};
