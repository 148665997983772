import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Login from "../Auth/Login";

interface SignupWallProps {
  setShowSignupWall: (show: boolean) => void;
}

function SignupWall({ setShowSignupWall }: SignupWallProps) {
  const [text, setPopupText] = useState<string>("");
  return (
    <div className="popup-overlay">
      <div className="supwall-popup-content">
        <button
          className="popup-close-button"
          onClick={() => {
            setShowSignupWall(false);
            setPopupText("We're coming right up with more awesome features!");
          }}
        >
          X
        </button>
        {/* <h1>{text}</h1> */}
        <h3>Log in to create characters!</h3>
        <Login redirectUrl="/create" />
      </div>
    </div>
  );
}

export default SignupWall;
