import React from "react";
import { Textarea, FormControl, FormLabel, FormHelperText } from "@mui/joy";
import InfoBubble from "./InfoBubble";

interface InfoInputProps {
  infoText: string;
  label: string;
  charKey: string;
  size: number;
  maxLength: number;
  value: string | undefined;
  subText: string;
  onChange: (value: string) => void;
  errors: { [key: string]: boolean };
}

const InfoInput: React.FC<InfoInputProps> = ({
  infoText,
  label,
  charKey,
  size,
  maxLength,
  value,
  subText,
  onChange,
  errors,
}) => {
  return (
    <FormControl
      className="!mx-1 !my-3 !font-main !text-white "
      error={errors[charKey]}
    >
      <FormLabel className=" !text-lg !font-main !font-bold !text-white ">
        {label}
        {infoText !== "" && <InfoBubble title={label} infoText={infoText} />}
        {/* <p className="!text-orange-100 !text-xs mx-1">{subText}</p> */}
      </FormLabel>

      <Textarea
        className="!text-white !bg-neutral-700 !shadow-md "
        color="warning"
        minRows={size}
        maxRows={size}
        placeholder=""
        variant="soft"
        value={value}
        onInput={(e) => {
          const target = e.target as HTMLTextAreaElement;
          target.value = target.value.slice(0, maxLength);
        }}
        onChange={(e) => onChange(e.target.value)}
      ></Textarea>
      <FormHelperText className="!text-orange-100">{`${value?.length}/${maxLength}`}</FormHelperText>
      {errors[charKey] && (
        <FormHelperText className="!text-red-500">
          {label} cannot be empty
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default InfoInput;
