import React from "react";
import {
  Input,
  Box,
  List,
  ListItem,
  ListItemDecorator,
  ListItemContent,
  CircularProgress,
  Avatar,
} from "@mui/joy";
import { Search } from "lucide-react";
import { useState, useEffect, useRef, useContext } from "react";
import { Character } from "../../types";
import { PlatformContext } from "../../contexts/PlatformContext";
import { debounce } from "../Misc/SearchOverlay";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { characterSearch } from "../../apis/request";
import useRequest from "../../apis/useRequest";
import * as amplitude from "@amplitude/analytics-browser";
function SearchBar() {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState<Character[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const context = useContext(PlatformContext);
  const makeRequest = useRequest();
  if (!context) {
    throw new Error(
      "useContext must be used within a PlatformContext.Provider"
    );
  }
  const { NSFW, username } = context;

  function parseSearchResults(searchResults: any) {
    let filteredHits = searchResults;
    if (!NSFW) {
      for (const hit of filteredHits) {
        if (hit.tags.includes("NSFW")) {
          filteredHits = filteredHits.filter((h: any) => h !== hit);
        }
      }
    }
    const characters = filteredHits.map((hit: any) => {
      const fields = hit;

      return {
        bio: fields.bio,
        character_id: fields.character_id,
        creator: fields.creator,
        name: fields.name,
        image_url: `${process.env.REACT_APP_ASSETS_BUCKET}${fields.character_id}/display_picture.jpg`,
        chat_background_url: `${process.env.REACT_APP_ASSETS_BUCKET}${fields.character_id}/chat_background.jpg`,
        interactions: fields.num_interactions,
        tags: fields.tags,
      };
    });

    return characters;
  }

  const fetchSearchResults = async (value: string) => {
    setIsLoading(true);
    try {
      const response = await makeRequest<any, any>(characterSearch(), {
        query: value,
        NSFW: NSFW,
      });

      const newCharacters = parseSearchResults(response.results);
      if (response.name_match_failed === true) {
        amplitude.track("Empty Search Results", {
          searchTerm: value,
          username: username,
        });
      }
      setSearchResults(newCharacters);
      setIsLoading(false);

      amplitude.track("Search Term", {
        searchTerm: value,
        username: username,
      });
    } catch (error) {
      console.error("Search error:", error);
      setIsLoading(false);
    }
  };

  const debouncedSearch = useRef(debounce(fetchSearchResults, 300)).current;
  const handleCharacterSelect = (character: Character) => {
    if (!isAuthenticated) {
      loginWithRedirect({
        appState: {
          returnTo: `/chat/${character.character_id}`,
        },
      });
      return;
    }
    navigate(`/chat/${character.character_id}`);
  };
  useEffect(() => {
    if (searchTerm) {
      debouncedSearch(searchTerm);
    } else {
      setSearchResults([]);
    }
  }, [searchTerm]);

  const formatInteractions = (interactions: number) => {
    if (interactions >= 1000000) {
      return (interactions / 1000000).toFixed(1) + "M";
    } else if (interactions >= 1000) {
      return (interactions / 1000).toFixed(1) + "K";
    } else {
      return interactions.toString();
    }
  };

  const handleClose = () => {
    setSearchTerm("");
    setSearchResults([]);
  };

  return (
    <div amp-unmask="true" className="relative h-10 w-64 md:w-96 z-50">
      <Input
        startDecorator={<Search className="text-orange-400" />}
        id="searchCharacters"
        placeholder="Search for Characters"
        type="search"
        fullWidth
        value={searchTerm}
        className="focus:!outline-none !bg-gray-800 !border-orange-400  !z-50 !flex !gap-2 !w-full !max-w-3xl !border-spacing-1 !rounded-3xl !bg-surface-elevation-1 !p-2  focus:!outline-orange-400 !text-orange-100"
        onChange={(e) => setSearchTerm(e.target.value)}
        autoFocus={false}
      />

      {searchResults.length > 0 && searchTerm.length > 0 && (
        <>
          <div
            className="fixed inset-0 bg-black bg-opacity-50 z-40"
            onClick={handleClose}
          ></div>
          <Box className="!rounded-3xl !bg-gray-800 !mt-2 !overflow-y-auto !max-h-[calc(100vh-5rem)] !shadow-lg !z-50 !relative ">
            <List
              sx={{
                "--ListItemDecorator-size": "56px",
                overflowY: "auto",
              }}
              className="!font-chat !overflow-y-auto !scrollbar-thin !scrollbar-thumb-[var(--orange-brand-accent)] !scrollbar-track-transparent space-y-2 !p-1"
            >
              {isLoading ? (
                <CircularProgress size="md" thickness={4} />
              ) : (
                searchResults.map((character) => (
                  <ListItem
                    key={character.character_id}
                    className="p-1 hover:bg-gray-700 transition-colors duration-100 cursor-pointer !rounded-xl !m-2"
                    onClick={() => {
                      navigate(`/chat/${character.character_id}`);
                      handleClose();
                    }}
                  >
                    <ListItemDecorator>
                      <Avatar
                        size="lg"
                        alt={character.name}
                        src={`${process.env.REACT_APP_ASSETS_BUCKET}${character.character_id}/display_picture.jpg`}
                      />
                    </ListItemDecorator>
                    <ListItemContent className="space-y-1 ">
                      <p className="text-orange-100 m-0">{character.name}</p>
                      <p className="text-orange-200 text-xs line-clamp-2">
                        {character.bio}
                      </p>
                      <p
                        className="text-orange-400 text-xs cursor-pointer hover:scale-105 transition-transform"
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(`/profile/${character.creator}`);
                          handleClose();
                        }}
                      >
                        {formatInteractions(character.interactions)} chats @
                        {character.creator}
                      </p>
                    </ListItemContent>
                  </ListItem>
                ))
              )}
            </List>
          </Box>
        </>
      )}
    </div>
  );
}

export default SearchBar;
