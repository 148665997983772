import React, { useState, useEffect } from "react";
import { useQuery } from "convex/react";
import { api } from "../../convex/_generated/api";
import { Id } from "../../convex/_generated/dataModel";
import { useSendInput } from "../../hooks/sendInput";
import { GameId } from "../../convex/aiTown/ids";
import { Button } from "@mui/joy";
import { SelectElement } from "./Player";
import { ServerGame } from "../../hooks/serverGame";
import { toastOnError } from "../../toasts";
import { StyledAvatar } from "./common/styledAvatar";
import * as amplitude from "@amplitude/analytics-browser";

interface InvitePopupProps {
  worldId: Id<"worlds">;
  engineId: Id<"engines">;
  game: ServerGame;
}

export function InvitePopup({ worldId, engineId, game }: InvitePopupProps) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const players = [...game.world.players.values()];
  const humanPlayer = players.find((p) => p.human);
  const humanConversation = humanPlayer
    ? game.world.playerConversation(humanPlayer)
    : undefined;

  const invitingPlayer = players.find((player) => {
    if (player.id === humanPlayer?.id) return false;
    const playerConversation = game.world.playerConversation(player);
    const sameConversation =
      humanPlayer &&
      humanConversation &&
      playerConversation &&
      humanConversation.id === playerConversation.id;
    const humanStatus =
      humanPlayer &&
      humanConversation?.participants.get(humanPlayer.id)?.status;
    return sameConversation && humanStatus?.kind === "invited";
  });

  const acceptInvite = useSendInput(engineId, "acceptInvite");
  const rejectInvite = useSendInput(engineId, "rejectInvite");

  if (!invitingPlayer || !humanPlayer || !humanConversation) {
    return null;
  }

  const playerDescription = game.playerDescriptions.get(invitingPlayer.id);

  if (!playerDescription) {
    return null;
  }

  const onAcceptInvite = async () => {
    amplitude.track("Accept Invite Clicked", {
      town_id: worldId,
      player_id: invitingPlayer.id,
      origin: "popup",
    });
    await toastOnError(
      acceptInvite({
        playerId: humanPlayer.id,
        conversationId: humanConversation.id,
      })
    );
  };

  const onRejectInvite = async () => {
    amplitude.track("Reject Invite Clicked", {
      town_id: worldId,
      player_id: invitingPlayer.id,
      origin: "popup",
    });
    await toastOnError(
      rejectInvite({
        playerId: humanPlayer.id,
        conversationId: humanConversation.id,
      })
    );
  };

  return (
    <div
      className={`fixed ${isMobile ? "top-2 left-2 right-2" : "top-4 left-1/2 transform -translate-x-1/2"} bg-neutral-900/90 flex flex-col text-white p-4 rounded-xl shadow-lg z-50 font-main`}
    >
      <div
        className={`flex ${isMobile ? "flex-col" : "flex-row"} justify-center items-center space-y-2 sm:space-y-0 sm:space-x-2`}
      >
        <StyledAvatar
          size={isMobile ? "small" : "medium"}
          src={`${process.env.REACT_APP_ASSETS_BUCKET}${playerDescription.character_id}/display_picture.jpg`}
          alt={playerDescription.name}
        />
        <p className={`${isMobile ? "text-center" : "mb-2"}`}>
          {playerDescription.name} has invited you to a conversation!
        </p>
      </div>
      <div
        className={`flex justify-center ${isMobile ? "flex-col" : "flex-row"} space-y-2 sm:space-y-0 sm:space-x-2 font-main mt-4`}
      >
        <Button color="success" onClick={onAcceptInvite} fullWidth={isMobile}>
          Accept
        </Button>
        <Button color="danger" onClick={onRejectInvite} fullWidth={isMobile}>
          Reject
        </Button>
      </div>
    </div>
  );
}

export default InvitePopup;
