import React, { useState, useContext, useEffect } from "react";
import { Card, CardContent, Typography } from "@mui/joy";
import { Trash2, Ellipsis, CircleEllipsis } from "lucide-react";
import { deleteTown } from "../../apis/request";
import useRequest from "../../apis/useRequest";
import { api } from "../../convex/_generated/api";
import { useMutation, useQuery } from "convex/react";
import { PlatformContext } from "../../contexts/PlatformContext";
import { SelectedCharacterProps } from "../../types";
import { MapName } from "../../data/maps";
import {
  Modal,
  Chip,
  ModalDialog,
  ModalClose,
  DialogTitle,
  Button,
  Typography as JoyTypography,
  Menu,
  MenuItem,
  Avatar,
  AvatarGroup,
} from "@mui/joy";
import { Id } from "../../convex/_generated/dataModel";
import { townMaps } from "./TownPage";
import { StyledAvatar } from "../Town/common/styledAvatar";
export interface TownCardProps {
  townName: string;
  townId: string;
  characters: SelectedCharacterProps[];
  map: MapName;
  onClick?: () => void;
  refresh: () => void;
}

function WorldStatusHandleError(townId: Id<"worlds">) {
  try {
    const result = useQuery(api.world.getWorldStatus, {
      worldId: townId,
    });
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
}

function TownCard({
  townName,
  townId,
  characters,
  map,
  onClick,
  refresh,
}: TownCardProps) {
  const makeRequest = useRequest();
  const [openDialog, setOpenDialog] = useState(false);
  const [isRunning, setIsRunning] = useState(false);
  const context = useContext(PlatformContext);
  if (!context) {
    throw new Error("Context not found");
  }
  const { username } = context;
  const archive = useMutation(api.testing.archiveWorld);
  const castTownId: Id<"worlds"> = townId as Id<"worlds">;
  const handleDelete = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setOpenDialog(true);
  };
  const handleConfirmDelete = async (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    const response = await makeRequest<void, void>(
      deleteTown(username, townId)
    );
    await archive({ worldId: castTownId });
    setOpenDialog(false);
    refresh();
  };

  // const [worldStatus, setWorldStatus] = useState<any>(null);

  // useEffect(() => {
  //   const fetchWorldStatus = async () => {
  //     const result = WorldStatusHandleError(castTownId);
  //     console.log("Result", result);
  //     if (result === null || result === undefined) {
  //       setWorldStatus(null);
  //       return;
  //     }
  //     setWorldStatus(result.worldStatus);
  //     if (
  //       result.worldStatus?.status === "inactive" ||
  //       result.worldStatus?.status === "stoppedByDeveloper"
  //     ) {
  //       setIsRunning(false);
  //     } else {
  //       setIsRunning(true);
  //     }
  //     console.log(result);
  //   };

  //   fetchWorldStatus();
  // }, [castTownId]);

  // if (worldStatus === null) {
  //   console.error("Error fetching world status");
  //   return (
  //     <Card
  //       className="!bg-neutral-800 !border-2 !border-neutral-700 !border-solid !rounded-xl flex mr-1 p-0 h-[18rem] w-[19rem] !text-orange-500 overflow-hidden relative
  //     hover:!bg-neutral-700 hover:!cursor-pointer transition-all duration-200 group"
  //     >
  //       <div className="text-white">
  //         If you are on dev, this was probably a local world and vice versa. If
  //         you see this on prod, contact support.
  //       </div>
  //     </Card>
  //   );
  // }
  return (
    <>
      <Card
        className="!bg-neutral-800 !border-2 !border-neutral-700 !border-solid !rounded-xl flex mr-1 p-0 h-[18rem] w-[19rem] !text-orange-500 overflow-hidden relative
      hover:!bg-neutral-700 hover:!cursor-pointer transition-all duration-200 group"
        onClick={onClick}
      >
        <CardContent className="flex flex-col justify-between items-center h-full w-full relative p-4">
          <div className="w-full h-full">
            <div className="relative w-full h-full">
              <img
                src={townMaps.find((townMap) => townMap.mapName === map)?.image}
                className="w-full h-full object-cover rounded-xl"
                alt={`${townName} map`}
              />
              <div
                className="absolute inset-0 rounded-xl"
                style={{
                  background:
                    "linear-gradient(to top, rgba(0,0,0,0.9), transparent)",
                }}
              >
                <h3 className="absolute bottom-4 left-0 right-0 text-center text-white z-20 font-main font-bold m-0">
                  {townName}
                </h3>
              </div>
            </div>
          </div>

          <Trash2
            style={{
              ...styles.trashIcon,
              position: "absolute",
              top: "10px",
              right: "10px",
            }}
            className="bg-red-400 rounded-full p-1 text-red-200 hover:!cursor-pointer hover:!bg-red-500"
            onClick={handleDelete}
          />

          {/* {isRunning ? (
            <Chip
              size="md"
              color="danger"
              variant="outlined"
              style={{
                marginLeft: "auto",
                position: "absolute",
                top: "10px",
                left: "10px",
              }}
            >
              Live
            </Chip>
          ) : (
            <Chip
              size="md"
              color="primary"
              variant="solid"
              style={{
                marginLeft: "auto",
                position: "absolute",
                top: "10px",
                left: "10px",
              }}
            >
              Paused
            </Chip>
          )} */}
          {/* <div className="flex-grow" /> */}

          <AvatarGroup
            size="md"
            className="mt-2 mr-auto"
            slotProps={{
              root: { sx: { ["& .MuiAvatarGroup-avatar"]: { border: 0 } } },
            }}
          >
            {characters.map((character) => (
              <Avatar
                key={character.character_id}
                src={`${process.env.REACT_APP_ASSETS_BUCKET}${character.character_id}/display_picture.jpg`}
                alt={character.name || ""}
              />
            ))}
          </AvatarGroup>
          <p className="text-fourwall-orange font-main font-bold  text-md opacity-0 group-hover:opacity-100 transition-opacity duration-200 absolute bottom-1 right-2 ">
            ENTER
          </p>

          {/* <Button
            onClick={onClick}
            variant="outlined"
            size="sm"
            color="warning"
            className="!border-orange-500 !font-bold !font-main !rounded-full transition-all duration-200 hover:!bg-orange-500 hover:!text-white"
          >
            Enter
          </Button> */}
        </CardContent>
      </Card>
      <Modal open={openDialog} onClose={() => setOpenDialog(false)}>
        <ModalDialog className="!flex !flex-col !bg-neutral-800 !border-neutral-700 !border-solid !border-1 !rounded-xl ">
          <ModalClose />
          <DialogTitle className="!text-white">
            Delete the spot "{townName}" ?
          </DialogTitle>
          <JoyTypography className="!text-white">
            This cannot be undone
          </JoyTypography>
          <div className="flex justify-center gap-2">
            <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
            <Button onClick={handleConfirmDelete} color="danger">
              Delete
            </Button>
          </div>
        </ModalDialog>
      </Modal>
    </>
  );
}
const styles: { [key: string]: React.CSSProperties } = {
  trashIcon: {
    marginLeft: "auto",
    cursor: "pointer",
  },
};
export default TownCard;
