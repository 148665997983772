import React, { useState, useContext, useEffect } from "react";
import {
  List,
  ListItem,
  ListItemDecorator,
  ListSubheader,
  ListItemContent,
  ListItemButton,
  ListDivider,
  Divider,
  Box,
  Avatar,
  Tooltip,
  Button,
} from "@mui/joy";
import { SocialIcon } from "react-social-icons";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { PlatformContext } from "../../contexts/PlatformContext";
import SearchOverlay from "../Misc/SearchOverlay";
import Chip from "@mui/joy/Chip";
import Login from "../Auth/Login";
import SignupWall from "../Misc/SignupWall";
import { Chat } from "../../types";
import CSSTransition from "react-transition-group/CSSTransition";
import { UserPlus, CircleUserRound, Menu } from "lucide-react";
import MultiChatPopup from "../Misc/MultiChatPopup";
import { ChevronsLeft, Contrast } from "lucide-react";
import { StyledAvatar } from "../Town/common/styledAvatar";
import { formatDistanceToNow, subDays, isAfter } from "date-fns";
import { motion } from "framer-motion";
import * as amplitude from "@amplitude/analytics-browser";

export interface CharacterGroup {
  character: Chat;
  chats: Chat[];
}

const Sidebar: React.FC = () => {
  const { user, isAuthenticated, loginWithRedirect } = useAuth0();
  const [showSignupWall, setShowSignupWall] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState(false);
  const [showMultiChatPopup, setShowMultiChatPopup] = useState<boolean>(false);
  const [continueChats, setContinueChats] = useState<Chat[]>([]);
  const [selectedCharacterMultiChats, setSelectedCharacterMultiChats] =
    useState<Chat[]>([]);
  const [continueCharacters, setContinueCharacters] = useState<
    CharacterGroup[]
  >([]);
  const navigate = useNavigate();
  const context = useContext(PlatformContext);
  if (!context) {
    throw new Error(
      "useContext must be used within a PlatformContext.Provider"
    );
  }
  const {
    setShowChats,
    userAssets,
    showChats,
    isMobile,
    socialUrls,
    username,
  } = context;
  const [isOpen, setIsOpen] = useState<boolean>(isMobile ? showChats : true);

  useEffect(() => {
    if (!isMobile) {
      return;
    }
    setIsOpen(showChats);
  }, [showChats]);

  useEffect(() => {
    if (window.location.pathname === "/" && !isMobile) {
      setIsOpen(true);
      setShowChats(true);
    }
    if (
      window.location.pathname.includes("chat/") ||
      window.location.pathname.includes("spot") ||
      window.location.hostname.includes("topdawg.fun")
    ) {
      setIsOpen(false);
      setShowChats(false);
    }
    if (
      isMobile &&
      (window.location.pathname.includes("profile/") ||
        window.location.pathname === "/")
    ) {
      setIsOpen(false);
      setShowChats(false);
    }
  }, [window.location.pathname]);

  const groupChatsByTime = (): Record<string, Chat[]> => {
    const now = new Date();
    const oneWeekAgo = subDays(now, 7);
    const oneMonthAgo = subDays(now, 30);
    const sortedChats = userAssets.chats.sort(
      (a, b) =>
        new Date(b.last_interaction).getTime() -
        new Date(a.last_interaction).getTime()
    );

    return sortedChats.reduce(
      (acc, chat) => {
        const lastInteractionDate = new Date(chat.last_interaction);
        let timeGroup: string;

        if (isAfter(lastInteractionDate, oneWeekAgo)) {
          timeGroup = "Recent";
        } else if (isAfter(lastInteractionDate, oneMonthAgo)) {
          timeGroup = "Around a week ago";
        } else {
          timeGroup = "A month ago or earlier";
        }

        if (!acc[timeGroup]) {
          acc[timeGroup] = [];
        }
        acc[timeGroup].push(chat);
        return acc;
      },
      {} as Record<string, Chat[]>
    );
  };

  const aggregateChats = (chats: Chat[]) => {
    const groups: { [characterId: string]: CharacterGroup } = {};

    chats.forEach((chat) => {
      if (!groups[chat.character_id]) {
        groups[chat.character_id] = { character: chat, chats: [] };
      } else {
        groups[chat.character_id].character = chat;
      }
      groups[chat.character_id].chats.push(chat);
    });

    // Object.values(groups).forEach((group) =>
    //   group.chats.sort(
    //     (a, b) =>
    //       new Date(b.last_interaction).getTime() -
    //       new Date(a.last_interaction).getTime()
    //   )
    // );

    // const sortedChatGroups = Object.values(groups).sort((a, b) => {
    //   // Convert last_interaction to a Date object and compare
    //   const dateA = new Date(a.character.last_interaction);
    //   const dateB = new Date(b.character.last_interaction);
    //   return dateB.getTime() - dateA.getTime(); // Sort in descending order
    // });
    // setContinueCharacters(Object.values(sortedChatGroups));
    // setContinueCharacters(chats as CharacterGroup[]);
  };

  const [innerVisible, setInnerVisible] = useState(false);

  useEffect(() => {
    // When the sidebar opens, wait a bit before showing inner components
    if (isOpen) {
      const timer = setTimeout(() => {
        setInnerVisible(true);
      }, 200); // adjust timing based on your needs
      return () => clearTimeout(timer);
    } else {
      setInnerVisible(false);
    }
  }, [isOpen]);

  // useEffect(() => {
  //   if (isAuthenticated) {
  //     aggregateChats(userAssets.chats);
  //   }
  // }, []);

  const handleContinueCharacterSelect = (group: CharacterGroup): void => {
    if (group.chats.length === 1) {
      // If there's only one chat, navigate directly to that chat
      navigate(
        `/chat/${group.chats[0].character_id}/${group.chats[0].chat_id}`
      );
    } else {
      // If there are multiple chats, open a pop-up
      setShowMultiChatPopup(true);
      setSelectedCharacterMultiChats(group.chats);
    }
  };
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
    if (isMobile) {
      setShowChats(!isOpen);
    }
  };

  const handleClickRequiringSignUp = (route: string) => {
    amplitude.track("Create Character Clicked", {
      origin: "sidebar",
      username: username,
    });
    if (isAuthenticated) {
      navigate(route);
    } else {
      setShowSignupWall(true);
    }
  };

  const handleProfileClick = () => {
    if (user && user["https://chat.4wall.ai/user_id"]) {
      handleClickRequiringSignUp(
        `/profile/${user["https://chat.4wall.ai/username"]}/${user["https://chat.4wall.ai/user_id"]}`
      );
    } else {
      alert("Please sign in");
    }
  };
  const renderChatGroups = () => {
    const groupedChats = groupChatsByTime();

    return Object.entries(groupedChats).map(([timeGroup, chats]) => (
      <React.Fragment key={timeGroup}>
        <ListSubheader className="!text-orange-300 !font-main !text-xs !mx-4 !my-2 !opacity-70">
          {timeGroup.toUpperCase()}
        </ListSubheader>
        {chats.map((chat) => (
          <ListItemButton
            key={chat.chat_id}
            className="hover:!bg-neutral-800 !rounded-xl !mx-2 !px-0"
          >
            <ListItem
              onClick={() =>
                navigate(`/chat/${chat.character_id}/${chat.chat_id}`)
              }
            >
              <ListItemDecorator>
                <Avatar
                  alt={chat.name}
                  src={`${process.env.REACT_APP_ASSETS_BUCKET}${chat.character_id}/display_picture.jpg`}
                />
              </ListItemDecorator>
              <ListItemContent className="ml-4 !text-white !font-main text-sm truncate">
                {chat.name.length > 20
                  ? `${chat.name.slice(0, 20)}...`
                  : chat.name}
              </ListItemContent>
            </ListItem>
          </ListItemButton>
        ))}
      </React.Fragment>
    ));
  };
  return (
    <>
      {!innerVisible &&
        !window.location.pathname.includes("spot/") &&
        (isMobile ? (
          !window.location.pathname.includes("chat/") && (
            <Menu
              onClick={toggleSidebar}
              className="p-1 z-50 cursor-pointer flex-end absolute top-0 left-0 mx-2 my-3 text-white hover:bg-orange-500 rounded-full"
              size={32}
            />
          )
        ) : (
          <Menu
            onClick={toggleSidebar}
            className="p-1 z-50 cursor-pointer flex-end absolute top-0 left-0 mx-2 my-3 text-white hover:bg-orange-500 rounded-full"
            size={32}
          />
        ))}
      <Box
        sx={{
          width: isMobile
            ? isOpen || showChats
              ? "30rem"
              : "0rem"
            : isOpen
              ? "18rem"
              : window.location.pathname.includes("chat")
                ? "0rem"
                : "0rem",
          height: "100svh",
          color: "white",
          display: "flex",
          flex: "1 1 auto",
          boxSizing: "border-box",
          flexDirection: "column",
          borderRight: isOpen ? "1px solid rgba(255, 255, 255, 0.2)" : "none",
          bgcolor: isOpen ? "rgba(0,0,0,0.7)" : "transparent", // Transparent with opacity
          transition: "width 0.3s ease", // Smooth transition for width change
        }}
      >
        {showSignupWall && <SignupWall setShowSignupWall={setShowSignupWall} />}
        <Box sx={{ display: "flex" }}>
          {innerVisible && (
            <div className="w-full flex  justify-between items-center ">
              <div className="flex items-center gap-0 m-2">
                <img
                  src="/4Wall_Logo_Package/fourwall-orange-transparent-cropped.png"
                  alt="logo"
                  className="w-auto h-14 hover:opacity-80 transition-all duration-300"
                  style={{ objectFit: "contain" }}
                  onClick={() => navigate("/")}
                />
              </div>

              <div className="flex justify-end mx-2 gap-2 items-center">
                <Tooltip title="Toggle contrast mode" placement="bottom">
                  <motion.div
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                  >
                    <Contrast
                      onClick={() => {
                        const body = document.body;
                        if (body.style.background === "black") {
                          body.style.background = "";
                        } else {
                          body.style.background = "black";
                        }
                      }}
                      className={`cursor-pointer ${document.body.style.background === "black" ? "" : "text-rainbow-animated"}`}
                      size={28}
                    />
                  </motion.div>
                </Tooltip>

                <ChevronsLeft
                  className="cursor-pointer"
                  size={28}
                  onClick={toggleSidebar}
                />
              </div>
            </div>
          )}
        </Box>

        {showSearch && (
          <SearchOverlay
            isOpen={showSearch}
            onClose={() => setShowSearch(false)}
          />
        )}
        {innerVisible && (
          <Box className="flex-grow flex flex-col">
            <ListDivider sx={styles.divider} />

            <List>
              <ListItemButton className="hover:!bg-neutral-700">
                <ListItem onClick={() => handleClickRequiringSignUp("/create")}>
                  <ListItemDecorator sx={styles.icon}>
                    <UserPlus />
                  </ListItemDecorator>
                  <ListItemContent className="!text-white !font-main text-sm">
                    Create a character
                  </ListItemContent>
                </ListItem>
              </ListItemButton>

              <ListItem>
                <Button
                  className="fancy-btn town"
                  variant="soft"
                  onClick={() => {
                    if (!isAuthenticated) {
                      loginWithRedirect({
                        appState: { returnTo: "/spot" },
                      });
                      return;
                    }
                    navigate("/spot");
                  }}
                >
                  SPOT
                  <Chip
                    sx={{
                      margin: "0 0.5rem",
                      fontFamily: "var(--font_b)",
                      fontSize: "0.6rem",
                    }}
                    color="warning"
                    size="sm"
                    variant="outlined"
                  >
                    ALPHA
                  </Chip>
                </Button>
              </ListItem>
              {/* <ListItem>
                <Button
                  onClick={() => {
                    if (!isAuthenticated) {
                      loginWithRedirect({
                        appState: { returnTo: "/botcast/create" },
                      });
                      return;
                    }
                    navigate(`/botcast/create`, {
                      state: { characters: [] },
                    });
                  }}
                  variant="soft"
                  className="fancy-btn !text-white !font-main"
                >
                  BOTCASTS
                </Button>
              </ListItem> */}
            </List>

            <Box className="flex justify-center">
              <SocialIcon
                url={socialUrls.Reddit}
                target="_blank"
                style={styles.socialIconStyle}
                fgColor="var(--orange-brand-accent)"
                bgColor="transparent"
              />
              <SocialIcon
                url={socialUrls.Discord}
                target="_blank"
                network="discord"
                style={styles.socialIconStyle}
                fgColor="var(--orange-brand-accent)"
                bgColor="transparent"
              />
              <SocialIcon
                url={socialUrls.X}
                className="social-icon"
                target="_blank"
                fgColor="var(--orange-brand-accent)"
                bgColor="transparent"
                style={styles.socialIconStyle}
              />
            </Box>
            {isAuthenticated && (
              <>
                <Divider sx={styles.divider} />
                <ListSubheader className="!text-white !font-main !text-sm !mx-4 !my-2">
                  CHATS
                </ListSubheader>
              </>
            )}

            <Box
              sx={{
                flexGrow: 1,
                overflowY: "auto",
                "&::-webkit-scrollbar-thumb, &::-webkit-scrollbar-thumb:active, &::-webkit-scrollbar-thumb:hover":
                  {
                    backgroundColor: "var(--orange-brand-accent)",
                  },
                "&::-webkit-scrollbar": {
                  width: "2px",
                  height: "2px",
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "transparent",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "var(--orange-brand-accent)",
                  borderRadius: "5px",
                },
                "&::-webkit-scrollbar-thumb:hover": {
                  backgroundColor: "var(--orange-brand-accent)",
                },
                "&::-webkit-scrollbar-thumb:active": {
                  backgroundColor: "var(--orange-brand-accent)",
                },
                "&::-webkit-scrollbar-corner": {
                  backgroundColor: "transparent",
                },
                "&::-webkit-resizer": {
                  backgroundColor: "transparent",
                },
                height: isMobile
                  ? "calc(100svh - 400px)"
                  : "calc(100svh - 400px)",
              }}
            >
              <List>{renderChatGroups()}</List>
            </Box>

            <Box sx={{ mt: "auto" }}>
              <ListDivider className="!bg-neutral-700 !w-[90%] !mx-auto" />
              <List>
                {!isAuthenticated ? (
                  <ListItem>
                    <Login redirectUrl="/" />{" "}
                  </ListItem>
                ) : (
                  <List>
                    <ListItemButton
                      className="hover:!bg-orange-800/40  !rounded-xl !mx-2 "
                      onClick={handleProfileClick}
                    >
                      <ListItemDecorator sx={styles.icon}>
                        <StyledAvatar
                          size="small"
                          src={
                            process.env.REACT_APP_ASSETS_BUCKET +
                            "users/" +
                            username +
                            "/display_picture.jpg"
                          }
                          alt={username}
                        />
                      </ListItemDecorator>
                      <ListItemContent className="!text-gray-100 !font-main text-sm !ml-2">
                        {username}
                      </ListItemContent>
                    </ListItemButton>
                  </List>
                )}
              </List>
            </Box>
          </Box>
        )}
        <CSSTransition
          in={showMultiChatPopup !== null}
          timeout={300}
          classNames="popup"
          unmountOnExit
        >
          {showMultiChatPopup ? (
            <MultiChatPopup
              open={showMultiChatPopup}
              chats={selectedCharacterMultiChats}
              onClose={() => setShowMultiChatPopup(false)}
            />
          ) : (
            <></>
          )}
        </CSSTransition>
      </Box>
    </>
  );
};

const styles = {
  logo: {
    cursor: "pointer",
    fontFamily: "var(--font_b_extended)",
    color: "var(--primary-text-color)",
    marginTop: "0.67em",
    fontSize: "1.4rem",
    textShadow: "2px 2px 4px #000000",
    "&:hover": {
      color: "var(--orange-brand-accent)",
    },
  },
  icon: {
    color: "var(--orange-brand-accent)",
  },
  divider: {
    width: "90%",
    margin: "0 auto",
    borderColor: "rgba(255, 255, 255, 0.3)",
  },
  text: {
    font: "var(--font_b_extended)",
  },
  sidebarText: {
    fontFamily: "var(--font_b)",
    fontSize: "0.9rem",
  },
  socialIconStyle: {
    height: 50,
    width: 50,
    margin: "0 1.4rem",
    bgColor: "green",
    fgColor: "blue",
  },
};
export default Sidebar;
