import React, {
  useEffect,
  useState,
  useContext,
  ChangeEvent,
  useMemo,
} from "react";
import { useAuth0 } from "@auth0/auth0-react";
import "./Create.css";
import { useParams, useNavigate } from "react-router-dom";
import Attributes from "./Attributes";
import ColorSelector from "./ColorSelector";
import { Backdrop, CircularProgress } from "@mui/material";
import Loading from "../../common/Loading";
import InfoBubble from "./InfoBubble";
import InfoInput from "./InfoInput";
import ExampleConvoInput from "./ExampleConvoInput";
import { ImageUploadComponent } from "./ImageUpload";
import { v4 as uuid } from "uuid";
import {
  createCharacter,
  updateCharacter,
  getCharacter,
} from "../../apis/request";
import useRequest from "../../apis/useRequest";
import {
  chubInfo,
  greetingInfo,
  descriptionInfo,
  exampleConvoInfo,
  tagsInfo,
  personalizeInfo,
  defaultScenarioInfo,
} from "./infotexts";
import {
  Button,
  Select,
  FormControl,
  FormHelperText,
  FormLabel,
  Switch,
  Avatar,
  Box,
  Chip,
  Option,
  Modal,
  ModalDialog,
  ModalClose,
} from "@mui/joy";
import { ChevronLeft, FileJson2, Upload } from "lucide-react";
import { PlatformContext } from "../../contexts/PlatformContext";
import { styled } from "@mui/joy/styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  whiteSpace: "nowrap",
  width: 1,
});
export interface CharacterState {
  action: "create" | "update";
  character_id?: string;
  creator: string;
  // image: File | string;
  // chubJson?: File | undefined;
  name: string;
  personality: string;
  attributes: {
    adventurous: number;
    empathy: number;
    funny: number;
    intelligent: number;
  };
  description: string;
  greeting: string;
  bio: string;
  safeMode: string;
  isPublic: boolean;
  // chatBackground: File | string;
  chatBackgroundColor: string;
  characterBackgroundColor: string;
  characterTextColor: string;
  userBackgroundColor: string;
  userTextColor: string;
  tags: string[];
  exampleConvos?: string;
  defaultScenario?: string;
}

interface Tag {
  id: number;
  name: string;
}

interface JsonAttributes {
  name?: string;
  greeting?: string;
  description?: string;
  avatar?: string;
}

function TagSelector({
  character,
  setCharacter,
  tags,
  errors,
  setErrors,
  charKey,
}: {
  character: CharacterState;
  setCharacter: (character: CharacterState) => void;
  tags: string[];
  errors: { [key: string]: boolean };
  setErrors: (errors: { [key: string]: boolean }) => void;
  charKey: string;
}) {
  return (
    <FormControl>
      <Select
        multiple
        color="warning"
        defaultValue={character.tags}
        onChange={(event, value: string[]) => {
          setCharacter({ ...character, tags: value });
          if (value.length === 0) {
            setErrors({ ...errors, tags: true });
          } else {
            setErrors({ ...errors, tags: false });
          }
        }}
        className="!text-white !font-bold !font-main !bg-neutral-700 !min-w-1/2 !outline-none !border-none"
        renderValue={(selected) => (
          <Box sx={{ display: "flex", gap: "0.25rem", flexWrap: "wrap" }}>
            {selected.map((selectedOption) => (
              <Chip variant="soft" color="warning">
                {selectedOption.label}
              </Chip>
            ))}
          </Box>
        )}
        sx={{ minWidth: "15rem" }}
        slotProps={{
          listbox: {
            sx: {
              width: "100%",
            },
          },
        }}
      >
        {tags.map((value) => (
          <Option key={value} value={value}>
            {value}
          </Option>
        ))}
      </Select>
      {errors[charKey] && (
        <FormHelperText className="!text-red-500">
          Tags cannot be empty
        </FormHelperText>
      )}
    </FormControl>
  );
}

function Create() {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [imageSrc, setImageSrc] = useState<string>("");
  const [chatBgSrc, setChatBgSrc] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState<boolean>(false);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  const [characterLoading, setCharacterLoading] = useState<boolean>(true);
  const [retrievedCharacterId, setRetrievedCharacterId] = useState<string>("");
  const retrievedCharacterIdRef = React.useRef(retrievedCharacterId);
  const [chubLoading, setChubLoading] = useState<boolean>(false);
  const [modMessage, setModMessage] = useState<string>("");
  const [openModerator, setOpenModerator] = useState<boolean>(false);
  const [errors, setErrors] = useState<{ [key: string]: boolean }>({
    name: false,
    description: false,
    bio: false,
    greeting: false,
    tags: false,
  });
  const [showUserBubbleColorContainer, setShowUserBubbleColorContainer] =
    useState<boolean>(false);
  const [
    showCharacterBubbleColorContainer,
    setShowCharacterBubbleColorContainer,
  ] = useState<boolean>(false);
  const [showUserTextColorContainer, setShowUserTextColorContainer] =
    useState<boolean>(false);
  const [showCharacterTextColorContainer, setShowCharacterTextColorContainer] =
    useState<boolean>(false);
  const context = useContext(PlatformContext);
  if (!context) {
    throw new Error("Platform context not found in Create component");
  }
  const { username, alertInfo, setAlertInfo, showAlert, closeAlert } = context;
  const [pageLoading, setPageLoading] = useState<boolean>(true);
  const [character, setCharacter] = useState<CharacterState>(
    {} as CharacterState
  );
  const [selectedDisplayPicture, setSelectedDisplayPicture] =
    useState<File | null>(null);
  const [selectedChatBackground, setSelectedChatBackground] =
    useState<File | null>(null);

  const { user, isLoading, isAuthenticated } = useAuth0();
  const { character_id: paramCharacterId } = useParams<{
    character_id: string;
  }>();
  const [character_id, setCharacterId] = useState<string>(
    paramCharacterId || uuid()
  );
  const [isCreate, setIsCreate] = useState<boolean>(
    paramCharacterId === undefined
  );
  const memoizedTimestamp = useMemo(() => Date.now(), []);
  const tags = [
    "Fantasy",
    "Role Play",
    "Celebrities",
    "Sci-Fi",
    "Historical",
    "Anime",
    "Superheroes",
    "Horror",
    "Comedy",
    "NSFW",
    "Gaming",
    "LGBTQ+",
    "Original Character",
    "Movies & TV",
  ];

  const navigate = useNavigate();
  const makeRequest = useRequest();
  const fetchCharacter = async (creator: string) => {
    if (isAuthenticated && user) {
      try {
        const response = await makeRequest<any, any>(
          getCharacter(username, character_id)
        );

        if (response.creator !== username) {
          console.log("not authorized to edit this character");
          navigate("/");
        }
        let parsedTags: string[];
        if (typeof response.tags === "string") {
          try {
            parsedTags = JSON.parse(response.tags);
          } catch {
            // If parsing fails, assume it's a comma-separated string
            parsedTags = response.tags
              .split(",")
              .map((tag: string) => tag.trim());
          }
        } else if (Array.isArray(response.tags)) {
          parsedTags = response.tags;
        } else {
          parsedTags = []; // Fallback to empty array if unexpected type
        }

        setCharacter({
          action: "update",
          character_id: character_id,
          creator: creator,

          name: response.name,
          personality: "",
          attributes: response.attributes,
          description: response.description,
          greeting: response.greeting,
          exampleConvos: response.example_convos,
          bio: response.bio,
          safeMode: response.safeMode,
          isPublic: response.is_public, //=== "true" ? true : false,
          chatBackgroundColor: response.chat_background_color,
          characterBackgroundColor: response.char_message_bg_color,
          characterTextColor: response.char_text_color,
          userBackgroundColor: response.user_message_bg_color,
          userTextColor: response.user_text_color,
          defaultScenario: response.default_scenario,
          tags: parsedTags,
        });
        setCharacterLoading(false);
        return response;
      } catch (error) {
        console.error("There was an error fetching the characters:", error);
      }
    }
  };

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      window.location.href = "/";
    } else if (!isLoading && isAuthenticated) {
      setCharacter({
        action: "create",
        character_id: character_id,
        creator: username,

        name: "",
        personality: "",
        attributes: {
          adventurous: 0,
          empathy: 0,
          funny: 0,
          intelligent: 0,
        },
        description: "",
        greeting: "",
        defaultScenario: "",
        bio: "",
        safeMode: "false",
        isPublic: true,

        chatBackgroundColor: "#333",
        characterBackgroundColor: "#535252",
        characterTextColor: "#D8D7D6",
        userBackgroundColor: "#000000",
        userTextColor: "#ffffff",
        tags: [],
      });

      if (!isCreate) {
        fetchCharacter(username);
      } else {
        setCharacterLoading(false);
      }

      setPageLoading(false);
    }
  }, [username, isAuthenticated, isLoading]);

  const handleNext = () => {
    const requiredFields = ["name", "description", "bio"];
    const emptyFields = requiredFields.filter(
      (field) => character[field as keyof CharacterState] === ""
    );

    if (emptyFields.length > 0 || character.tags.length === 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        ...Object.fromEntries(emptyFields.map((field) => [field, true])),
        tags: character.tags.length === 0,
      }));
      showAlert(
        "Please fill in all required fields and select at least one tag",
        "danger"
      );
      return;
    }

    setCurrentPage(2);
  };
  const handleSubmit = async () => {
    setIsSubmitting(true);

    try {
      if (isCreate) {
        const response = await makeRequest<any, any>(
          createCharacter(username, character_id),
          character
        );

        if (response.is_safe) {
          setShowSuccessPopup(true);
        } else {
          setModMessage(response.message);
          setOpenModerator(true);
        }
      } else {
        const response = await makeRequest<any, any>(
          updateCharacter(username, character_id),
          character
        );

        if (response.is_safe) {
          setShowSuccessPopup(true);
        } else {
          setModMessage(response.message);
          setOpenModerator(true);
        }
      }
    } catch (error) {
      console.error("There was an error:", error);
    }
    setIsSubmitting(false);
  };

  const handleBack = () => {
    if (currentPage === 1) {
      navigate(-1);
    } else if (currentPage === 2) {
      setCurrentPage(1);
    }
  };

  if (pageLoading || characterLoading) {
    return (
      <div className="loading-centered">
        {" "}
        {/* <CircularProgress color="inherit" /> */}
        <Loading />
      </div>
    );
  }

  const LoadingOverlay = ({ open }: { open: boolean }) => (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
    >
      <Loading />
    </Backdrop>
  );

  interface PopupProps {
    open: boolean;
    onClose: () => void;
    title: string;
    children: React.ReactNode;
  }

  const Popup = ({ open, onClose, title, children }: PopupProps) => {
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle className="create-popup-title">{title}</DialogTitle>
        <DialogContent className="create-popup-content">
          {children}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  interface ChubUploadProps {
    character: CharacterState;
    setCharacter: (character: CharacterState) => void;
  }

  function ChubUpload({ character, setCharacter }: ChubUploadProps) {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [fileAttributes, setFileAttributes] = useState<JsonAttributes | null>(
      null
    );

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.files && event.target.files[0]) {
        const file = event.target.files[0];
        setSelectedFile(file);
        setChubLoading(true);
        const reader = new FileReader();
        reader.onload = async (e) => {
          try {
            const text = e.target?.result;
            const json = JSON.parse(text as string);
            const jsonData = json.data ? json.data : json;
            const attributes = {
              name: jsonData.name,
              greeting: jsonData.first_mes,
              description: jsonData.description,
              personality: jsonData.personality,
              avatar: jsonData.avatar,
              tags: jsonData.tags,
              defaultScenario: jsonData.world_scenario || jsonData.scenario,
            };
            let imageFile: File | string = "";

            if (attributes.avatar) {
              try {
                const response = await fetch(attributes.avatar);
                const blob = await response.blob();
                imageFile = new File([blob], "avatar.png", {
                  type: "image/png",
                });

                setSelectedDisplayPicture(imageFile);
                setImageSrc(URL.createObjectURL(imageFile));
              } catch (imgError) {
                console.error("Error fetching the image:", imgError);
              }
            }
            const validTags = tags.filter((value) =>
              attributes.tags.includes(value)
            );

            // const maxSizeInBytes = 5 * 1024 * 1024; // 5 MB in bytes

            // if (file.size > maxSizeInBytes) {
            //   alert(
            //     "The file is too large. Please select a file smaller than 8 MB."
            //   );
            //   return;
            // }
            setCharacter({
              ...character,
              name: attributes?.name || "",
              greeting: attributes?.greeting || "",
              description:
                attributes?.description + attributes.personality || "",
              attributes: {
                adventurous: 50,
                empathy: 50,
                funny: 50,
                intelligent: 50,
              },
              defaultScenario: attributes?.defaultScenario || "",
              tags: validTags,
              // image: imageFile,
              // chubJson: event.target.files ? event.target.files[0] : undefined,
            });

            // setFileAttributes(attributes);
          } catch (error) {
            console.error("Error parsing JSON:", error);
            alert("Sorry, this file could not be parsed.");
          } finally {
            setChubLoading(false);
          }
        };
        reader.readAsText(file);
      }
    };

    return (
      <div className="flex flex-row w-full items-center space-x-2">
        <Button
          variant="soft"
          color="warning"
          startDecorator={<FileJson2 size={22} />}
          className="!bg-fourwall-orange !text-white !font-bold !font-main"
          component="label"
        >
          Upload JSON from Chub
          <input
            type="file"
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
        </Button>

        <InfoBubble title="Character Description" infoText={chubInfo} />
        {selectedFile && <p>Selected File: {selectedFile.name}</p>}
      </div>
    );
  }

  const handlePopupClose = () => {
    setShowSuccessPopup(false);
    navigate(`/chat/${character_id}`, {
      state: { character: character },
    });
  };
  const handleSwitchPublic = () => {
    setCharacter({ ...character, isPublic: !character.isPublic });
  };
  console.log("Character", character);
  return (
    <div className="flex flex-col p-4 font-main text-white items-center  bg-black/30">
      <h1 className="md:text-5xl text-3xl font-bold">
        {" "}
        <ChevronLeft
          size={22}
          onClick={handleBack}
          className="create-back-arrow"
        />{" "}
        Character Creator
      </h1>

      {currentPage === 1 ? (
        <div className="md:w-[80%] w-full flex flex-col space-y-10">
          <h1 className="text-2xl font-bold md:text-left text-center">
            Character Details
          </h1>
          {chubLoading && <Loading />}
          <div className="flex md:flex-row flex-col md:items-start items-center w-full ">
            <ImageUploadComponent
              character={character}
              setCharacter={setCharacter}
              setImageSource={setImageSrc}
              input_id="imageInput"
              setModMessage={setModMessage}
              setOpenModerator={setOpenModerator}
              selectedFile={selectedDisplayPicture}
              setSelectedFile={setSelectedDisplayPicture}
            />
            <div className="flex flex-col md:w-2/3 w-full">
              <ChubUpload character={character} setCharacter={setCharacter} />
              <InfoInput
                infoText={""}
                label="Name"
                charKey="name"
                size={1}
                maxLength={30}
                value={character.name}
                subText="Name your character"
                onChange={(value) => {
                  setCharacter({ ...character, name: value });
                  if (value.trim() !== "") {
                    setErrors({ ...errors, name: false });
                  }
                }}
                errors={errors}
              />
              <InfoInput
                infoText={""}
                label="Character Bio"
                charKey="bio"
                size={4}
                maxLength={50}
                value={character.bio}
                subText="Summarize your character in 10 words or less for their character card."
                onChange={(value) => {
                  setCharacter({ ...character, bio: value });
                  if (value.trim() !== "") {
                    setErrors({ ...errors, bio: false });
                  }
                }}
                errors={errors}
              />
            </div>
          </div>
          <InfoInput
            infoText={descriptionInfo}
            label="Character Description"
            charKey="description"
            size={7}
            maxLength={5000}
            value={character.description}
            subText="Describe any unique traits, quirks, or characteristics to personalize your character."
            onChange={(value) => {
              setCharacter({ ...character, description: value });
              if (value.trim() !== "") {
                setErrors({ ...errors, description: false });
              }
            }}
            errors={errors}
          />
          <InfoInput
            infoText={greetingInfo}
            label="Greeting"
            charKey="greeting"
            size={4}
            maxLength={1200}
            value={character.greeting}
            subText="Specify a greeting message for your character"
            onChange={(value) => {
              setCharacter({ ...character, greeting: value });
              if (value.trim() !== "") {
                setErrors({ ...errors, greeting: false });
              }
            }}
            errors={errors}
          />
          <InfoInput
            infoText={defaultScenarioInfo}
            label="Default Scenario"
            charKey="defaultScenario"
            size={7}
            maxLength={6000}
            value={character.defaultScenario || ""}
            subText={
              "Specify a default scenario for your character. This will be used as the default setting for all chats with this character."
            }
            onChange={(value) => {
              setCharacter({ ...character, defaultScenario: value });
              if (value.trim() !== "") {
                setErrors({ ...errors, defaultScenario: false });
              }
            }}
            errors={errors}
          />
          <ExampleConvoInput
            infoText={exampleConvoInfo}
            label="Example Conversations"
            charKey="exampleConvos"
            size={10}
            maxLength={6000}
            value={character.exampleConvos || ""}
            subText="Specify example conversations for your character"
            onChange={(value) => {
              setCharacter({ ...character, exampleConvos: value });
              if (value.trim() !== "") {
                setErrors({ ...errors, exampleConvos: false });
              }
            }}
            errors={errors}
          />
          {/* <InfoInput
            infoText={exampleConvoInfo}
            label="Example Conversations"
            charKey="exampleConvos"
            size={10}
            maxLength={6000}
            value={character.exampleConvos || ""}
            subText="Specify example conversations for your character"
            onChange={(value) => {
              setCharacter({ ...character, exampleConvos: value });
              if (value.trim() !== "") {
                setErrors({ ...errors, exampleConvos: false });
              }
            }}
            errors={errors}
          /> */}
          <div className="flex flex-col">
            <label className="flex flex-row items-center ">
              Tags
              <InfoBubble title="Tags" infoText={tagsInfo} />
            </label>
            <div className="flex flex-col md:flex-row">
              <TagSelector
                character={character}
                setCharacter={setCharacter}
                tags={tags}
                errors={errors}
                setErrors={setErrors}
                charKey="tags"
              />
            </div>
          </div>
          <div className="items-center flex">
            <Button
              size="lg"
              className="!bg-fourwall-orange !text-white !font-bold !font-main !rounded-full !align-middle !mx-auto !my-4"
              onClick={handleNext}
            >
              NEXT
            </Button>
          </div>
        </div>
      ) : (
        <div className="md:w-[80%] w-full flex flex-col items-center">
          <h2 className="text-2xl font-bold md:text-left text-center">
            Chat Details
          </h2>

          <div className="flex md:flex-row flex-col items-center md:space-x-4 justify-between">
            <ImageUploadComponent
              character={character}
              setCharacter={setCharacter}
              setImageSource={setChatBgSrc}
              input_id="imageBgInput"
              setModMessage={setModMessage}
              setOpenModerator={setOpenModerator}
              selectedFile={selectedChatBackground}
              setSelectedFile={setSelectedChatBackground}
            />

            <div className="flex flex-col w-full mx-auto my-0">
              <h2 className="flex flex-row justify-between m-0">
                Chat Interface
                <InfoBubble
                  title="Message Customization"
                  infoText={personalizeInfo}
                />
              </h2>

              <div
                className="flex flex-col md:items-start items-center  border-1 border-orange-500 border-solid p-2 rounded-lg"
                style={{
                  backgroundImage: selectedChatBackground
                    ? `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${URL.createObjectURL(selectedChatBackground)})`
                    : `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${process.env.REACT_APP_ASSETS_BUCKET}${character_id}/chat_background.jpg)`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <div className="flex gap-3">
                  <Avatar
                    className="!w-20 !h-20"
                    src={`${process.env.REACT_APP_ASSETS_BUCKET}${character_id}/display_picture.jpg?${memoizedTimestamp}`}
                    alt={character.name}
                    onError={(e) => {
                      const target = e.target as HTMLImageElement;
                      target.onerror = null;
                      target.src =
                        "https://4thwall-assets.s3.amazonaws.com/default_assets/display_picture.jpg";
                    }}
                  />
                  <div className="flex flex-col space-y-1 text-white m-auto">
                    <h2 className="m-0">{character.name}</h2>
                    <p className="text-sm cursor-pointer hover:text-orange-500">
                      @{username}
                    </p>
                  </div>
                </div>

                <div className="flex w-full flex-col justify-start h-full overflow-x-hidden align-middle scrollbar-hide transition-all duration-300 ease-in-out">
                  <div className="chat-message char">
                    <Avatar
                      className="chat-message-chardp"
                      onError={(e) => {
                        const target = e.target as HTMLImageElement; // Cast to HTMLImageElement
                        target.onerror = null; // prevents looping
                        target.src =
                          "https://4thwall-assets.s3.amazonaws.com/default_assets/display_picture.jpg";
                      }}
                      src={`${process.env.REACT_APP_ASSETS_BUCKET}${character_id}/display_picture.jpg?${memoizedTimestamp}`}
                      alt={character.name}
                    />
                    <div className="flex flex-col items-start">
                      <p className="text-xs text-neutral-200 mx-1 my-0 font-chat">
                        {character.name}
                      </p>
                      <div
                        className="message-content !font-chat"
                        style={{
                          backgroundColor: character.characterBackgroundColor,
                          color: character.characterTextColor,
                        }}
                      >
                        <p>Hi User!</p>
                      </div>
                    </div>
                  </div>
                  <div className="chat-message user">
                    <div className="flex flex-col items-end">
                      <p className="text-xs text-neutral-200 mx-1 my-0 font-chat">
                        {username}
                      </p>
                      <div
                        className="message-content !font-chat"
                        style={{
                          backgroundColor: character.userBackgroundColor,
                          color: character.userTextColor,
                        }}
                      >
                        <p>Hi Character!</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex rounded-lg p-2">
                <div className="flex flex-col">
                  <ColorSelector
                    selectedColor={character.characterBackgroundColor}
                    setShowColorContainer={setShowCharacterBubbleColorContainer}
                    showColorContainer={showCharacterBubbleColorContainer}
                    setCharacter={setCharacter}
                    character={character}
                    attributeToSet="characterBackgroundColor"
                    label="Character Bubble Color"
                  />
                  <ColorSelector
                    selectedColor={character.characterTextColor}
                    setShowColorContainer={setShowCharacterTextColorContainer}
                    showColorContainer={showCharacterTextColorContainer}
                    setCharacter={setCharacter}
                    character={character}
                    attributeToSet="characterTextColor"
                    label="Character Text Color"
                  />
                </div>
                <div className="flex flex-col">
                  <ColorSelector
                    selectedColor={character.userBackgroundColor}
                    setShowColorContainer={setShowUserBubbleColorContainer}
                    showColorContainer={showUserBubbleColorContainer}
                    setCharacter={setCharacter}
                    character={character}
                    attributeToSet="userBackgroundColor"
                    label="User Bubble Color"
                  />
                  <ColorSelector
                    selectedColor={character.userTextColor}
                    setShowColorContainer={setShowUserTextColorContainer}
                    showColorContainer={showUserTextColorContainer}
                    setCharacter={setCharacter}
                    character={character}
                    attributeToSet="userTextColor"
                    label="User Text Color"
                  />
                </div>
              </div>
            </div>
          </div>

          <FormControl className="!flex !flex-row space-x-2 ">
            <FormLabel className="flex flex-row items-center !text-white">
              Private
              <Switch
                checked={!character.isPublic}
                onChange={handleSwitchPublic}
              />
            </FormLabel>
            <InfoBubble
              title="Make your characters private"
              infoText={
                "Private characters are visibile only on your profile and only you can chat or create a botcast with them."
              }
            />
          </FormControl>
          <Button
            disabled={submitDisabled}
            className="!rounded-full !bg-fourwall-orange !text-white !font-bold !font-main !mx-auto !my-4 "
            onClick={handleSubmit}
          >
            SUBMIT
          </Button>

          <Popup open={showSuccessPopup} onClose={handlePopupClose} title="Yay">
            Character successfully created/updated !
          </Popup>
        </div>
      )}
      <LoadingOverlay open={isSubmitting} />
      <Modal open={openModerator} onClose={() => setOpenModerator(false)}>
        <ModalDialog className="!bg-neutral-900 !text-white !border-none">
          <ModalClose />
          <div className="flex flex-col space-y-2 items-center">
            <h2 className="text-2xl font-bold font-main text-fourwall-orange">
              Sorry, please choose a different image
            </h2>
            <p className="font-main">{modMessage}</p>
          </div>
        </ModalDialog>
      </Modal>
    </div>
  );
}

export default Create;
