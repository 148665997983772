import { Id } from "../../../convex/_generated/dataModel";
import { api } from "../../../convex/_generated/api";
import { useMutation } from "convex/react";

export const useInfluenceActions = () => {
  const influenceAgent = useMutation(api.world.influenceAgent);

  const handleAttack = async ({
    worldId,
    playerId,
    otherPlayerId,
    attackerAgentId = "a:7",
    victimAgentId = "a:1",
  }: {
    worldId: Id<"worlds">;
    playerId: string;
    otherPlayerId: string;
    attackerAgentId?: string;
    victimAgentId?: string;
  }) => {
    // Attack action
    await influenceAgent({
      worldId,
      playerId,
      otherPlayerId,
      agentId: attackerAgentId,
      type: "influenceAttack",
    });

    // Hurt reaction
    await influenceAgent({
      worldId,
      playerId: otherPlayerId,
      otherPlayerId: playerId,
      agentId: victimAgentId,
      type: "influenceHurt",
    });
  };

  const handleDance = async ({
    worldId,
    playerId,
    agentId,
  }: {
    worldId: Id<"worlds">;
    playerId: string;
    agentId: string;
  }) => {
    await influenceAgent({
      worldId,
      playerId,
      agentId,
      type: "influenceDance",
    });
  };

  const handleInfluence = async ({
    worldId,
    playerId,
    agentId,
    inceptMemory,
  }: {
    worldId: Id<"worlds">;
    playerId: string;
    agentId: string;
    inceptMemory?: string;
  }) => {
    await influenceAgent({
      worldId,
      playerId,
      agentId,
      type: "influenceIncept",
      inceptMemory,
    });
  };

  return {
    handleAttack,
    handleDance,
    handleInfluence,
  };
};
