import { useRef, useState, useEffect } from "react";

interface Size {
  width: number;
  height: number;
}

function useResizeObserver(): [React.RefObject<HTMLDivElement>, Size] {
  const ref = useRef<HTMLDivElement>(null);
  const [size, setSize] = useState<Size>({ width: 0, height: 0 });

  useEffect(() => {
    const observeTarget = ref.current;
    if (!observeTarget) return;

    const updateSize = () => {
      setSize({
        width: observeTarget.offsetWidth,
        height: observeTarget.offsetHeight,
      });
    };

    // Set initial size
    updateSize();

    if (typeof ResizeObserver === "undefined") {
      console.warn("ResizeObserver is not supported in this browser.");
      return;
    }

    const resizeObserver = new ResizeObserver((entries) => {
      if (!entries.length) return;
      updateSize();
    });

    resizeObserver.observe(observeTarget);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return [ref, size];
}

export default useResizeObserver;
