import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import { DialogTitle } from "@mui/material";
import { DialogContent } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ShareIcon from "@mui/icons-material/Share";
import ReplyRoundedIcon from "@mui/icons-material/ReplyRounded";
import { SocialIcon } from "react-social-icons";
import { RiTwitterXFill } from "react-icons/ri";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";

import {
  TwitterShareButton,
  WhatsappShareButton,
  RedditShareButton,
  FacebookShareButton,
  FacebookMessengerShareButton,
} from "react-share"; // These are from 'react-share' library
import { RiGitRepositoryPrivateFill } from "react-icons/ri";
// import { copyToClipboard } from "./utils"; // Assume you have a utility function to handle clipboard actions

interface Props {
  url: string;
  sendShareData: () => void;
}
const BotcastSharePopup = ({ url, sendShareData }: Props) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
    sendShareData();
  };
  const handleClose = () => setOpen(false);
  const shareCaption = `Check out this AWESOME BOTCAST on 4thWall AI!🔥🎙️ #4thwallai`;
  const FlippedReplyIcon = styled(ReplyRoundedIcon)({
    transform: "scaleX(-1)",
    cursor: "pointer",
  });
  const popup_heading = "Share Botcast";
  const copyToClipboard = (text: string) => {
    if (!navigator.clipboard) {
      // Clipboard API not available, use document.execCommand fallback
      const textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        const successful = document.execCommand("copy");
        const msg = successful ? "successful" : "unsuccessful";
        console.log("Fallback: Copying text command was " + msg);
      } catch (err) {
        console.error("Fallback: Oops, unable to copy", err);
      }
      document.body.removeChild(textArea);
    } else {
      // Clipboard API available
      navigator.clipboard.writeText(text).then(
        () => console.log("Async: Copying to clipboard was successful!"),
        (err) => console.error("Async: Could not copy text: ", err)
      );
    }
  };

  return (
    <>
      {/* <ShareIcon onClick={handleOpen} /> */}
      <FlippedReplyIcon sx={{ margin: "auto 0" }} onClick={handleOpen} />
      <Dialog open={open} onClose={handleClose} className="share-popup">
        <DialogTitle>
          {popup_heading}
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon className="close-button" />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          <p>People with the link can view and share this botcast.</p>
          <div style={{ display: "flex" }}>
            <input type="text" value={url} readOnly className="copy-link" />
            <Button
              variant="contained"
              className="copy-button"
              onClick={() => copyToClipboard(url)}
            >
              Copy Link
            </Button>
            {/* <button>Copy Link</button> */}
          </div>

          <div className="share-area">
            <TwitterShareButton url={url} title={shareCaption}>
              <SocialIcon network="twitter" className="social-share-button" />
              {/* <RiTwitterXFill size={2} className="twitter-share-button" /> */}
            </TwitterShareButton>
            <WhatsappShareButton url={url} title={shareCaption}>
              <SocialIcon network="whatsapp" className="social-share-button" />
            </WhatsappShareButton>
            <RedditShareButton url={url} title={shareCaption}>
              <SocialIcon network="reddit" className="social-share-button" />
            </RedditShareButton>
            <FacebookShareButton url={url} title={shareCaption}>
              <SocialIcon network="facebook" className="social-share-button" />
            </FacebookShareButton>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default BotcastSharePopup;
