import React, { useRef, useState, useEffect, FC, forwardRef } from "react";
import { Character } from "../../types";
import { Swiper as SwiperInstance } from "swiper/types"; // Importing the type for Swiper instance
import CharacterTrending from "../Character/CharacterTrending";
import Skeleton from "@mui/material/Skeleton";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  FreeMode,
  Mousewheel,
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
} from "swiper/modules";

type ArrowProps = React.HTMLAttributes<HTMLButtonElement> & {
  isVisible: boolean;
};

const CustomPrevArrow = forwardRef<HTMLButtonElement, ArrowProps>(
  ({ isVisible, ...props }, ref) => (
    <button
      ref={ref}
      className={`custom-swiper-button-prev transition-opacity duration-300 ${
        isVisible ? "opacity-100" : "opacity-0"
      }`}
      {...props}
    >
      &#10094; {/* Left arrow symbol */}
    </button>
  )
);

const CustomNextArrow = forwardRef<HTMLButtonElement, ArrowProps>(
  ({ isVisible, ...props }, ref) => (
    <button
      ref={ref}
      className={`custom-swiper-button-next transition-opacity duration-300 ${
        isVisible ? "opacity-100" : "opacity-0"
      }`}
      {...props}
    >
      &#10095; {/* Right arrow symbol */}
    </button>
  )
);

CustomPrevArrow.displayName = "CustomPrevArrow";
CustomNextArrow.displayName = "CustomNextArrow";

export default function GenSwiper({
  characters,
  SliderComponent,
}: {
  characters: Character[];
  SliderComponent: React.FC<{
    character_id: string;
    name: string;
    image: string;
  }>;
}) {
  const [isHovered, setIsHovered] = useState(false);
  const [isEnd, setIsEnd] = useState(false);
  const [isBeginning, setIsBeginning] = useState(false);
  const prevRef = useRef<HTMLButtonElement>(null);
  const nextRef = useRef<HTMLButtonElement>(null);
  return (
    <div
      className="relative max-w-[90vw]"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Swiper
        modules={[
          FreeMode,
          Mousewheel,
          Navigation,
          Pagination,
          Scrollbar,
          A11y,
        ]}
        spaceBetween={10}
        slidesPerView={"auto"}
        touchRatio={1}
        speed={400}
        touchEventsTarget="container"
        mousewheel={{
          forceToAxis: true,
          releaseOnEdges: true,
        }}
        freeMode={true}
        slidesPerGroup={2}
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        }}
        onSlideChange={(swiper) => {
          setIsEnd(swiper.isEnd);
          setIsBeginning(swiper.isBeginning);
        }}
        onBeforeInit={(swiper: SwiperInstance) => {
          if (typeof swiper.params.navigation !== "boolean") {
            // Ensure it's not the boolean 'false'
            (swiper.params.navigation as any).prevEl = prevRef.current;
            (swiper.params.navigation as any).nextEl = nextRef.current;
          }
        }}
        className="mySwiper"
      >
        <CustomPrevArrow ref={prevRef} isVisible={true} />
        <CustomNextArrow ref={nextRef} isVisible={true} />
        {characters.map((character) => (
          <SwiperSlide key={character.character_id}>
            {/* <CharacterTrending character={character} /> */}
            <SliderComponent
              character_id={character.character_id}
              name={character.name}
              image={`${process.env.REACT_APP_ASSETS_BUCKET}${character.character_id}/display_picture.jpg`}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      {!isEnd && (
        <div
          className="absolute top-0 right-0 bottom-0 w-12 pointer-events-none z-10"
          style={{
            background:
              "linear-gradient(to right, rgba(0,0,0,0.0), rgba(0,0,0,0.6))",
          }}
        ></div>
      )}
      {!isBeginning && (
        <div
          className="absolute top-0 left-0 bottom-0 w-12 pointer-events-none z-10"
          style={{
            background:
              "linear-gradient(to left, rgba(0,0,0,0), rgba(0,0,0,0.6))",
          }}
        ></div>
      )}
    </div>
  );
}
