import React, { useState, useContext, useEffect, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { List, ListDivider, Divider, ListSubheader, Box } from "@mui/joy";
import { useAuth0 } from "@auth0/auth0-react";
import { PlatformContext } from "../../contexts/PlatformContext";
import { TownContext } from "../../contexts/TownContext";
import {
  Home,
  ChevronsLeft,
  SquarePlus,
  VolumeX,
  Volume2,
  MoreVertical,
  ChevronDown,
} from "lucide-react";
import { Id } from "../../convex/_generated/dataModel";
import SpotMemberDisplay from "./SpotMemberDisplay";
import AddMember from "./AddMember";
import FreezeButton from "./FreezeButton";
import { useQuery } from "convex/react";
import { api } from "../../convex/_generated/api";
import { motion, AnimatePresence } from "framer-motion";
import { GameId } from "../../convex/aiTown/ids";
import { StyledAvatar } from "./common/styledAvatar";

interface SidebarProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  townName: string;
  players: any[];
  worldId: Id<"worlds">;
  engineId: string;
  game: any;
  setSelectedElement: (element: any) => void;
  onPlayerClick: (playerId: GameId<"players">) => void;
  handleAddMember: () => void;
  toggleAudio: () => void;
  isAudioPlaying: boolean;
  handleHomeClick: () => void;
  worldStatus: any;
  isSpectator: boolean;
  creator: string;
}

const DesktopSidebar: React.FC<SidebarProps> = ({
  open,
  setOpen,
  townName,
  players,
  worldId,
  engineId,
  game,
  setSelectedElement,
  onPlayerClick,
  handleAddMember,
  toggleAudio,
  isAudioPlaying,
  handleHomeClick,
  worldStatus,
  isSpectator,
  creator,
}) => (
  <AnimatePresence>
    {open && (
      <motion.div
        initial={{ width: "0rem", opacity: 0 }}
        animate={{
          width: "20rem",
          opacity: 1,
        }}
        exit={{ width: "0rem", opacity: 0 }}
        transition={{ duration: 0.3, ease: "easeInOut" }}
        className="h-full bg-neutral-900 text-white flex flex-col py-4 px-2"
        style={{
          borderRight: "1px solid rgba(255, 255, 255, 0.2)",
        }}
      >
        <motion.div className="flex items-center justify-between h-[7%]">
          <ListSubheader className="!text-xl !text-white !font-main">
            {townName}
          </ListSubheader>
          <motion.div
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={() => setOpen(false)}
          >
            <ChevronsLeft />
          </motion.div>
        </motion.div>
        <ListDivider />
        <motion.div className="flex justify-between items-center">
          <p className="!text-lg !text-white !font-main !font-bold">Members</p>
          <motion.div
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={handleAddMember}
          >
            {!isSpectator && (
              <SquarePlus fill="white" className="cursor-pointer !text-black" />
            )}
          </motion.div>
        </motion.div>
        <List>
          {players
            .filter((player) => !player.human)
            .map((player) => (
              <SpotMemberDisplay
                key={player.id}
                user={false}
                worldId={worldId}
                engineId={engineId as Id<"engines">}
                playerId={player.id}
                game={game}
                setSelectedElement={setSelectedElement}
                onPlayerClick={onPlayerClick}
              />
            ))}
        </List>

        <Divider />
        {!isSpectator ? (
          <>
            <motion.div className="mt-auto flex flex-row justify-between items-center">
              <List>
                <SpotMemberDisplay
                  user={true}
                  worldId={worldId}
                  engineId={engineId as Id<"engines">}
                  playerId={players.find((p) => p.human)?.id}
                  game={game}
                  setSelectedElement={setSelectedElement}
                  onPlayerClick={onPlayerClick}
                />
              </List>
              <motion.div className="flex items-center gap-2 text-gray-400">
                <FreezeButton worldStatus={worldStatus} size="small" />
                <motion.div
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  onClick={toggleAudio}
                >
                  {isAudioPlaying ? (
                    <Volume2
                      className="cursor-pointer"
                      size={32}
                      strokeWidth={1.5}
                    />
                  ) : (
                    <VolumeX
                      className="cursor-pointer"
                      size={32}
                      strokeWidth={1.5}
                    />
                  )}
                </motion.div>
                <motion.div
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  onClick={handleHomeClick}
                >
                  <Home
                    className="cursor-pointer"
                    size={30}
                    strokeWidth={1.5}
                  />
                </motion.div>
              </motion.div>
            </motion.div>
          </>
        ) : (
          <div className="mt-auto flex flex-row justify-between items-center px-3 py-2">
            <SpotMemberDisplay
              user={true}
              worldId={worldId}
              engineId={engineId as Id<"engines">}
              playerId={players.find((p) => p.human)?.id}
              game={game}
              setSelectedElement={setSelectedElement}
              onPlayerClick={onPlayerClick}
            />
            <motion.div
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              onClick={toggleAudio}
            >
              {isAudioPlaying ? (
                <Volume2
                  className="cursor-pointer"
                  size={32}
                  strokeWidth={1.5}
                />
              ) : (
                <VolumeX
                  className="cursor-pointer"
                  size={32}
                  strokeWidth={1.5}
                />
              )}
            </motion.div>
            <motion.div
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              onClick={handleHomeClick}
            >
              <Home className="cursor-pointer" size={30} strokeWidth={1.5} />
            </motion.div>
          </div>
        )}
      </motion.div>
    )}
  </AnimatePresence>
);

const MobileSidebar: React.FC<SidebarProps> = ({
  open,
  setOpen,
  townName,
  players,
  worldId,
  engineId,
  game,
  setSelectedElement,
  onPlayerClick,
  handleAddMember,
  toggleAudio,
  isAudioPlaying,
  handleHomeClick,
  worldStatus,
  isSpectator,
}) => (
  <AnimatePresence>
    {open && (
      <motion.div
        initial={{ height: 0, opacity: 0 }}
        animate={{
          height: "100%",
          opacity: 1,
        }}
        exit={{ height: 0, opacity: 0 }}
        transition={{ duration: 0.1, ease: "easeInOut" }}
        className="fixed inset-0 bg-neutral-900/90 text-white flex flex-col py-4 px-2 z-50 overflow-y-auto"
        style={{
          borderTop: "1px solid rgba(255, 255, 255, 0.2)",
        }}
      >
        <motion.div className="flex items-center justify-between mb-4">
          <ListSubheader className="!text-xl !text-white !font-main">
            {townName}
          </ListSubheader>
          <motion.div
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={() => setOpen(false)}
          >
            <ChevronDown />
          </motion.div>
        </motion.div>
        <motion.div className="flex justify-between items-center mb-2">
          <p className="!text-lg !text-white !font-main !font-bold">Members</p>
          {!isSpectator && (
            <motion.div
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              onClick={handleAddMember}
            >
              <SquarePlus fill="white" className="cursor-pointer !text-black" />
            </motion.div>
          )}
        </motion.div>
        <List className="flex-grow overflow-y-auto">
          {players
            .filter((player) => !player.human)
            .map((player) => (
              <SpotMemberDisplay
                key={player.id}
                user={false}
                worldId={worldId}
                engineId={engineId as Id<"engines">}
                playerId={player.id}
                game={game}
                setSelectedElement={setSelectedElement}
                onPlayerClick={onPlayerClick}
              />
            ))}
        </List>
        <Divider className="my-2" />
      </motion.div>
    )}
  </AnimatePresence>
);

interface GodviewSidebarProps {
  worldId: Id<"worlds">;
  engineId: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  game: any;
  setSelectedElement: (element: any) => void;
  onPlayerClick: (playerId: GameId<"players">) => void;
  creator: string;
  cryptoSpot?: boolean;
}

export default function GodviewSidebar({
  worldId,
  engineId,
  open,
  setOpen,
  game,
  setSelectedElement,
  onPlayerClick,
  creator,
}: GodviewSidebarProps) {
  const context = useContext(PlatformContext);
  const townContext = useContext(TownContext);
  if (!context || !townContext) {
    throw new Error(
      "useContext must be used within a PlatformContext.Provider and TownContext.Provider"
    );
  }

  const navigate = useNavigate();
  const { town_id, townName, isSpectator } = townContext;
  const players = [...(game?.world.players.values() || [])];

  const castTownId: Id<"worlds"> = town_id as Id<"worlds">;
  const [addMemberOpen, setAddMemberOpen] = useState(false);
  const result = useQuery(api.world.getWorldStatus, {
    worldId: castTownId,
  });
  const map = useQuery(api.world.getMap, { worldId });
  const mapAudioUrl = map?.mapAudioUrl
    ? `/music/${map.mapAudioUrl}`
    : undefined;
  const worldStatus = result?.worldStatus;
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const isMobile = window.innerWidth < 768;
  const handleHomeClick = () => {
    setSelectedElement(null);
    navigate("/spot");
  };

  const handleAddMember = () => {
    const nonHumanPlayers = players.filter((player) => !player.human);
    if (nonHumanPlayers.length >= 7) {
      alert(
        "Cannot add more members. Maximum limit of 7 non-human characters reached."
      );
    } else {
      setAddMemberOpen(true);
    }
  };

  const toggleAudio = () => {
    if (audioRef.current) {
      if (isAudioPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsAudioPlaying(!isAudioPlaying);
    }
  };

  useEffect(() => {
    if (mapAudioUrl) {
      audioRef.current = new Audio(mapAudioUrl);
      audioRef.current.loop = true;
    }
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
      }
    };
  }, [mapAudioUrl]);

  const sidebarProps: SidebarProps = {
    open,
    setOpen,
    townName,
    players,
    worldId,
    engineId,
    game,
    setSelectedElement,
    onPlayerClick,
    handleAddMember,
    toggleAudio,
    isAudioPlaying,
    handleHomeClick,
    worldStatus,
    isSpectator,
    creator,
  };

  return (
    <>
      {isMobile ? (
        <MobileSidebar {...sidebarProps} />
      ) : (
        <DesktopSidebar {...sidebarProps} />
      )}
      <AddMember
        open={addMemberOpen}
        setOpen={setAddMemberOpen}
        selectedCharacters={players}
        setSelectedCharacters={() => {}}
      />
    </>
  );
}
