import {
  Modal,
  ModalDialog,
  ModalClose,
  DialogContent,
  Typography,
  DialogTitle,
  List,
  Avatar,
  ListItem,
} from "@mui/joy";
import { useContext, useState } from "react";
import { Pencil } from "lucide-react";
import { PlatformContext } from "../../contexts/PlatformContext";

function PersonaPicker({
  open,
  setOpen,
  onChoose,
  activePersonaId,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  onChoose: (persona_id: string) => void;
  activePersonaId: string | null;
}) {
  const [hoveredPersona, setHoveredPersona] = useState<string | null>(null);
  const context = useContext(PlatformContext);

  if (!context) {
    return null;
  }

  const { userAssets } = context;
  const handleChoosePersona = (persona_id: string) => {
    // setOpen(false);
    onChoose(persona_id);
  };
  const getRandomColor = () => {
    const hue = Math.floor(Math.random() * 360);
    return `hsl(${hue}, 70%, 80%)`;
  };

  const getGradient = (color: string) => {
    const lighterColor = color.replace("80%", "90%");
    return `linear-gradient(to top left, ${color}, ${lighterColor})`;
  };

  const personaColors = userAssets.personas.reduce(
    (acc, persona) => {
      acc[persona.persona_id] = getRandomColor();
      return acc;
    },
    {} as Record<string, string>
  );
  return (
    <Modal open={open}>
      <ModalDialog className="!w-1/4 !bg-neutral-800 !rounded-2xl !border-neutral-700 !text-orange-100 !font-main">
        <ModalClose onClick={() => setOpen(false)} />
        <DialogTitle className="!text-orange-500 !text-2xl !font-main">
          Persona Picker
        </DialogTitle>
        <DialogContent>
          <div>
            <List className="gap-2 ">
              {userAssets.personas
                .filter((persona) => !persona.is_deleted)
                .map((persona) => (
                  <ListItem
                    key={persona.persona_id}
                    className="!cursor-pointer !rounded-lg hover:bg-neutral-700 !py-2"
                    onMouseEnter={() => setHoveredPersona(persona.persona_id)}
                    onMouseLeave={() => setHoveredPersona(null)}
                    onClick={() => handleChoosePersona(persona.persona_id)}
                  >
                    <Avatar
                      sx={{
                        background: getGradient(
                          personaColors[persona.persona_id]
                        ),
                      }}
                      src={`${process.env.REACT_APP_ASSETS_BUCKET}personas/${persona.persona_id}/display_picture.jpg`}
                    >
                      {persona.name.charAt(0)}
                    </Avatar>
                    <Typography className="!text-orange-100 !text-md !font-main">
                      {persona.name}
                    </Typography>
                    {activePersonaId === persona.persona_id && (
                      <Typography className="!text-orange-500 !text-sm !font-main ml-auto">
                        active
                      </Typography>
                    )}
                    {/* {hoveredPersona === persona.persona_id && (
                    <Pencil
                      size={20}
                      className="text-orange-500 align-end ml-auto"
                    />
                  )} */}
                  </ListItem>
                ))}
            </List>
          </div>
        </DialogContent>
      </ModalDialog>
    </Modal>
  );
}

export default PersonaPicker;
