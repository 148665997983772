import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  forwardRef,
  KeyboardEvent,
  useMemo,
} from "react";
import Stack from "@mui/material/Stack";
import CharacterTrending from "../Character/CharacterTrending";
import { PlatformContext } from "../../contexts/PlatformContext";
import { Character } from "../../types";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination as MuiPagination } from "@mui/material";
import "swiper/swiper-bundle.css";
import { Switch } from "@mui/material";
import CharacterImage from "../Misc/CharacterImage";
import { SelectedCharacters } from "./CreateBotcast";
import { useAuth0 } from "@auth0/auth0-react";
import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material/styles";
import { Button } from "@mui/material";
import "./Botcasts.css";
import SwiperCore, {
  FreeMode,
  Mousewheel,
  Grid,
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
} from "swiper/modules";
import "swiper/swiper-bundle.css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Swiper as SwiperInstance } from "swiper/types"; // Importing the type for Swiper instance
import { set } from "lodash";

const tags: string[] = [
  "Fantasy",
  "Anime",
  "Celebrities",
  "Comedy",
  "Historical",
  "Horror",
  "Role Play",
  "Sci-Fi",
  "Superheroes",
  "Gaming",
  "LGBTQ+",
  "Original Character", // "Original Character" tag is added to the list of tags
  "NSFW",
  "Movies & TV",
];
type ArrowProps = React.HTMLAttributes<HTMLButtonElement>;

const CustomPrevArrow = forwardRef<HTMLButtonElement, ArrowProps>(
  (props, ref) => (
    <button ref={ref} className="custom-swiper-button-prev" {...props}>
      &#10094; {/* Left arrow symbol */}
    </button>
  )
);

const CustomNextArrow = forwardRef<HTMLButtonElement, ArrowProps>(
  (props, ref) => (
    <button ref={ref} className="custom-swiper-button-next" {...props}>
      &#10095; {/* Right arrow symbol */}
    </button>
  )
);

CustomPrevArrow.displayName = "CustomPrevArrow";
CustomNextArrow.displayName = "CustomNextArrow";

interface SelectorProps {
  handleCharacterSelect: (character: Character) => void;
  selectedCharacters: SelectedCharacters;
}
function BotcastCharacterSelector({
  handleCharacterSelect,
  selectedCharacters,
}: SelectorProps) {
  const [selectedTags, setSelectedTags] = useState<string[]>(["Fantasy"]);
  //   const [itemsPerPage, setItemsPerPage] = useState<number>(12);
  //   const [currentPage, setCurrentPage] = useState(1);;
  const [characters, setCharacters] = useState<Character[]>([]);
  const [slidesPerGroup, setSlidesPerGroup] = useState<number>(1);
  const [rows, setRows] = useState<number>(3);
  const context = useContext(PlatformContext);
  if (!context) {
    throw new Error(
      "useContext must be used within a CharactersContext.Provider"
    );
  }

  interface SwiperProps {
    characters: Character[];
    handleCharacterSelect: (char: Character) => void;
  }

  const CharacterSwiper: React.FC<SwiperProps> = ({
    characters,
    handleCharacterSelect,
  }) => {
    const prevRef = useRef<HTMLButtonElement>(null);
    const nextRef = useRef<HTMLButtonElement>(null);
    useEffect(() => {
      if (characters.length > 6 && characters.length < 12) {
        setRows(2);
      } else if (characters.length < 6) {
        setRows(1);
      }
    }, [characters]);

    return (
      <>
        <Swiper
          modules={[
            Grid,
            FreeMode,
            Mousewheel,
            Navigation,
            Pagination,
            Scrollbar,
            A11y,
          ]}
          spaceBetween={10}
          slidesPerView={"auto"}
          touchRatio={1.5}
          speed={500}
          grid={{
            rows: characters.length > 6 ? 3 : 1,
            fill: "row",
          }}
          mousewheel={{
            forceToAxis: true, // Only allow mousewheel control in the axis direction of the swiper
            releaseOnEdges: true, // Prevents unexpected behavior when swiping to the edges
            thresholdDelta: 14, // Set a threshold before the mousewheel control triggers swiper slide
          }}
          freeMode={true}
          slidesPerGroup={slidesPerGroup}
          navigation={{
            prevEl: prevRef.current,
            nextEl: nextRef.current,
          }}
          onBeforeInit={(swiper: SwiperInstance) => {
            if (typeof swiper.params.navigation !== "boolean") {
              // Ensure it's not the boolean 'false'
              (swiper.params.navigation as any).prevEl = prevRef.current;
              (swiper.params.navigation as any).nextEl = nextRef.current;
            }
          }}
          className="mySwiper"
        >
          <CustomPrevArrow ref={prevRef} />
          <CustomNextArrow ref={nextRef} />
          {characters.map((char) => (
            <SwiperSlide key={char.character_id}>
              <div
                className={`choose-character-card ${
                  selectedCharacters[char.character_id] ? "selected" : ""
                }`}
                key={char.character_id}
                onClick={() => handleCharacterSelect(char)}
              >
                <img
                  src={char.image_url}
                  alt={char.name}
                  onError={(e) => {
                    const target = e.target as HTMLImageElement; // Cast to HTMLImageElement
                    target.onerror = null; // prevents looping
                    target.src =
                      "https://4thwall-assets.s3.amazonaws.com/default_assets/display_picture.jpg";
                  }}
                />
                {/* <CharacterImage image_url={char.image_url} name={char.name} /> */}
                <p className="choose-char-info">{char.name}</p>
                {selectedCharacters[char.character_id] && (
                  <span className="subscript">
                    {selectedCharacters[char.character_id]}
                  </span>
                )}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </>
    );
  };

  useEffect(() => {
    const updateSlidesPerGroup = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setSlidesPerGroup(2);
      } else if (width < 1024) {
        setSlidesPerGroup(3);
      } else {
        setSlidesPerGroup(5);
      }
    };

    updateSlidesPerGroup();
    window.addEventListener("resize", updateSlidesPerGroup);

    return () => {
      window.removeEventListener("resize", updateSlidesPerGroup);
    };
  }, []);
  const toggleTag = (tag: string): void => {
    let updatedTags: string[] = [];
    if (selectedTags.includes(tag)) {
      updatedTags = selectedTags.filter((t) => t !== tag);
    } else {
      updatedTags = [...selectedTags, tag];
    }
    if (updatedTags.length === 0) {
      updatedTags = [tag];
      return;
    }
    handleTagSubmit(updatedTags);
  };
  function parseSearchResults(searchResults: any) {
    const hits = searchResults.hits.hit;
    const filteredHits = hits.filter((hit: any) => {
      const { is_deleted, is_public } = hit.fields;
      const isDeleted = is_deleted !== "0";
      const isNotPublic = is_public !== "1";
      if (isDeleted || isNotPublic) {
        // console.log(
        //   `Character skipped (ID: ${hit.fields.character_id}, Name: ${hit.fields.name}) because is_deleted=${isDeleted} and is_public=${isNotPublic}`
        // );
        return false;
      }
      return true;
    });

    // Map over the hits array to transform each search hit into a Character object
    const characters = filteredHits.map((hit: any) => {
      const fields = hit.fields;

      return {
        bio: fields.bio,
        char_message_bg_color: fields.char_message_bg_color,
        char_text_color: fields.char_text_color,
        character_id: fields.character_id,
        chat_background_color: fields.chat_background_color,
        creator: fields.creator,
        user_message_bg_color: fields.user_message_bg_color,
        user_text_color: fields.user_text_color,
        name: fields.name,
        image_url: `https://4thwall-assets.s3.amazonaws.com/${fields.character_id}/display_picture`,
        chat_background_url: `https://4thwall-assets.s3.amazonaws.com/${fields.character_id}/chat_background`,
        interactions: fields.num_interactions,
        num_saves: fields.num_favorites,
        tags: fields.tags,
      };
    });

    return characters;
  }

  const handleTagSubmit = (currentTags: string[]): void => {
    if (currentTags.length === 0) {
      setCharacters([]);
      return;
    }
    const apiUrl = process.env.REACT_APP_SEARCH_API;
    if (!apiUrl) {
      throw new Error("API URL not defined in environment variables");
    }

    // Construct the CloudSearch query for selected tags
    // This will create a substring search for each tag
    const tagsQuery = currentTags
      .map((tag) => `(term field=tags '${tag}')`)
      .join(" ");
    const cloudSearchQuery = `q=(or ${tagsQuery})&fq=(and is_public:'1' is_deleted:'0')&q.parser=structured`;

    const searchUrl = `${apiUrl}?${cloudSearchQuery}&size=200`;

    // Use searchUrl in the fetch request directly without further encoding
    fetch(searchUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log("Search results:", data);
        setCharacters(parseSearchResults(data));
        setSelectedTags(currentTags);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  useEffect(() => {
    handleTagSubmit(selectedTags);
  }, []);

  const handleSearch = () => {
    const searchTerm = String(searchTermRef.current?.value);
    setIsLoading(true);
    // console.log("Search for:", searchTerm);
    var apiUrl = process.env.REACT_APP_SEARCH_API;
    if (!apiUrl) {
      throw new Error("API URL not defined in environment variables");
    }
    // const searchQuery = encodeURIComponent(searchTerm);
    const searchQuery = encodeURIComponent(searchTerm + "*");
    const queryString = `?q=${searchQuery}&fq=(and is_public:'1' is_deleted:'0')&size=1000`;

    apiUrl += queryString;

    // Now you can make the fetch call to the API
    fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          console.log("Search API responded with an error");
          alert("Search isn't working, please try again later!");
        }
        return response.json();
      })
      .then((data) => {
        // console.log("Search results:", data);
        const parsedCharacters = parseSearchResults(data);
        setCharacters(parsedCharacters);
        setIsLoading(false);
        // console.log("Parsed characters:", parsedCharacters);
      })
      .catch((error) => {
        console.error("There was an error performing the search:", error);
      });
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const [isLoading, setIsLoading] = useState(false);
  const searchTermRef = useRef<HTMLInputElement>(null); // Ref
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage: number = 21; // Adjust this to how many items you want per swiper/page
  const [totalPages, setTotalPages] = useState<number>(1);

  useEffect(() => {
    // Here you would fetch characters and then update total pages
    setTotalPages(Math.ceil(characters.length / itemsPerPage));
  }, [characters]);

  // Calculate the characters to show based on pagination
  const startIndex: number = (currentPage - 1) * itemsPerPage;
  const endIndex: number = startIndex + itemsPerPage;

  let combinedCharacters = [...characters];

  // Check if the user is authenticated and userAssets.characters exists
  const { userAssets, setUserAssets } = context;
  const { user, isAuthenticated } = useAuth0();
  if (isAuthenticated && userAssets.characters) {
    // Filter out PRIVATE characters (where is_public is false) from userAssets.characters
    const privateCharacters = userAssets.characters.filter(
      (char) => !char.is_public
    );
    // Combine the original characters with the private characters
    combinedCharacters = [...combinedCharacters, ...privateCharacters];
  }
  const charactersToShow: Character[] = characters.slice(startIndex, endIndex);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ): void => {
    setCurrentPage(value);
  };
  const [darkTheme, setDarkTheme] = useState(
    createTheme({
      palette: {
        mode: localStorage.getItem("darkMode") === "true" ? "dark" : "light",
      },
    })
  );
  return (
    <div>
      <div className="botcast-selector-header">
        <h2>
          <span>Choose two characters to create a botcast!</span>
        </h2>
        <div style={{ display: "flex" }}>
          <input
            type="text"
            placeholder="Search..."
            ref={searchTermRef} // Set the ref here
            onKeyPress={handleKeyPress}
            className="main-search-input"
          />
          <Button
            onClick={handleSearch}
            variant="contained"
            className="search-btn"
          >
            Search
          </Button>
        </div>
        <div className="tags-section">
          {tags.map((tag, index) => (
            <button
              className="tag-button"
              key={index}
              type="button"
              onClick={() => toggleTag(tag)}
              style={{
                backgroundColor: selectedTags.includes(tag)
                  ? "var(--tag-selected-color)"
                  : "var(--tag-bg-color)",
                color: selectedTags.includes(tag) ? "black" : "white",
                opacity: selectedTags.includes(tag) ? 1 : 0.5,
              }}
            >
              {tag}
            </button>
          ))}
        </div>
      </div>
      <div className="trending-characters">
        <CharacterSwiper
          characters={charactersToShow}
          handleCharacterSelect={handleCharacterSelect}
        />
      </div>
      {/* Pagination controls */}
      <ThemeProvider theme={darkTheme}>
        <Stack spacing={1} alignItems="center">
          <MuiPagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
          />
        </Stack>
      </ThemeProvider>
    </div>
  );
}

export default BotcastCharacterSelector;
