import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Button from "@mui/material/Button";
import "./Auth.css";

interface Props {
  redirectUrl: string;
}
const Login = ({ redirectUrl }: Props) => {
  const { loginWithRedirect } = useAuth0();

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={() =>
        loginWithRedirect({
          appState: {
            returnTo: redirectUrl,
          },
        })
      }
      className="login-button"
    >
      Log In
    </Button>
    // <button className="auth-button" onClick={() => loginWithRedirect()}>
    //   Log In
    // </button>
  );
};

export default Login;
