import { Stack, Text, Button } from "@chakra-ui/react";
import { useState } from "react";
import { useServerGame } from "../../../../../hooks/serverGame";
import { Id } from "../../../../../convex/_generated/dataModel";
import { GameId } from "../../../../../convex/aiTown/ids";
import { useQuery } from "convex/react";
import { api } from "../../../../../convex/_generated/api";

const TerminalBox = ({ worldId }: { worldId?: Id<"worlds"> }) => {
  const [displayLimit, setDisplayLimit] = useState(20);
  const MEMORIES_PER_PAGE = 20;

  const game = useServerGame(worldId);
  const playerDescriptions = game?.playerDescriptions;

  const allMemories = useQuery(api.agent.memory.getWorldUIMemories, {
    worldId: worldId as Id<"worlds">,
  });

  // Sort all memories by creation time descending
  const sortedMemories =
    allMemories?.sort((a, b) => b._creationTime - a._creationTime) ?? [];

  // Only display memories up to the current limit
  const displayedMemories = sortedMemories.slice(0, displayLimit);

  return (
    <Stack gap={6}>
      <Stack>
        <Stack align={"start"} gap={2}>
          {displayedMemories.map((memory) => {
            const player = playerDescriptions?.get(
              memory.playerId as GameId<"players">
            );
            return (
              <Text key={memory._id} fontSize={"sm"}>
                <Text as="span" fontWeight="bold" textTransform="uppercase">
                  {player?.name}:{" "}
                </Text>
                {memory.description}
              </Text>
            );
          })}
        </Stack>
        {sortedMemories.length > displayLimit && (
          <Button
            onClick={() =>
              setDisplayLimit((limit) => limit + MEMORIES_PER_PAGE)
            }
            size="sm"
            sx={{
              color: "brand.primary700",
              bg: "brand.primary100",
              _hover: {
                bg: "brand.primary200",
                color: "brand.primary800",
              },
            }}
          >
            Load More
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

export default TerminalBox;
