import React from "react";
import { IconButton, IconButtonProps, Tooltip } from "@mui/joy";

type MemoryButtonSize = "xs" | "small" | "medium" | "large" | "xl";

interface MemoryButtonProps extends Omit<IconButtonProps, "children" | "size"> {
  size?: MemoryButtonSize;
}
export default function MemoryButton({
  size = "medium",
  ...props
}: MemoryButtonProps) {
  const sizeClasses = {
    xs: "w-6 h-6",
    small: "w-8 h-8",
    medium: "w-10 h-10",
    large: "w-12 h-12",
    xl: "w-14 h-14",
  };

  return (
    <Tooltip title="View Memories">
      <IconButton
        {...props}
        className={`hover:!bg-fourwall-orange !rounded-full ${sizeClasses[size]}`}
      >
        <img src="/memory.svg" alt="Memory" />
      </IconButton>
    </Tooltip>
  );
}
