import React from "react";
import Dialog from "@mui/material/Dialog";
import { useNavigate } from "react-router-dom";
import DialogTitle from "@mui/material/DialogTitle";
import {
  List,
  ListItem,
  Modal,
  ModalClose,
  ModalDialog,
  Typography,
} from "@mui/joy";
import { Chat } from "../../types";
import "./Misc.css";
interface MultiChatPopupProps {
  open: boolean;
  chats: Chat[];
  onClose: () => void;
}

function MultiChatPopup({ open, chats, onClose }: MultiChatPopupProps) {
  // Sort chats in descending order by last interaction
  const sortedChats = chats.sort(
    (a, b) =>
      new Date(b.last_interaction).getTime() -
      new Date(a.last_interaction).getTime()
  );
  const navigate = useNavigate();

  const handleChatSelect = (chat: Chat) => {
    navigate(`/chat/${chat.character_id}/${chat.chat_id}`);
    onClose();
  };
  return (
    <Modal onClose={onClose} open={open}>
      <ModalDialog
        sx={{
          width: "15em",
          backgroundColor: "var(--profile-card-bg)",
          color: "var(--primary-text-color)",
          border: "none",
        }}
      >
        <img
          src={sortedChats[0].image_url}
          alt={sortedChats[0].name}
          className="multi-chat-popup-img"
          onError={(e) => {
            const target = e.target as HTMLImageElement; // Cast to HTMLImageElement
            target.onerror = null; // prevents looping
            target.src =
              "https://4thwall-assets.s3.amazonaws.com/default_assets/display_picture.jpg";
          }}
        />
        <h1 className="font-main m-auto">{sortedChats[0].name}</h1>
        <h3 className="font-main m-auto">Select chat to continue</h3>
        <List>
          {sortedChats.map((chat) => (
            <ListItem
              onClick={() => handleChatSelect(chat)}
              className="cursor-pointer hover:bg-fourwall-orange"
            >
              <Typography sx={{ color: "var(--primary-text-color)" }}>
                {`Chat date: ${new Date(
                  chat.last_interaction
                ).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}`}
              </Typography>
            </ListItem>
          ))}
        </List>
      </ModalDialog>
    </Modal>
  );
}

export default MultiChatPopup;
