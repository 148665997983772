import React from "react";
import clsx from "clsx";
import { useMutation, useQuery } from "convex/react";
import { KeyboardEvent, useRef, useState } from "react";
import { api } from "../../convex/_generated/api";
import { Id } from "../../convex/_generated/dataModel";
import { useSendInput } from "../../hooks/sendInput";
import { Player } from "../../convex/aiTown/player";
import { Conversation } from "../../convex/aiTown/conversation";
import { Textarea, CircularProgress } from "@mui/joy";
import { motion } from "framer-motion";
import { Send } from "lucide-react";
// import { Level } from "../../convex/aiTown/level";

export function MessageInput({
  worldId,
  engineId,
  humanPlayer,
  conversation,
}: {
  worldId: Id<"worlds">;
  engineId: Id<"engines">;
  humanPlayer: Player;
  conversation: Conversation;
}) {
  const descriptions = useQuery(api.world.gameDescriptions, { worldId });
  const [blockInput, setBlockInput] = useState(false);
  const humanName = descriptions?.playerDescriptions.find(
    (p: any) => p.playerId === humanPlayer.id
  )?.name;
  const inputRef = useRef<HTMLParagraphElement>(null);
  const inflightUuid = useRef<string | undefined>();
  const writeMessage = useMutation(api.messages.writeMessage);
  const startTyping = useSendInput(engineId, "startTyping");
  const currentlyTyping = conversation.isTyping;

  function generateUUID() {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c) =>
      (
        parseInt(c) ^
        (crypto.getRandomValues(new Uint8Array(1))[0] &
          (15 >> (parseInt(c) / 4)))
      ).toString(16)
    );
  }
  const sendMessage = async () => {
    if (!inputRef.current) {
      return;
    }
    const text = inputRef.current.innerText;
    inputRef.current.innerText = "";
    if (!text) {
      return;
    }
    let messageUuid = inflightUuid.current;
    if (currentlyTyping && currentlyTyping.playerId === humanPlayer.id) {
      messageUuid = currentlyTyping.messageUuid;
    }
    messageUuid = messageUuid || generateUUID();
    await writeMessage({
      worldId,
      playerId: humanPlayer.id,
      conversationId: conversation.id,
      text,
      messageUuid,
    });
  };

  const onSendClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    await sendMessage();
  };

  const onKeyDown = async (e: KeyboardEvent) => {
    e.stopPropagation();

    // Set the typing indicator if we're not submitting.
    if (e.key !== "Enter") {
      console.log(inflightUuid.current);
      if (currentlyTyping || inflightUuid.current !== undefined) {
        return;
      }
      inflightUuid.current = generateUUID();
      try {
        // Don't show a toast on error.
        await startTyping({
          playerId: humanPlayer.id,
          conversationId: conversation.id,
          messageUuid: inflightUuid.current,
        });
      } finally {
        inflightUuid.current = undefined;
      }
      return;
    }

    // Send the current message.
    e.preventDefault();
    await sendMessage();
  };
  return (
    <div className="mt-8 fixed bottom-0 w-[90%] items-center mb-4 flex gap-4 mx-10 justify-center">
      <div className={clsx("bubble", "bubble-mine", "flex-grow")}>
        {/* <Textarea
          className="!bg-orange-100 !font-chat !rounded-full !p-2 w-full resize-none !outline-none !text-black"
          ref={inputRef}
          color="warning"
          style={{ outline: "none" }}
          tabIndex={0}
          placeholder="Type here"
          onKeyDown={(e) => onKeyDown(e)}
        /> */}
        <div className={clsx("bubble", "bubble-mine")}>
          <p
            className=" rounded-full -m-1 py-2 px-4   bg-orange-100 text-black"
            ref={inputRef}
            contentEditable
            style={{ outline: "none" }}
            tabIndex={0}
            placeholder="Type here"
            onKeyDown={(e) => onKeyDown(e)}
          />
        </div>
      </div>
      <div className="flex gap-3">
        <motion.div whileTap={{ scale: 0.9 }}>
          <motion.button
            disabled={blockInput || !inputRef.current?.innerText}
            className={`z-0 inline-flex items-center justify-center border-none rounded-full p-2 text-white hover:bg-fourwall-orange ${
              blockInput || !inputRef.current?.innerText
                ? "bg-orange-300"
                : "bg-fourwall-orange"
            }`}
            onClick={(e) => onSendClick(e)}
            whileTap={{ scale: 0.9 }}
          >
            {!blockInput && <Send size={22} />}

            {blockInput && <CircularProgress size="sm" />}
          </motion.button>
        </motion.div>
      </div>
    </div>
  );
}

const styles: { [key: string]: React.CSSProperties } = {
  joinLeave: {
    fontFamily: "var(--font_b)",
    fontStyle: "italic",
    fontSize: "0.7rem",
    textAlign: "center",
    margin: "auto",
    color: "white",
  },
  messageMeta: {
    fontFamily: "var(--font_b)",
    fontSize: "0.7rem",
  },
  messageText: {
    fontFamily: "var(--font_chat)",
    fontSize: "0.8rem",
  },
};
