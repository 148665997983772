import React, { useState, useEffect, useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { PlatformContext } from "../../contexts/PlatformContext";
import { BotcastProp } from "../../types";
import BotcastCard from "./BotcastCard";

export interface SavedBotcastProps {
  character1_id: string;
  character2_id: string;
  botcast_id: string;
  character1_name: string;
  character2_name: string;
  character1_image_url: string;
  character2_image_url: string;
  last_interaction: string;
  topic: string;
  is_private: boolean;
}

function SavedBotcasts() {
  const context = useContext(PlatformContext);
  const [botcasts, setBotcasts] = useState<SavedBotcastProps[]>([]);
  const { user, isAuthenticated, isLoading } = useAuth0();
  if (!context) {
    throw new Error(
      "useContext must be used within a CharactersContext.Provider"
    );
  }
  const { characters, setCharacters, userAssets, setUserAssets } = context;

  // Function to add name and image_url to chatData based on character_id
  function enrichChatDataWithCharacterInfo(
    botcastData: BotcastProp
  ): SavedBotcastProps {
    const character1 = characters.find(
      (c) => c.character_id === botcastData.character1_id
    );
    const character2 = characters.find(
      (c) => c.character_id === botcastData.character2_id
    );
    if (character1) {
      botcastData.character1_name = character1.name;
      botcastData.character1_image_url =
        process.env.ASSETS_BUCKET +
        character1.character_id +
        "/display_picture.jpg";
    } else {
      console.error("Character not found for the given character_id");
    }

    if (character2) {
      botcastData.character2_name = character2.name;
      botcastData.character2_image_url =
        process.env.ASSETS_BUCKET +
        character2.character_id +
        "/display_picture.jpg";
    } else {
      console.error("Character not found for the given character_id");
    }
    return botcastData;
  }

  const fetchBotcasts = async () => {
    if (isAuthenticated && user) {
      const data = userAssets.botcasts.map((item: SavedBotcastProps) =>
        enrichChatDataWithCharacterInfo(item)
      );
      const sortedData = data.sort(
        (a, b) =>
          new Date(b.last_interaction).getTime() -
          new Date(a.last_interaction).getTime()
      );
      setBotcasts(data);
    }
  };
  useEffect(() => {
    fetchBotcasts();
  }, [isAuthenticated, user]);

  if (isLoading) {
    return <div>Loading...</div>;
  }
  // Dummy data for botcasts
  const dummyBotcasts: SavedBotcastProps[] = [
    {
      botcast_id: "1",
      character1_id: "char1",
      character2_id: "char2",
      character1_name: "Alice",
      character2_name: "Bob",
      character1_image_url:
        "https://4thwall-assets.s3.amazonaws.com/default_assets/display_picture.jpg",
      character2_image_url:
        "https://4thwall-assets.s3.amazonaws.com/default_assets/display_picture.jpg",
      last_interaction: new Date().toISOString(),
      topic: "Alice and Bob's Adventure",
      is_private: false,
    },
    {
      botcast_id: "2",
      character1_id: "char3",
      character2_id: "char4",
      character1_name: "Charlie",
      character2_name: "Diana",
      character1_image_url:
        "https://4thwall-assets.s3.amazonaws.com/default_assets/display_picture.jpg",
      character2_image_url:
        "https://4thwall-assets.s3.amazonaws.com/default_assets/display_picture.jpg",
      last_interaction: new Date(Date.now() - 86400000).toISOString(), // 1 day ago
      topic: "Charlie and Diana's Dialogue",
      is_private: true,
    },
  ];

  return (
    <div className="flex flex-row flex-wrap justify-center">
      {botcasts.map((botcast) => (
        <div key={botcast.botcast_id}>
          <BotcastCard
            {...botcast}
            setBotcasts={setBotcasts}
            setUserAssets={setUserAssets}
          />
        </div>
      ))}
    </div>
  );
}

export default SavedBotcasts;
