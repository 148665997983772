import {
  Modal,
  ModalDialog,
  ModalClose,
  DialogContent,
  DialogTitle,
  Input,
  Textarea,
} from "@mui/joy";
import { useContext, useState } from "react";
import { Pen, CircleX, Check } from "lucide-react";
import { PlatformContext } from "../../contexts/PlatformContext";
import { ChatContext } from "../../contexts/ChatContext";

function ScenarioEditor({
  open,
  setOpen,
  onUpdateScenario,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  onUpdateScenario: (scenario: string) => void;
}) {
  const [editing, setEditing] = useState(false);
  const context = useContext(PlatformContext);
  const chatContext = useContext(ChatContext);
  if (!context || !chatContext) {
    throw new Error(
      "useContext must be used within a PlatformContext.Provider and ChatContext.Provider"
    );
  }

  const { scenario, setScenario } = chatContext;
  const [unchangedScenario, setUnchangedScenario] = useState(scenario);

  return (
    <Modal open={open}>
      <ModalDialog className="md:!min-w-[60%] !min-w-[90%] !bg-neutral-800 !rounded-2xl !border-none !text-orange-100 !font-main">
        <ModalClose onClick={() => setOpen(false)} />
        <DialogTitle className="!text-orange-500 !text-2xl !font-main">
          Scenario
        </DialogTitle>
        <DialogContent className="!flex !flex-row !items-center !gap-2 !justify-between !m-0">
          {editing ? (
            <div className="flex flex-col md:flex-row items-center justify-between space-x-1 md:space-x-2">
              <Textarea
                value={scenario}
                color="warning"
                onChange={(e) => setScenario(e.target.value)}
                minRows={7}
                maxRows={20}
                className="!bg-neutral-700 !text-orange-100 !font-main !outline-none !border-none "
                sx={{
                  minWidth: { xs: 250, sm: 600 },
                  width: "100%",
                }}
              />
              <div className="flex flex-row m-2 justify-between space-x-2">
                <Check
                  size={24}
                  onClick={() => {
                    setEditing(false);
                    setScenario(scenario);
                    setUnchangedScenario(scenario);
                    onUpdateScenario(scenario);
                  }}
                  className="text-neutral-400 cursor-pointer"
                />
                <CircleX
                  size={24}
                  onClick={() => {
                    setEditing(false);
                    setScenario(unchangedScenario);
                  }}
                  className="text-neutral-400 cursor-pointer"
                />
              </div>
            </div>
          ) : (
            <div className="flex flex-col md:flex-row items-center justify-between space-x-1 md:space-x-2">
              <div className="!text-white md:m-4 m-2 max-w-[80%]">
                {scenario}
              </div>

              <Pen
                size={24}
                onClick={() => setEditing(!editing)}
                className="text-neutral-400 cursor-pointer"
              />
            </div>
          )}
        </DialogContent>
      </ModalDialog>
    </Modal>
  );
}

export default ScenarioEditor;
