import React, { useState, useEffect } from "react";
import { ReactNode } from "react";
import { ConvexReactClient, ConvexProvider } from "convex/react";
// import { ConvexProviderWithClerk } from 'convex/react-clerk';
// import { ClerkProvider, useAuth } from '@clerk/clerk-react';

/**
 * Fetches the Convex deployment URL based on the townId.
 *
 * @param townId - The ID of the town to fetch the Convex URL for.
 * @returns The Convex deployment URL.
 */
async function fetchConvexUrl(townId: string): Promise<string> {
  const response = await fetch(`/api/getConvexUrl?townId=${townId}`);
  // if (!response.ok) {
  //   throw new Error("Couldn’t fetch the Convex deployment URL.");
  // }
  // const data = await response.json();
  // return data.url;
  return process.env.REACT_APP_CONVEX_URL
    ? process.env.REACT_APP_CONVEX_URL
    : "";
  // return "https://giant-gerbil-299.convex.cloud";
}

export default function ConvexClientProvider({
  townId,
  children,
}: {
  townId: string;
  children: ReactNode;
}) {
  const [convex, setConvex] = useState<ConvexReactClient | null>(null);

  useEffect(() => {
    async function initializeConvex() {
      try {
        const url = await fetchConvexUrl(townId);
        const client = new ConvexReactClient(url, {
          unsavedChangesWarning: false,
        });
        setConvex(client);
      } catch (error) {
        console.error(error);
      }
    }
    initializeConvex();
  }, [townId]);

  if (!convex) {
    return <div>Loading...</div>;
  }

  return <ConvexProvider client={convex}>{children}</ConvexProvider>;
}
