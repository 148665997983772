import React, { useRef, useState, useEffect, FC, forwardRef } from "react";
import { Character } from "../../types";
import { Swiper as SwiperInstance } from "swiper/types"; // Importing the type for Swiper instance
import CharacterTrending from "../Character/CharacterTrending";
import Skeleton from "@mui/material/Skeleton";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  FreeMode,
  Mousewheel,
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
} from "swiper/modules";
interface FeaturedSwiperProps {
  featuredCharacters: Character[];
  handleCharacterSelect: (char: Character) => void;
  name: string;
  loaded: boolean;
}
type ArrowProps = React.HTMLAttributes<HTMLButtonElement> & {
  isVisible: boolean;
};

const CustomPrevArrow = forwardRef<HTMLButtonElement, ArrowProps>(
  ({ isVisible, ...props }, ref) => (
    <button
      ref={ref}
      className={`custom-swiper-button-prev transition-opacity duration-300 ${
        isVisible ? "opacity-100" : "opacity-0"
      }`}
      {...props}
    >
      &#10094; {/* Left arrow symbol */}
    </button>
  )
);

const CustomNextArrow = forwardRef<HTMLButtonElement, ArrowProps>(
  ({ isVisible, ...props }, ref) => (
    <button
      ref={ref}
      className={`custom-swiper-button-next transition-opacity duration-300 ${
        isVisible ? "opacity-100" : "opacity-0"
      }`}
      {...props}
    >
      &#10095; {/* Right arrow symbol */}
    </button>
  )
);

CustomPrevArrow.displayName = "CustomPrevArrow";
CustomNextArrow.displayName = "CustomNextArrow";

function FeaturedSwiper({
  featuredCharacters,
  handleCharacterSelect,
  name,
  loaded,
}: FeaturedSwiperProps) {
  const [slidesPerGroup, setSlidesPerGroup] = useState(1);
  const [isEnd, setIsEnd] = useState(false);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  useEffect(() => {
    const updateSlidesPerGroup = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setSlidesPerGroup(2);
      } else if (width < 1024) {
        setSlidesPerGroup(3);
      } else {
        setSlidesPerGroup(5);
      }
    };

    updateSlidesPerGroup();
    window.addEventListener("resize", updateSlidesPerGroup);

    return () => {
      window.removeEventListener("resize", updateSlidesPerGroup);
    };
  }, []);
  // repushing
  const prevRef = useRef<HTMLButtonElement>(null);
  const nextRef = useRef<HTMLButtonElement>(null);
  return (
    <div>
      <h3 className="text-white font-main">{name}</h3>
      <div
        className="relative"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Swiper
          modules={[
            FreeMode,
            Mousewheel,
            Navigation,
            Pagination,
            Scrollbar,
            A11y,
          ]}
          spaceBetween={10}
          slidesPerView={"auto"}
          touchRatio={1}
          speed={400}
          touchEventsTarget="container"
          mousewheel={{
            forceToAxis: true,
            releaseOnEdges: true,
          }}
          freeMode={true}
          slidesPerGroup={2}
          navigation={{
            prevEl: prevRef.current,
            nextEl: nextRef.current,
          }}
          onSlideChange={(swiper) => {
            setIsEnd(swiper.isEnd);
            setIsBeginning(swiper.isBeginning);
          }}
          onBeforeInit={(swiper: SwiperInstance) => {
            if (typeof swiper.params.navigation !== "boolean") {
              // Ensure it's not the boolean 'false'
              (swiper.params.navigation as any).prevEl = prevRef.current;
              (swiper.params.navigation as any).nextEl = nextRef.current;
            }
          }}
          className="mySwiper"
        >
          <CustomPrevArrow ref={prevRef} isVisible={isHovered} />
          <CustomNextArrow ref={nextRef} isVisible={isHovered} />
          {featuredCharacters.map((char) => (
            <SwiperSlide
              key={char.character_id}
              onClick={() => handleCharacterSelect(char)}
              className="!py-4 !px-1/2"
            >
              {loaded ? (
                <CharacterTrending
                  character={char}
                  key={char.character_id}
                  theme={false}
                  onClick={() => handleCharacterSelect(char)}
                />
              ) : (
                <Skeleton variant="rectangular" width={210} height={118} />
              )}
            </SwiperSlide>
          ))}
        </Swiper>
        {!isEnd && (
          <div
            className="absolute top-0 right-0 bottom-0 w-12 pointer-events-none z-10"
            style={{
              background:
                "linear-gradient(to right, rgba(0,0,0,0.0), rgba(0,0,0,0.6))",
            }}
          ></div>
        )}
        {!isBeginning && (
          <div
            className="absolute top-0 left-0 bottom-0 w-12 pointer-events-none z-10"
            style={{
              background:
                "linear-gradient(to left, rgba(0,0,0,0), rgba(0,0,0,0.6))",
            }}
          ></div>
        )}
      </div>
    </div>
  );
}

export default FeaturedSwiper;
