import axios from "axios";
const BE_URL = process.env.REACT_APP_BE_URL;

export const axiosPrivate = axios.create({
  baseURL: BE_URL,
  headers: { "Content-Type": "application/json" },
});

export const axiosPublic = axios.create({
  baseURL: BE_URL,
  headers: { "Content-Type": "application/json" },
});
