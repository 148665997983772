import React from "react";
import { SelectedCharacterProps } from "../types";
export interface TownContextState {
  townName: string;
  setTownName: React.Dispatch<React.SetStateAction<string>>;
  town_id: string;
  charactersMetadata: SelectedCharacterProps[];
  setCharactersMetadata: React.Dispatch<
    React.SetStateAction<SelectedCharacterProps[]>
  >;
  isSpectator: boolean;
  creator: string;
  cryptoSpot: boolean;
}
export const TownContext = React.createContext<TownContextState | undefined>(
  undefined
);
