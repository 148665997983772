import React from "react";
import { useMutation, useQuery } from "convex/react";
import { api } from "../../convex/_generated/api";
import { motion } from "framer-motion";
import { Pause, Play } from "lucide-react";
import Btn from "../AgentTownSidePanel/components/Buttons/Btn";

export default function FreezeButton({ cryptoSpot, worldStatus }: any) {
  const stopAllowed = useQuery(api.testing.stopAllowed) ?? false;
  const defaultWorld = worldStatus;
  const frozen =
    defaultWorld?.status === "stoppedByDeveloper" ||
    defaultWorld?.status === "inactive";
  const unfreeze = useMutation(api.testing.resume);
  const freeze = useMutation(api.testing.stop);

  const flipSwitch = async () => {
    if (frozen) {
      console.log("Unfreezing");
      await unfreeze({
        worldId: defaultWorld.worldId,
      });
    } else {
      console.log("Freezing");
      await freeze({
        worldId: defaultWorld.worldId,
      });
    }
  };

  return !stopAllowed ? null : (
    <motion.div
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      onClick={flipSwitch}
      className={`cursor-pointer rounded px-3 py-1 !font-main ${
        cryptoSpot &&
        (frozen
          ? "!bg-fourwall-orange hover:!bg-fourwall-orange/80"
          : "!bg-gray-800 hover:!bg-gray-700")
      }`}
    >
      {cryptoSpot ? (
        <span className="text-sm text-white">
          {frozen ? "Unfreeze" : "Freeze"}
        </span>
      ) : (
        <>
          {frozen ? (
            <Play size={24} className="text-gray-400" />
          ) : (
            <Pause size={24} className="text-gray-400" />
          )}
        </>
      )}
    </motion.div>
  );
}
