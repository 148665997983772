// import React from "react";
// import { Graphics } from "@pixi/react";
// import { Graphics as PixiGraphics } from "pixi.js";
// import { useCallback } from "react";
// // import { Doc } from "../../convex/_generated/dataModel";
// import { Player } from "../../convex/aiTown/player";
// import { unpackPathComponent } from "../../convex/util/types";

// export function DebugPath({
//   player,
//   tileDim,
// }: {
//   player: Player;
//   tileDim: number;
// }) {
//   const path =
//     player.pathfinding?.state.kind == "moving" && player.pathfinding.state.path;
//   const draw = useCallback(
//     (g: any) => {
//       g.clear();
//       if (!path) {
//         return;
//       }
//       let first = true;
//       for (const p of path) {
//         const { position } = unpackPathComponent(p as any);
//         const x = position.x * tileDim + tileDim / 2;
//         const y = position.y * tileDim + tileDim / 2;
//         if (first) {
//           g.moveTo(x, y);
//           g.lineStyle(2, debugColor(player.id), 0.5);
//           first = false;
//         } else {
//           g.lineTo(x, y);
//         }
//       }
//     },
//     [path]
//   );
//   return path ? <Graphics draw={draw} /> : null;
// }
// function debugColor(_id: string) {
//   return { h: 0, s: 50, l: 90 };
// }

import React, { useCallback, useEffect, useState } from "react";
import { Graphics } from "@pixi/react";
import { Graphics as PixiGraphics } from "pixi.js";
import { Player } from "../../convex/aiTown/player";
import { unpackPathComponent } from "../../convex/util/types";

export function DebugPath({
  player,
  tileDim,
}: {
  player: Player;
  tileDim: number;
}) {
  const [visible, setVisible] = useState(true);
  const path =
    player.pathfinding?.state.kind == "moving" && player.pathfinding.state.path;

  useEffect(() => {
    const interval = setInterval(() => {
      setVisible((v) => !v);
    }, 500); // Blink every 500ms

    return () => clearInterval(interval);
  }, []);

  const draw = useCallback(
    (g: PixiGraphics) => {
      g.clear();
      if (!path || !visible) {
        return;
      }
      const finalPosition = unpackPathComponent(
        path[path.length - 1] as any
      ).position;
      const x = finalPosition.x * tileDim + tileDim / 2;
      const y = finalPosition.y * tileDim + tileDim / 2;

      g.beginFill(0xff0000, 0.5); // Red color with 50% opacity
      g.drawCircle(x, y, tileDim / 4); // Draw a circle with radius 1/4 of tile dimension
      g.endFill();
    },
    [path, visible, tileDim]
  );

  return path ? <Graphics draw={draw} /> : null;
}
