import React, { useState, useRef, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import Footer from "./Footer";
import Faq from "react-faq-component";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";

const styles = {
  bgColor: "transparent",
  titleTextColor: "var(--primary-text-color)",
  titleTextSize: "2rem",
  rowTitleColor: "var(--primary-text-color)",
  rowTitleTextSize: "large",
  rowContentColor: "var(--secondary-text-color)",
  rowContentTextSize: "1rem",
  rowContentPaddingTop: "10px",
  rowContentPaddingBottom: "10px",
  timingFunc: "ease",
};

const communityAndSupportData = {
  title: "",
  rows: [
    {
      title: "General Principles",
      content:
        "1. Respect and Inclusion: Treat all users with respect. Discrimination, harassment, and hate speech will not be tolerated.<br>2. Creativity and Fun: Encourage creativity and positive engagement. We are all here to have fun and enjoy the experience.<br>3. Safety and Security: Ensure the safety and security of all users by reporting any suspicious or harmful behavior.",
    },
    {
      title: "Prohibited Content and Behavior",
      content:
        "Characters created with intent to engage in the following activities are liable for moderation:<br>1. Underage Sexual Acts: Any depiction or discussion involving sexual acts with minors is strictly prohibited.<br>2. Non-Consensual Sexual Acts and Bestiality: Content involving non-consensual acts or bestiality is forbidden.<br>3. Incest: Characters engaging in or promoting incestuous relationships are not allowed.<br>4. Incitement to Violence and Harassment: Any content that incites violence or targets harassment toward any race, religion, gender, or nationality is strictly forbidden.<br>5. Unauthorized Use of Real People’s Images: Characters whose images contain real people who are not celebrities or public figures are liable for moderation..",
    },
    {
      title: "Graphic Content in Images",
      content:
        "Graphic content used to depict character images is liable for moderation. This includes but is not limited to:<br>1. Hate Symbols: Any symbols or imagery that promote hate speech or discrimination are forbidden.<br>2. Sexual Content:  Explicit sexual content in images, especially that which is non-consensual or inappropriate, is prohibited.<br>3. Violence: Excessive or gratuitous violence is not allowed.",
    },
    {
      title: "Special Exceptions",
      content:
        "Special exceptions may be made (based on our discretion) for famous characters with storylines that include one or more of the above prohibited elements. These exceptions will be carefully evaluated on a case-by-case basis.",
    },
    {
      title: "Reporting and Moderation",
      content:
        "We rely on our community to help maintain these standards. If you encounter any content or behavior that violates these rules, please report it to our moderation team via <a href='https://discord.gg/EUebsQR5KE' target='_blank' rel='noopener noreferrer' style={{ color: 'inherit' }}>discord</a> or <a href='mailto:admin@4wall.ai' target='_blank' rel='noopener noreferrer' style={{ color: 'inherit' }}>email</a>. Our moderators will review the report and take appropriate action, which may include warnings, content removal, or account suspension.",
    },
    {
      title: "Consequences of Violating Community Standards",
      content:
        "Violating these community standards can result in the following actions:<br>• Warnings: For minor infractions, users may receive a warning.<br>• Content Removal: Content that violates our standards will be removed.<br>• Account Suspension: Repeated or severe violations may result in account suspension or termination.",
    },
  ],
};

function CommunityStandards() {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };
  return (
    <div className="background">
      <ArrowBackIosNewRoundedIcon
        onClick={handleBack}
        className="create-back-arrow"
      />
      <div className="community-rules">
        <h1>Community Rules & Standards</h1>
        <h3>
          4Wall is dedicated to creating a fun, inclusive, and safe environment
          for all users. To ensure everyone has an enjoyable and respectful
          experience, we have established the following community standards. All
          characters and interactions on 4Wall must adhere to these guidelines.
        </h3>

        <Faq styles={styles} data={communityAndSupportData} />
        <h3>
          By adhering to these community standards, we can ensure that 4Wall
          remains a fun, creative, and safe place for everyone. Thank you for
          being a part of our community and for helping to maintain a positive
          environment.<br></br>
          <br></br>
          If you have any questions or need further clarification, please feel
          free to reach out to our support team via{" "}
          <a
            href="mailto:admin@4wall.ai"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "inherit" }}
          >
            email
          </a>
          .<br></br>
          <br></br>
          Let's work together to foster a welcoming and respectful community!
        </h3>
      </div>
      <Footer />
    </div>
  );
}
export default CommunityStandards;
