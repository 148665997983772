import React, { useState, useRef, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import Footer from "./Footer";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";

const styles = {
  bgColor: "transparent",
  titleTextColor: "var(--primary-text-color)",
  titleTextSize: "2rem",
  rowTitleColor: "var(--primary-text-color)",
  rowTitleTextSize: "large",
  rowContentColor: "var(--primary-text-color)",
  rowContentTextSize: "1rem",
  rowContentPaddingTop: "10px",
  rowContentPaddingBottom: "10px",
  // rowContentPaddingRight: '150px',
  // arrowColor: "red",
  //transitionDuration: "1s",
  timingFunc: "ease",
};

const communityAndSupportData = {
  title: "",
  rows: [
    {
      title: "General Principles",
      content:
        "1. Respect and Inclusion: Treat all users with respect. Discrimination, harassment, and hate speech will not be tolerated.<br>2. Creativity and Fun: Encourage creativity and positive engagement. We are all here to have fun and enjoy the experience.<br>3. Safety and Security: Ensure the safety and security of all users by reporting any suspicious or harmful behavior.",
    },
    {
      title: "Prohibited Content and Behavior",
      content:
        "Characters created with intent to engage in the following activities are liable for moderation:<br>1. Underage Sexual Acts: Any depiction or discussion involving sexual acts with minors is strictly prohibited.<br>2. Non-Consensual Sexual Acts and Bestiality: Content involving non-consensual acts or bestiality is forbidden.<br>3. Incest: Characters engaging in or promoting incestuous relationships are not allowed.<br>4. Incitement to Violence and Harassment: Any content that incites violence or targets harassment toward any race, religion, gender, or nationality is strictly forbidden.<br>5. Unauthorized Use of Real People’s Images: Characters whose images contain real people who are not celebrities or public figures are liable for moderation..",
    },
    {
      title: "Graphic Content in images",
      content:
        "Graphic content used to depict character images is liable for moderation. This includes but is not limited to:<br>1. Hate Symbols: Any symbols or imagery that promote hate speech or discrimination are forbidden.<br>2. Sexual Content:  Explicit sexual content in images, especially that which is non-consensual or inappropriate, is prohibited.<br>3. Violence: Excessive or gratuitous violence is not allowed.",
    },
    {
      title: "Special Exceptions",
      content:
        "Special exceptions may be made (based on our discretion) for famous characters with storylines that include one or more of the above prohibited elements. These exceptions will be carefully evaluated on a case-by-case basis.",
    },
    {
      title: "Reporting and Moderation",
      content:
        "We rely on our community to help maintain these standards. If you encounter any content or behavior that violates these rules, please report it to our moderation team via <a href='https://discord.gg/EUebsQR5KE' target='_blank' rel='noopener noreferrer'>discord</a> or <a href='mailto:admin@4wall.ai' target='_blank' rel='noopener noreferrer' style={{ color: 'inherit' }}>email</a>. Our moderators will review the report and take appropriate action, which may include warnings, content removal, or account suspension.",
    },
    {
      title: "Consequences of Violating Community Standards",
      content:
        "Violating these community standards can result in the following actions:<br>• Warnings: For minor infractions, users may receive a warning.<br>• Content Removal: Content that violates our standards will be removed.<br>• Account Suspension: Repeated or severe violations may result in account suspension or termination.",
    },
  ],
};

function PrivacyPolicy() {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };
  return (
    <div className="background">
      <ArrowBackIosNewRoundedIcon
        onClick={handleBack}
        className="create-back-arrow"
      />
      <div className="community-rules">
        <h1>4Wall AI Privacy Policy</h1>
        <h3>Last updated: June 22, 2024</h3>
        {/* <Faq styles={styles} data={communityAndSupportData} /> */}
        <h3>1. Introduction</h3>
        <h4>
          This Privacy Policy (the “Privacy Policy” or “Policy”) describes the
          types of information 4Wall AI, Inc. and its affiliates
          ("beta.4wall.ai", “4Wall.AI", "4Wall AI", "4Wall", “we,” “our,” or
          “us”) collect and process from and about you.<br></br>
          <br></br>
          Except as described in this Privacy Policy, the Policy applies to any
          and all websites, mobile applications, and any other electronic and/or
          digital products and/or other services that are made available by
          4wall.ai and that link to this Policy, and our offline services
          (collectively, the “Services”).<br></br>
          <br></br>
          By using the Services, you are agreeing to the practices described in
          this Policy. If you do not agree to the practices described in this
          Policy, please do not access or use the Services.
        </h4>

        <h3>2. Information We Collect</h3>
        <h4>
          We collect information to provide better services to our users. The
          information we collect falls into two categories:<br></br>
          <ul>
            <li>
              2.1 Information You Provide to Us: We may collect information
              directly from you. For example, you may provide us with
              information when you use the Services, communicate with us,
              register for an account, subscribe to newsletters, or participate
              in a promotion. Information you provide directly to us may concern
              you and others and may include, but is not limited to:
              <ul>
                <li>
                  Personal Information: When you register on our site, we
                  collect personal information such as your name, username,
                  email address, and any other information you choose to
                  provide.
                </li>
                <li>
                  Content: Your User Content (as defined in the Terms of
                  Service), such as chat communications, posted images, and
                  shared Characters; and Contents of communications with us.
                </li>
              </ul>
            </li>
            <br></br>
            <li>
              2.2 Information We Collect Automatically: We and our third-party
              vendors, which include ad networks and analytics companies such as
              Google Analytics, may use cookies, web beacons, and other tracking
              technologies to collect information about the computers or devices
              (including mobile devices) you use to access the Services. As
              described further below, we may collect and analyze information
              including but not limited to
              <ul>
                <li>browser type; </li>
                <li>ISP or operating system; </li>
                <li>domain name; </li>
                <li>access time; </li>
                <li>referring or exit pages; </li>
                <li>page views; </li>
                <li>IP address; </li>
                <li>unique device identifiers (e.g. IDFA or Android ID); </li>
                <li>version of our Services you’re using; and </li>
                <li>the type of device that you use. </li>
                <br></br>
              </ul>
              We may also track when and how frequently you access or use the
              Services, including how you engage with or navigate our site or
              mobile application. We may use this information (including the
              information collected by our third-party vendors) for analytics
              (including to determine which portions of the Services are used
              most frequently and what our users like/do not like), to evaluate
              the success of any advertising campaigns, and as otherwise
              described in this Policy.<br></br>
              <br></br>
              We and our third-party vendors may use cookies, clear GIFs, pixel
              tags, and other technologies that help us better understand user
              behavior, personalize preferences, perform research and analytics,
              and improve the Services. These technologies, for example, may
              allow us to tailor the Services to your needs, save your password
              in password-protected areas, track the pages you visit, help us
              manage content, and compile statistics about usage of our
              Services. We or our third-party vendors also may use certain of
              these technologies in emails to our customers to help us track
              email response rates, identify when our emails are viewed, and
              track whether our emails are forwarded.<br></br>
              <br></br>
              Most web browsers automatically accept cookies, but your browser
              may allow you to modify your browser settings to decline cookies
              if you prefer. If you disable cookies, you may be prevented from
              taking full advantage of the Services, because the Services may
              not function properly. As we adopt additional technologies, we may
              also gather additional information through other methods. For more
              information, see https://www.allaboutcookies.org/.<br></br>
              <br></br>
              When you use the Services, we may collect general location
              information (such as general location inferred from an IP
              address).
            </li>
            <br></br>
            <li>
              2.3 Information we collect from other sources: We may also collect
              information about you from other parties, such as when you are
              referred to us by another user. We may also receive information
              about you from third party social platforms, such as x (fka
              Twitter), Discord, or Instagram, when you interact with our social
              media profiles.<br></br>
              <br></br>
              We may also collect information when you connect to your account
              using an account maintained by a third party, such as a social
              media account (“Third-Party Account”). The Services may collect
              information about you from your Third-Party Accounts in accordance
              with your permissions. When you connect to us through a
              Third-Party Account like Google, we receive information from that
              third party identifying your account. We collect and store this
              information and use it to help you connect to the Services.
              Connecting your account to a Third-Party Account is completely
              optional, and you will have the opportunity to grant permission
              when you attempt to connect. You can revoke permission by logging
              into the Third-Party Account and disconnecting Character.AI from
              there, and through the native applications on your smartphone. We
              may retain the information we collected previously from you.
            </li>
          </ul>
          You are not required to provide us with such information, but certain
          features of the Services may not be accessible or available absent the
          provision of the requested information.
        </h4>
        <h3>3. How We Use Your Information</h3>
        <h4>
          We may use your information for any of the following purposes:
          <ul>
            <li>Provide and administer the Services;</li>
            <li>Personalize the Services;</li>
            <li>
              Communicate with you, including to information you about features
              or aspects of the Services we believe might be of interest to you,
              or to communicate with you about changes to our terms, conditions,
              or policies;
            </li>
            <li>
              Engage in other communications with you, such as to respond to
              your inquiries, comments, feedback, or questions;
            </li>
            <li>
              Analyze, maintain, improve, modify, customize, and measure the
              Services, including to train our artificial intelligence/machine
              learning models;
            </li>
            <li>Develop new programs and services;</li>
            <li>
              Detect and prevent fraud, criminal activity, or misuses of our
              Service, and to ensure the security of our IT systems,
              architecture, and networks;
            </li>
            <li>
              Comply with legal obligations and legal process and to protect our
              rights, privacy, safety, or property, and/or that of our
              affiliates, you, or other parties, including to enforce our Terms
              of Service and any other agreements; and
            </li>
            <li>
              Carry out any other purpose for which the information was
              collected.
            </li>
          </ul>
          We may combine information that we collect from you through the
          Services with information that we obtain from other sources. We may
          also aggregate and/or de-identify information collected through the
          Services. We may use and disclose de-identified or aggregated data for
          any purpose, including without limitation for research and marketing
          purposes.
        </h4>
        <h3>4. Sharing Your Information</h3>
        <h4>
          We may disclose your information in any of the following
          circumstances:
          <ul>
            <li>
              Affiliates: We may disclose information to our affiliates, meaning
              an entity that controls, is controlled by, or is under common
              control with 4Wall.AI. Our affiliates may use the information we
              disclose in a manner consistent with this Policy.
            </li>
            <li>
              Vendors: We may disclose your information to employees,
              consultants, and other vendors who need access to such information
              to carry out work or perform services on our behalf, such as those
              who provide data storage, payment, technology support and
              services, customer service, analytics, fraud prevention, legal
              services, and marketing services.
            </li>
            <li>
              Safety and Protection of 4Wall.AI and Others: We may disclose
              certain information if we believe in good faith that doing so is
              necessary or appropriate to (i) protect or defend the 4Wall.AI or
              other parties, including to defend or enforce this Policy, our
              Terms of Service, or any other contractual arrangement; and/or
              (ii) protect the rights, property or personal safety of 4Wall.AI,
              our agents and affiliates, our employees, users and/or the public.
            </li>
            <li>
              Advertising and Analytics: Though we do not engage in advertising
              as of the date of this Policy, we may in the future disclose or
              make available some of your information with advertising and
              analytics partners to serve advertisements on our behalf across
              the internet and to provide analytics services. These entities may
              use cookies and tracking technologies to allow us to, among other
              things, track and analyze data, determine the popularity of
              certain content, deliver advertising and content targeted to your
              interests and better understand your online activity.
            </li>
            <li>
              Legal Requirements. We may disclose certain information if we
              believe in good faith that doing so is necessary or appropriate to
              comply with any law enforcement, legal, or regulatory process,
              such as to respond to a warrant, subpoena, court order, or other
              applicable laws and regulations.
            </li>
            <li>
              Business Transfers. We may disclose certain information, in
              connection with or during negotiations or closing of any merger,
              sale of company assets, financing, or acquisition of all or a
              portion of our business to another company.
            </li>
            <li>
              Your User Content. Certain actions you take may be visible to
              other users of the Services or result in content that is made
              available to other users of the Services. For example, if you
              create User Content, such as creating shared Characters, we may
              also make that available on the Services. If you communicate using
              a Group Chat, you are directing us to make your chats available to
              other members of that Group Chat, which may include Characters on
              the Services and other users.
            </li>
            <li>
              Other Disclosures with Consent or at Your Direction. We may
              disclose your information to nonaffiliated third parties based on
              your consent to do so. For example, you may direct us to disclose
              information about you when you refer us or our Services to your
              friends or other acquaintances.
            </li>
          </ul>
        </h4>
        <h3>5. Your Rights and Choices</h3>
        <h4>
          You have certain rights regarding your personal information, including
          the right to access, correct, and delete your information. You can
          exercise these rights by contacting us at [contact email].
        </h4>
        <h3>6. Data Security</h3>
        <h4>
          We take reasonable measures to protect your personal information from
          unauthorized access, use, and disclosure. However, no security system
          is impenetrable, and we cannot guarantee the absolute security of your
          information.
        </h4>
        <h3>7. Changes to This Privacy Policy</h3>
        <h4>
          We may update this Privacy Policy from time to time. We will notify
          you of any changes by posting the new Privacy Policy on our site. You
          are advised to review this Privacy Policy periodically for any
          changes.
        </h4>
        <h3>Contact Us</h3>
        <h4>
          If you have any questions about this Privacy Policy, please contact us
          at: admin@4wall.ai
        </h4>
      </div>
      <Footer />
    </div>
  );
}
export default PrivacyPolicy;
