import React, { useState } from "react";
import HelpIcon from "@mui/icons-material/Help";
import Dialog from "@mui/material/Dialog";
// import DialogTitle from "@mui/material/DialogTitle";
// import DialogContent from "@mui/material/DialogContent";
// import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  Modal,
  ModalDialog,
  ModalClose,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@mui/joy";
import "./Create.css";
interface Props {
  infoText: string;
  title: string;
}

const InfoBubble: React.FC<Props> = ({ infoText, title }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div style={{ position: "relative" }}>
      <HelpIcon onClick={handleClickOpen} style={{ cursor: "pointer" }} />
      <Modal open={open} onClose={handleClose}>
        <ModalDialog className="!bg-neutral-700 !border-none !text-orange-500 !font-main !min-w-[30%]">
          <DialogTitle className="!text-orange-500 !font-main !text-md md:!text-2xl">
            {title}
            <ModalClose />
          </DialogTitle>
          <DialogContent className="!text-white  !p-1 space-y-12 ">
            <div dangerouslySetInnerHTML={{ __html: infoText }} />
          </DialogContent>
        </ModalDialog>
      </Modal>
      {/* <Dialog onClose={handleClose} open={open} className="info-popup">
        <DialogTitle style={{ color: "var(--primary-text-color" }}>
          {title}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            style={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ color: "var(--primary-text-color" }} dividers>
          <div dangerouslySetInnerHTML={{ __html: infoText }} />
        </DialogContent>
      </Dialog> */}
    </div>
  );
};

export default InfoBubble;
