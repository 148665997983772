import { DialogTitle } from "@mui/material";
import { DialogContent } from "@mui/material";
import ReplyRoundedIcon from "@mui/icons-material/ReplyRounded";
import { SocialIcon } from "react-social-icons";
import { styled } from "@mui/material/styles";
import { Modal, ModalDialog, ModalClose, Button, Input } from "@mui/joy";
import {
  TwitterShareButton,
  WhatsappShareButton,
  RedditShareButton,
  FacebookShareButton,
  FacebookMessengerShareButton,
} from "react-share"; // These are from 'react-share' library
import { RiGitRepositoryPrivateFill } from "react-icons/ri";
// import { copyToClipboard } from "./utils"; // Assume you have a utility function to handle clipboard actions

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  url: string;
  name: string;
  id: string;
}
const SharePopup = ({ open, setOpen, url, name, id }: Props) => {
  const handleClose = () => setOpen(false);
  const shareCaption = `Check out ${name} on 4thWall AI 🔥! #4thwallai`;
  const FlippedReplyIcon = styled(ReplyRoundedIcon)({
    transform: "scaleX(-1)",
    cursor: "pointer",
  });
  const popup_heading = "Share Character";
  const copyToClipboard = (text: string) => {
    if (!navigator.clipboard) {
      // Clipboard API not available, use document.execCommand fallback
      const textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        const successful = document.execCommand("copy");
        const msg = successful ? "successful" : "unsuccessful";
        console.log("Fallback: Copying text command was " + msg);
      } catch (err) {
        console.error("Fallback: Oops, unable to copy", err);
      }
      document.body.removeChild(textArea);
    } else {
      // Clipboard API available
      navigator.clipboard.writeText(text).then(
        () => console.log("Async: Copying to clipboard was successful!"),
        (err) => console.error("Async: Could not copy text: ", err)
      );
    }
  };
  //   const handleCopyLink = () => copyToClipboard(url);

  return (
    <>
      {/* <ShareIcon onClick={handleOpen} /> */}
      <Modal open={open} onClose={handleClose}>
        <ModalDialog
          style={{
            backgroundColor: "var(--profile-card-bg)",
            color: "var(--primary-text-color)",
            border: "none",
            width: "50%",
          }}
        >
          <ModalClose />

          <DialogTitle>{popup_heading}</DialogTitle>

          <DialogContent dividers>
            <p>Don't worry! This won't share your conversation.</p>
            <div style={{ display: "flex" }}>
              <Input
                type="text"
                value={url}
                readOnly
                className="copy-link"
                style={{ width: "100%" }}
              />
              <Button
                color="primary"
                className="copy-button"
                onClick={() => copyToClipboard(url)}
              >
                Copy Link
              </Button>
            </div>

            <div className="flex flex-row gap-2 my-4 justify-center">
              <TwitterShareButton url={url} title={shareCaption}>
                <SocialIcon network="twitter" className="social-share-button" />
              </TwitterShareButton>
              <WhatsappShareButton url={url} title={shareCaption}>
                <SocialIcon
                  network="whatsapp"
                  className="social-share-button"
                />
              </WhatsappShareButton>
              <RedditShareButton url={url} title={shareCaption}>
                <SocialIcon network="reddit" className="social-share-button" />
              </RedditShareButton>
              <FacebookShareButton url={url} title={shareCaption}>
                <SocialIcon
                  network="facebook"
                  className="social-share-button"
                />
              </FacebookShareButton>
            </div>
          </DialogContent>
        </ModalDialog>
      </Modal>
    </>
  );
};

export default SharePopup;
