import React from "react";
import { AnimatePresence, motion } from "framer-motion";

export default function Loading() {
  return (
    <motion.div
      className="w-[50px] h-[50px] bg-fourwall-orange relative"
      animate={{
        scale: [1, 2, 2, 1, 1],
        rotate: [0, 0, 180, 180, 0],
        borderRadius: ["20%", "20%", "50%", "50%", "20%"],
      }}
      transition={{
        duration: 2,
        ease: "easeInOut",
        times: [0, 0.2, 0.5, 0.8, 1],
        repeat: Infinity,
        repeatDelay: 1,
      }}
    >
      <motion.img
        src="/4Wall_Logo_Package/white-transparent.png"
        alt="4Wall Logo"
        className="w-full h-full object-contain absolute m-auto"
        animate={{ rotate: [0, 0, -180, -180, 0] }}
        transition={{
          duration: 2,
          ease: "easeInOut",
          times: [0, 0.2, 0.5, 0.8, 1],
          repeat: Infinity,
          repeatDelay: 1,
        }}
      />
    </motion.div>
  );
}
