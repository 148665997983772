import React, {
  useContext,
  useState,
  useRef,
  useEffect,
  CSSProperties,
} from "react";
import { debounce } from "../Misc/SearchOverlay";
import { X, EllipsisVertical, CircleHelp, Search } from "lucide-react";
import {
  Button,
  Input,
  Typography,
  Box,
  CircularProgress,
  FormControl,
  FormLabel,
  FormHelperText,
  Snackbar,
  Alert,
  List,
  ListItem,
  ListItemDecorator,
  ListItemContent,
} from "@mui/joy";
import { PlatformContext } from "../../contexts/PlatformContext";
import { Character, SelectedCharacterProps } from "../../types";
import { characterSearch } from "../../apis/request";
import useRequest from "../../apis/useRequest";
import MapCarousel from "./MapCarousel";
import AddOrEditInGameCharacter from "./AddOrEditInGameCharacter";
import { ACTIVITIES } from "../../convex/constants";
import { StyledAvatar } from "../Town/common/styledAvatar";
import { MapName } from "../../data/maps";
import { townMaps } from "./TownPage";
import { toast } from "react-toastify";
import MobileSpotStudio from "./MobileSpotStudio";

interface CreateTownDialogProps {
  dialogOpen: boolean;
  setDialogOpen: (open: boolean) => void;
  townName: string;
  setTownName: (name: string) => void;
  selectedCharacters: SelectedCharacterProps[];
  handleCharacterChange: (character: SelectedCharacterProps) => void;
  setSelectedCharacters: (characters: SelectedCharacterProps[]) => void;
  createGotoTown: (townName: string, mapName: MapName) => void;
}

const character_id_list = [
  "d303226f-6522-465e-9f5d-cdf779451b96",
  "c78a6200-450f-43e3-943b-946aa7fda660",
  "a2a58e77-41c0-4ca7-9fc6-b996d1b8466d",
  "2bf85973-ebb9-4322-b17d-387bfc5aa93e",
];

export default function CreateTownDialog({
  dialogOpen,
  setDialogOpen,
  townName,
  setTownName,
  selectedCharacters,
  handleCharacterChange,
  setSelectedCharacters,
  createGotoTown,
}: CreateTownDialogProps) {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState<Character[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [helpOpen, setHelpOpen] = useState(false);
  const context = useContext(PlatformContext);
  const [createLoading, setCreateLoading] = useState(false);
  const [addCharacterOptions, setAddCharacterOptions] = useState<{
    open: boolean;
    character: SelectedCharacterProps;
    editing: boolean;
  }>({
    open: false,
    character: {
      character_id: "",
      identity: "",
      bio: "",
      plan: "",
      image_url: "",
      name: "",
      activities: ACTIVITIES,
    },
    editing: false,
  });
  const [selectedMap, setSelectedMap] = useState<{
    name: string;
    image: string;
    mapName: MapName;
  }>(townMaps[0]);
  if (!context) {
    throw new Error(
      "useContext must be used within a PlatformContext.Provider"
    );
  }
  const { username, featuredLists, NSFW, alertInfo, closeAlert, userAssets } =
    context;
  const defaultPlan =
    "You are in 4Wall Spot, a digital world where you have the ability to walk around and interact with users and other characters.";
  const makeRequest = useRequest();
  const isMobile = window.innerWidth < 768;
  function parseSearchResults(searchResults: any) {
    let filteredHits = searchResults;
    if (!NSFW) {
      for (const hit of filteredHits) {
        if (hit.tags.includes("NSFW")) {
          filteredHits = filteredHits.filter((h: any) => h !== hit);
        }
      }
    }
    const characters = filteredHits.map((hit: any) => {
      const fields = hit;

      return {
        bio: fields.bio,
        character_id: fields.character_id,
        creator: fields.creator,
        name: fields.name,
        image_url: `${process.env.REACT_APP_ASSETS_BUCKET}${fields.character_id}/display_picture.jpg`,
        chat_background_url: `${process.env.REACT_APP_ASSETS_BUCKET}${fields.character_id}/chat_background.jpg`,
        interactions: fields.num_interactions,
        tags: fields.tags,
      };
    });

    return characters;
  }

  const fetchSearchResults = async (value: string) => {
    setIsLoading(true);
    try {
      const response = await makeRequest<any, any>(characterSearch(), {
        query: value,
        NSFW: NSFW,
      });
      const newCharacters = parseSearchResults(response.results);
      setSearchResults(newCharacters);
      setIsLoading(false);
    } catch (error) {
      console.error("Search error:", error);
      setIsLoading(false);
    }
  };

  const debouncedSearch = useRef(debounce(fetchSearchResults, 300)).current;
  const removeCharacter = (characterId: string) => {
    setSelectedCharacters(
      selectedCharacters.filter(
        (character) => character.character_id !== characterId
      )
    );
  };
  useEffect(() => {
    if (searchTerm) {
      debouncedSearch(searchTerm);
    }
  }, [searchTerm]);
  const handleCreateTown = () => {
    if (!townName.trim()) {
      toast.error("Spot name cannot be empty");
      return;
    }
    if (selectedCharacters.length < 2) {
      toast.error("You must select at least 2 characters");
      return;
    }
    // console.log("DEBUG", selectedMap.mapName);
    setCreateLoading(true);
    createGotoTown(townName, selectedMap.mapName);
  };

  const displayList = searchTerm.trim()
    ? searchResults
    : [
        // Show user's characters first
        ...userAssets.characters,
        // Then show featured characters, excluding any that are already in userAssets
        ...Array.from(
          new Set(
            [
              ...featuredLists.anime,
              ...featuredLists.isekaiRPG,
              ...featuredLists.gaming,
              ...featuredLists.moviesTV,
            ].filter(
              (character) =>
                // Only include characters that aren't in userAssets
                !userAssets.characters.some(
                  (userChar) => userChar.character_id === character.character_id
                )
            )
          )
        ),
      ];

  if (createLoading) {
    return (
      <div className="fixed inset-0 z-50 flex flex-col justify-center items-center bg-black/70">
        <img
          src="/spot_loading.gif"
          alt="Loading"
          className="w-[20rem] rounded-full"
        />
        <p className="text-xl font-main font-bold text-white mt-4">
          Loading...
        </p>
      </div>
    );
  }
  if (isMobile) {
    return (
      <MobileSpotStudio
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        townName={townName}
        setTownName={setTownName}
        selectedCharacters={selectedCharacters}
        handleCharacterChange={handleCharacterChange}
        setSelectedCharacters={setSelectedCharacters}
        createGotoTown={createGotoTown}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        searchResults={searchResults}
        isLoading={isLoading}
        addCharacterOptions={addCharacterOptions}
        setAddCharacterOptions={setAddCharacterOptions}
        selectedMap={selectedMap}
        setSelectedMap={setSelectedMap}
        handleCreateTown={handleCreateTown}
        removeCharacter={removeCharacter}
      />
    );
  }

  return (
    <>
      {dialogOpen && (
        <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-neutral-800 rounded-xl border border-neutral-700 text-white w-full h-full max-w-7xl max-h-[90vh] overflow-auto p-6">
            <div className="flex justify-between items-center mb-4">
              <Typography level="h2" className="!font-main !text-white ">
                Spot Studio
              </Typography>
              <div className="flex items-center gap-4">
                {/* <CircleHelp
                  color="white"
                  size={32}
                  onClick={() => setHelpOpen(true)}
                  style={{ cursor: "pointer" }}
                /> */}
                <X
                  size={24}
                  onClick={() => setDialogOpen(false)}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>

            <div className="flex flex-row gap-6">
              {/* Search Container */}
              {/* <div className="border-r border-white/20 pr-6"> */}
              <div className="md:w-[40%] w-full mr-4 text-fourwall-orange  border-white/20">
                <Input
                  startDecorator={<Search />}
                  id="searchCharacters"
                  placeholder="Search Characters"
                  type="search"
                  fullWidth
                  variant="outlined"
                  value={searchTerm}
                  // style={styles.searchInput}
                  color="warning"
                  className="!bg-neutral-900 !text-white !rounded-full !border-none !w-4/5 !mx-auto !my-2"
                  onChange={(e) => setSearchTerm(e.target.value)}
                  autoFocus={false}
                />
                {!searchTerm.trim() && (
                  <h3 className="text-orange-500 text-center">
                    Suggested Characters
                  </h3>
                )}
                <Box
                  sx={{
                    height: isMobile ? 200 : 520,
                    overflow: "auto",
                    "&::-webkit-scrollbar": {
                      width: "5px",
                    },
                    "&::-webkit-scrollbar-track": {
                      background: "transparent",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "var(--orange-brand-accent)",
                      borderRadius: "4px",
                    },
                  }}
                  className="!text-white !border-none !bg-neutral-900 p-2 rounded-lg"
                >
                  <List
                    sx={{
                      "--ListItemDecorator-size": "56px",

                      overflowY: "auto",
                    }}
                  >
                    {isLoading ? (
                      <CircularProgress size="md" />
                    ) : (
                      displayList.map((character) =>
                        character ? (
                          <ListItem
                            className="cursor-pointer !rounded-full m-1 "
                            key={character.character_id}
                            onClick={() => {
                              setAddCharacterOptions({
                                open: true,
                                editing: false,
                                character: {
                                  character_id: character.character_id,
                                  identity: character.description || "",
                                  bio: character.bio,
                                  plan: defaultPlan,
                                  image_url: character.image_url,
                                  name: character.name,
                                  activities: ACTIVITIES,
                                },
                              });
                            }}
                            sx={{
                              backgroundColor: selectedCharacters.some(
                                (selectedCharacter) =>
                                  selectedCharacter.character_id ===
                                  character.character_id
                              )
                                ? "rgba(255, 255, 255, 0.1)"
                                : "transparent",
                            }}
                          >
                            <ListItemDecorator>
                              <StyledAvatar
                                alt={character.name}
                                size="small"
                                src={`${process.env.REACT_APP_ASSETS_BUCKET}${character.character_id}/display_picture.jpg`}
                              />
                            </ListItemDecorator>
                            <ListItemContent>
                              <Typography className="!text-white !font-main">
                                {character.name}
                              </Typography>
                            </ListItemContent>
                          </ListItem>
                        ) : null
                      )
                    )}
                  </List>
                </Box>
              </div>
              {/* </div> */}

              {/* Right Side */}
              <div className="w-3/5 flex flex-col">
                <div className="flex flex-row w-full items-center ">
                  <FormControl className="!w-full">
                    {/* <FormLabel htmlFor="townName" className="!text-white">
                      Spot Name
                    </FormLabel> */}
                    <Input
                      autoFocus
                      id="townName"
                      placeholder="Enter your spot name"
                      type="text"
                      fullWidth
                      variant="outlined"
                      color="warning"
                      className="!bg-neutral-900 !text-white !rounded-lg !border-none !w-5/6 !my-2"
                      value={townName}
                      onChange={(e) => setTownName(e.target.value.slice(0, 50))}
                    />
                    <FormHelperText className="!text-white">
                      {`${townName.length}/50 characters`}
                    </FormHelperText>
                  </FormControl>

                  <Button
                    color="primary"
                    onClick={() => handleCreateTown()}
                    className="!bg-fourwall-orange !rounded-lg"
                  >
                    CREATE
                  </Button>
                </div>

                <Box className="flex flex-col gap-2 p-4">
                  <Typography className="!text-orange-500 !font-main text-center">
                    Your Spot Members
                  </Typography>
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3 sm:gap-4 md:gap-5">
                    {selectedCharacters.map((character) => (
                      <div
                        key={character.character_id}
                        className="bg-neutral-700 rounded-lg p-2 relative items-center"
                        style={{ width: "100%", minHeight: "50px" }}
                      >
                        <div className="flex items-center gap-2">
                          <StyledAvatar
                            alt={character.name || ""}
                            src={`${process.env.REACT_APP_ASSETS_BUCKET}${character.character_id}/display_picture.jpg`}
                            size="small"
                          />
                          <Typography className="!text-white !text-xs sm:!text-sm flex-grow truncate">
                            {character.name}
                          </Typography>
                        </div>

                        <EllipsisVertical
                          size={14}
                          onClick={() => {
                            setAddCharacterOptions({
                              ...addCharacterOptions,
                              character: character,
                              open: true,
                              editing: true,
                            });
                          }}
                          className="!absolute !bottom-1 !right-1 !mb-1 !p-0 !text-white !cursor-pointer"
                        />

                        <X
                          size={14}
                          onClick={() =>
                            removeCharacter(character.character_id)
                          }
                          className="!absolute !top-1 !right-1 !mb-1 !p-0 !text-white !cursor-pointer"
                        />
                      </div>
                    ))}
                  </div>
                </Box>
                <div className="mt-auto w-full flex flex-col gap-2">
                  <MapCarousel
                    maps={townMaps}
                    handleMapSelect={setSelectedMap}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <AddOrEditInGameCharacter
        open={addCharacterOptions.open}
        setOpen={(open) => {
          const maxCharacters = 7;
          const canAddCharacter = selectedCharacters.length < maxCharacters;

          if (open && !canAddCharacter) {
            alert(`You can only have ${maxCharacters} characters in your spot`);
          } else {
            setAddCharacterOptions({ ...addCharacterOptions, open });
          }
        }}
        character={addCharacterOptions.character}
        onAddCharacter={handleCharacterChange}
        editing={addCharacterOptions.editing}
      />

      <Snackbar
        open={alertInfo.open}
        autoHideDuration={6000}
        onClose={closeAlert}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert color={alertInfo.severity}>{alertInfo.message}</Alert>
      </Snackbar>
    </>
  );
}
