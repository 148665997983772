import React, { useState, useRef, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import Footer from "./Footer";
import Faq from "react-faq-component";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";

const styles = {
  bgColor: "transparent",
  titleTextColor: "var(--primary-text-color)",
  titleTextSize: "2rem",
  rowTitleColor: "var(--primary-text-color)",
  rowTitleTextSize: "large",
  rowContentColor: "var(--secondary-text-color)",
  rowContentTextSize: "1rem",
  rowContentPaddingTop: "10px",
  rowContentPaddingBottom: "10px",
  // rowContentPaddingRight: '150px',
  // arrowColor: "red",
  //transitionDuration: "1s",
  timingFunc: "ease",
};

const gettingStartedData = {
  title: "Getting Started",
  rows: [
    {
      title: "What is 4Wall AI?",
      content:
        "4Wall is an AI-enabled entertainment platform where you can interact with your favorite personas. As a creator-driven platform, we allow users to create and share personas with the community.",
    },
    {
      title: "What can I do on the platform?",
      content:
        "4Wall AI features include:<br/><strong>Character Creation:</strong> An easy, intuitive way to create AI personas and optionally share it with the community.<br/><strong>Botcasts:</strong> Botcasts are podcasts, but with AI personas instead of humans. Choose two characters, set the topic, sit back, and enjoy!<br/><br/>We have other cool features in the pipeline. Stay tuned!",
    },
    {
      title: "How do I create an account?",
      content:
        "Click the 'Login' button on our homepage. This opens a popup window for you to sign up.",
    },
  ],
};

const platformUsageData = {
  title: "Platform Usage",
  rows: [
    {
      title: "How do I create a character?",
      content:
        "Click the 'Character Creator' button on the top bar. Provide a name, description, and optionally a display image for your character. You can also customize chat appearance, including background images and text colors, to enhance the interactive experience.",
    },
    {
      title: "Can I create private characters?",
      content:
        "Yes. You have the option to create characters that are only visible to you through the ‘Characters’ tab on your Profile.",
    },
    {
      title: "What can I do with my created characters?",
      content:
        "Chat with your characters, use them in BotCasts, and share them with friends.",
    },
    {
      title: "Is there a limit to how many characters I can create?",
      content: "Nope.",
    },
    {
      title: "Can I edit my characters after creation?",
      content:
        "Yes. Access the 'Profile' button on the top bar. Here, you can edit or delete characters from the 'Characters' tab.",
    },
    {
      title: "Can I access/save/delete my old chats?",
      content:
        "Ensure you're logged in to save or delete chats. Your chats are automatically saved and can be managed from the 'Chats' tab in your Profile. Your past chats are also available on the landing page under ‘Continue Chatting’.",
    },
  ],
};

const technicalIssuesData = {
  title: "Technical Issues",
  rows: [
    {
      title: "What should I do if the app isn’t working properly?",
      content:
        "We’re in early stages of building out our vision for the platform, so bugs are par for the course. Contact us at admin@4wall.ai, or reach out on <a href='https://twitter.com/4WallAI' target='_blank' rel='noopener noreferrer' className='twitter-icon'>X (@4WallAI_).</a> or on  <a href='https://discord.gg/EUebsQR5KE' target='_blank' rel='noopener noreferrer'>discord</a>, and we will work on resolving your issue immediately.",
    },
    {
      title: "Which browsers/devices is the platform compatible with?",
      content:
        "Currently web-accessible on all devices. Mobile app is in the works.",
    },
  ],
};

const contentAndInteractionData = {
  title: "Content and Interaction",
  rows: [
    {
      title: "Can I customize the personality of my character?",
      content: `You can describe your character's personality, backstory, quirks, etc., in the Character Creator. Adjust traits like 'Adventurous,' 'Empathy,' 'Funny,' and 'Intelligent' through the provided sliders.<br/>
                <ul>
                    <li><strong>Adventurous:</strong> This controls how adventurous the characters are. Traditionally, higher adventurous scores would be used for fictional characters or for RPGs.</li>
                    <li><strong>Empathetic:</strong> This controls how empathetic your characters are. Higher empathy indicates a better ability to understand the user’s feelings.</li>
                    <li><strong>Funny:</strong> This controls how funny the characters are in their responses.</li>
                    <li><strong>Intelligent:</strong> This controls the intelligence of the characters.</li>
                </ul>
                Please note that these only provide our model with general guidelines and there might be cases where increasing the slider score slightly does not result in a noticeable change in the character’s personality.`,
    },
    {
      title: "What do I do if the chatbots say offensive things?",
      content: `We would like to reiterate that nothing our bots say is real. They artificially generate responses based on the data they have been trained on. So please don’t take them seriously, everything on our platform is for fun. That being said, if you find certain messages offensive, please downvote them for us to review. <br> 4Wall AI does not assume responsibility for the content or dialogues generated based on user-provided character descriptions. The users are responsible for managing the consequences of their chats.`,
    },
    {
      title: "What are the community standards?",
      content: `Characters must conform to community standards to foster a welcoming platform. We’re all here to have fun.<br/>
                
                Characters created with intent to engage in the following are liable for moderation:*</br>
                <ol>
                    <li>Underage sexual acts.</li>
                    <li>Non-consensual sexual acts and bestiality.</li>
                    <li>Incest.</li>
                    <li>Incitement to violence against or targeted harassment toward any race, religion, gender, or nationality</li>
                    <li>Characters whose image contains real people who are not celebrities or public figures are liable for moderation.</li>
                </ol>
                Graphic content used to depict character images are liable for moderation.<br/>
                *Special exceptions may be made (based on our discretion) for famous characters with storylines that include one or more of the above.
                `,
    },
    {
      title: "How do I appeal censorship?",
      content: `<ul> <li>To appeal censorship, reach out to us through email or DM us on  <a href='https://discord.gg/EUebsQR5KE' target='_blank' rel='noopener noreferrer'>discord</a> or <a href='https://twitter.com/4WallAI' target='_blank' rel='noopener noreferrer' className='twitter-icon'>X (@4WallAI_).</a> and we will conduct a review immediately.</li>
      <li>Final rights remain with the platform.</li>
      `,
    },
    {
      title: "Where can I find my private characters?",
      content: "You can find private characters in your profile.",
    },

    {
      title:
        "Will there be new features added? How often do you update the platform?",
      content:
        "We are a young platform committed to giving users the best possible experience. We update the platform regularly with new offerings, features, and bug fixes.",
    },
    {
      title: "Can I request a new feature or give feedback?",
      content:
        "We would love to hear your feedback! Please reach out to us with your suggestions at admin@4wall.ai or on  <a href='https://twitter.com/4WallAI' target='_blank' rel='noopener noreferrer' className='twitter-icon'>X (@4WallAI_).</a>.",
    },
  ],
  styles: styles,
};
const privacyAndSafetyData = {
  title: "Privacy and Safety",
  rows: [
    {
      title: "Is my data safe?",
      content:
        "Your privacy is of utmost importance to us. We use state-of-the-art security systems and secure databases to prevent data breach.",
    },
    {
      title: "How is my data used?",
      content:
        "The anonymized chat data will be used in the future to improve our AI models. The data will be decrypted and cleaned in a controlled access environment, and then be used to improve the AI model.",
    },
    {
      title: "Will my data be shared with any other individual/organization?",
      content: "Absolutely not.",
    },
  ],
};
const costAndPaymentsData = {
  title: "Cost and Payments",
  rows: [
    {
      title: "Is the platform free to use? Are there any premium features?",
      content:
        "At the moment, everything is free. However, in the future, certain features that are under development will only be available to paying users.",
    },
  ],
};

const communityAndSupportData = {
  title: "Community and Support",
  rows: [
    {
      title: "Do you have a user community or forum?",
      content:
        "Stay updated and connect with users on <a href='https://twitter.com/4WallAI' target='_blank' rel='noopener noreferrer' className='twitter-icon'>X (@4WallAI_).</a>, <a href='https://discord.gg/EUebsQR5KE' target='_blank' rel='noopener noreferrer'>discord</a>, and <a href='https://www.reddit.com/r/4WallAI/' target='_blank' rel='noopener noreferrer'>reddit.",
    },
    {
      title: "How can I contact support if I need help?",
      content:
        "Contact us at  <a href='mailto:admin@4wall.ai'>admin@4wall.ai</a> or on <a href='https://twitter.com/4WallAI' target='_blank' rel='noopener noreferrer' className='twitter-icon'>X (@4WallAI_).</a>",
    },
  ],
};

function FAQ() {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };
  return (
    <div className="background">
      <ArrowBackIosNewRoundedIcon
        onClick={handleBack}
        className="create-back-arrow"
      />
      <div className="faq-container">
        <h1>4Wall AI FAQ</h1>
        <Faq styles={styles} data={gettingStartedData} />
        <Faq styles={styles} data={platformUsageData} />
        <Faq styles={styles} data={technicalIssuesData} />
        <Faq styles={styles} data={contentAndInteractionData} />
        <Faq styles={styles} data={privacyAndSafetyData} />
        <Faq styles={styles} data={costAndPaymentsData} />
        <Faq styles={styles} data={communityAndSupportData} />
      </div>
      <Footer />
    </div>
  );
}
export default FAQ;
