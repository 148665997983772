import { useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Chip, Divider } from "@mui/joy";
// import FeaturedSwiper from "./FeaturedSwiper";
import { TypeAnimation } from "react-type-animation";
import { PlatformContext } from "../../contexts/PlatformContext";
import GenSwiper from "./GenSwiper";
import React from "react";

declare global {
  interface Window {
    rdt: any;
  }
}
const LandingCharacterCard: React.FC<{
  character_id: string;
  name: string;
  image: string;
}> = ({ character_id, name, image }) => {
  const { loginWithRedirect } = useAuth0();
  const context = useContext(PlatformContext);
  if (!context) {
    return null;
  }
  const { featuredLists } = context;
  return (
    <div
      className="md:w-[11rem] w-[9rem] !h-[12rem]   bg-cover bg-center bg-no-repeat border-2 border-orange-500 rounded-lg cursor-pointer items-center justify-center "
      key={character_id}
      onClick={() => {
        loginWithRedirect({
          appState: { returnTo: `/chat/${character_id}` },
        });
      }}
      style={{
        backgroundImage: `url(${image})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        transition: "transform 0.1s ease-in-out, filter 0.1s ease-in-out",
      }}
      onMouseEnter={(e) => {
        e.currentTarget.style.filter = "brightness(0.8)";
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.filter = "brightness(1)";
      }}
    >
      {/* <h1 className="text-lg font-main absolute bottom-0 left-0 right-0 text-fourwall-orange">
        {name}
      </h1> */}
    </div>
  );
};

function Welcome() {
  const { loginWithRedirect } = useAuth0();
  const context = useContext(PlatformContext);
  if (!context) {
    return null;
  }
  const { featuredLists } = context;
  const isMobile = window.innerWidth < 768;

  const sequence = [
    "Chat with AI characters.",
    1000,
    "Free.",
    1000,
    "Unlimited.",
    1000,

    // 1000,
  ];

  const trackRedditConversion = (event: string) => {
    const fromReddit = sessionStorage.getItem("fromReddit") === "true";
    if (fromReddit) {
      window.rdt("track", event, {
        category: "Conversion",
        action: "Click",
        label: "Sign Up",
      });
    }
  };

  const headerAreaDesktop = (
    <>
      <div className="w-full h-12 flex  flex-row justify-between items-center">
        {/* <h2 className="cursor-pointer font-extended text-white  hover:text-fourwall-orange items-center my-0 text-4xl">
          4wall AI{" "}
        </h2> */}
        <img
          src="/4Wall_Logo_Package/white-transparent.png"
          alt="logo"
          className="w-auto h-20 hover:opacity-80 transition-all duration-300 hover:cursor-pointer "
          style={{ objectFit: "contain" }}
        />
        <div className="flex  space-x-2">
          <Button
            size="lg"
            className="!bg-white !text-black !rounded-full !px-5 !py-2  !font-main hover:!bg-neutral-300 transition-all duration-200"
            onClick={() => loginWithRedirect()}
          >
            Login
          </Button>
          <Button
            size="lg"
            className="!bg-fourwall-orange text-white !rounded-full !p-2 !px-5 !font-main hover:!bg-orange-500 transition-all duration-200"
            onClick={() => {
              loginWithRedirect({
                authorizationParams: {
                  screen_hint: "signup",
                },
              });
              trackRedditConversion("SignupClick");
            }}
          >
            Sign Up
          </Button>
        </div>
      </div>
      {/* </div> */}
    </>
  );
  const headerAreaMobile = (
    <>
      <div className="w-full h-10 flex justify-between items-center">
        {/* <h2 className="cursor-pointer font-main text-white  hover:text-fourwall-orange my-auto">
            4WALL AI
          </h2> */}
        <img
          src="/4Wall_Logo_Package/fourwall-orange-transparent.png"
          alt="logo"
          className="w-auto h-16  hover:cursor-pointer"
          style={{ objectFit: "contain" }}
        />

        <div className="flex items-center space-x-2">
          <Button
            className="!bg-white !text-black !rounded-full !px-5 !py-2  !font-main"
            onClick={() => loginWithRedirect()}
          >
            Login
          </Button>
          <Button
            className="!bg-fourwall-orange text-white !rounded-full !p-2 !px-5 !font-main "
            onClick={() => {
              loginWithRedirect({
                authorizationParams: {
                  screen_hint: "signup",
                },
              });
              trackRedditConversion("SignupClick");
            }}
          >
            Sign Up
          </Button>
        </div>
      </div>
    </>
  );
  const featuredKeys = Object.keys(featuredLists);
  return (
    <div
      className={`flex flex-col text-white ${isMobile ? "max-h-screen" : ""}`}
    >
      <div className="absolute inset-0 w-full h-full image-container overflow-hidden flex flex-col">
        {isMobile ? (
          <>
            <img
              src="/cards_landing.png"
              alt="Left Background"
              className="w-full h-full object-cover rotated-image"
            />
          </>
        ) : (
          <img
            src="/cards_landing.png"
            alt="Left Background"
            className="w-full h-full object-cover rotated-image"
          />
        )}
      </div>

      {!isMobile && (
        <div className="relative z-20 justify-between p-3">
          {headerAreaDesktop}
        </div>
      )}
      {isMobile && (
        <div className="relative z-20 justify-between p-2">
          {headerAreaMobile}
        </div>
      )}
      <div className=" items-center relative z-20 flex flex-col   font-main justify-center md:items-center  p-2 md:mt-20">
        {/* <div className="flex-1 flex flex-col  h-3/4 justify-center items-center">
          <img
            src="/towns_txt.svg"
            alt="towns"
            className="w-full h-full object-cover"
          />
        </div> */}

        <div className="flex flex-col  items-center text-center font-main  rounded-3xl p-10 ">
          {/* <p className="text-2xl mb-4 w-full md:w-full font-bold  relative">
            <span className="relative z-10">
              <TypeAnimation
                sequence={sequence}
                wrapper="span"
                speed={60}
                deletionSpeed={60}
                repeat={Infinity}
                className="relative z-10 text-3xl mb-4 h-10 w-2/3 md:w-full font-bold md:text-[5rem] whitespace-normal md:whitespace-nowrap overflow-hidden "
              />

        
            </span>
          </p> */}
          <h1 className="text-3xl md:text-5xl p-0 m-0 md:mb-4 p-4">
            <span className="text-fourwall-orange">Free</span> and{" "}
            <span className="text-fourwall-orange">unlimited </span>
            chats with AI characters.
          </h1>
          <GenSwiper
            characters={[...featuredLists.anime].sort(
              () => Math.random() - 0.5
            )}
            SliderComponent={LandingCharacterCard}
          />

          <Button
            size="lg"
            className="  !bg-fourwall-orange !text-white !rounded-full !p-2 !px-6 !font-main !text-xl hover:!bg-orange-500 hover:!scale-105 transition-all duration-200 !mt-8 md:!mt-20 "
            onClick={() => {
              loginWithRedirect();
              trackRedditConversion("SignupClick");
            }}
          >
            GET STARTED
          </Button>
        </div>
      </div>
    </div>
  );
}

const styles = {
  socialIconStyle: {
    height: window.innerWidth < 768 ? 28 : 40,
    width: window.innerWidth < 768 ? 28 : 40,
    borderRadius: "50%",
    margin: "0 0.5rem",
  },
  loginButtonSection: {
    display: "flex",
    margin: "0 10rem",
  },
};

export default Welcome;
