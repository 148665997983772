import React, {
  useCallback,
  useContext,
  useState,
  useEffect,
  useRef,
} from "react";
import * as PIXI from "pixi.js";
import { PlatformContext } from "../../contexts/PlatformContext";
import { TownContext } from "../../contexts/TownContext";
import { useApp } from "@pixi/react";
import { Player, SelectElement } from "./Player";
import { PixiStaticMap } from "./PixiStaticMap";
import PixiViewport from "./PixiViewport";
import { Viewport } from "pixi-viewport";
import { Id } from "../../convex/_generated/dataModel";
import { useQuery } from "convex/react";
import { api } from "../../convex/_generated/api";
import { useSendInput } from "../../hooks/sendInput";
import { toastOnError } from "../../toasts";
import { DebugPath } from "./DebugPath";
import { PositionIndicator } from "./PositionIndicator";
import { SHOW_DEBUG_UI } from "./Game";
import { ServerGame } from "../../hooks/serverGame";
import { GameId } from "../../convex/aiTown/ids";
import * as amplitude from "@amplitude/analytics-browser";
import { movePlayer, stopPlayer } from "../../convex/aiTown/movement";
import * as Sound from "@pixi/sound";
import { Button } from "@mui/material";
import { VolumeUp, VolumeOff } from "@mui/icons-material";
import { Container, Graphics, Sprite, Text } from "@pixi/react";
import { TextStyle } from "pixi.js";

export const PixiGame = (props: {
  worldId: Id<"worlds">;
  engineId: Id<"engines">;
  game: ServerGame;
  historicalTime: number | undefined;
  width: number;
  height: number;
  setSelectedElement: SelectElement;
  worldStatus: any;
  focusedPlayerId: GameId<"players"> | undefined;
}) => {
  // PIXI setup.
  const pixiApp = useApp();
  const viewportRef = useRef<Viewport | undefined>(undefined);
  const [currentMovement, setCurrentMovement] = useState<string | null>(null);
  const humanTokenIdentifier =
    useQuery(api.world.userStatus, { worldId: props.worldId }) ?? null;
  const humanPlayerId = [...props.game.world.players.values()].find(
    (p) => p.human === humanTokenIdentifier
  )?.id;
  const humanPlayer = humanPlayerId
    ? props.game.world.players.get(humanPlayerId)
    : null;
  const moveTo = useSendInput(props.engineId, "moveTo");

  // Interaction for clicking on the world to navigate.
  const dragStart = useRef<{ screenX: number; screenY: number } | null>(null);
  const onMapPointerDown = (e: any) => {
    // https://pixijs.download/dev/docs/PIXI.FederatedPointerEvent.html
    dragStart.current = { screenX: e.screenX, screenY: e.screenY };
  };

  const [lastDestination, setLastDestination] = useState<{
    x: number;
    y: number;
    t: number;
  } | null>(null);

  const [arrowKeys, setArrowKeys] = useState({
    ArrowUp: false,
    ArrowDown: false,
    ArrowLeft: false,
    ArrowRight: false,
  });

  const prevArrowKeys = useRef(arrowKeys);

  const map = useQuery(api.world.getMap, { worldId: props.worldId });
  const mapAudioUrl = map?.mapAudioUrl
    ? `/music/${map.mapAudioUrl}`
    : undefined;

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    if (Object.keys(arrowKeys).includes(event.key)) {
      console.log(`Starting movement: ${event.key}`);
      event.preventDefault();
      if (humanPlayer && humanPlayerId) {
        const mapBoundary = props.game.worldMap;
        let destination = { ...humanPlayer.position };
        let newMovement = "";

        switch (event.key) {
          case "ArrowUp":
            destination.y = 0;
            newMovement = "up";
            break;
          case "ArrowDown":
            destination.y = mapBoundary.height;
            newMovement = "down";
            break;
          case "ArrowLeft":
            destination.x = 0;
            newMovement = "left";
            break;
          case "ArrowRight":
            destination.x = mapBoundary.width;
            newMovement = "right";
            break;
        }

        if (currentMovement !== newMovement) {
          moveTo({ playerId: humanPlayerId, destination });
          setCurrentMovement(newMovement);
        }
      }
      setArrowKeys((prev) => ({ ...prev, [event.key]: true }));
    }
  }, []);
  const historicalLocations = props.game.world.historicalLocations?.get(
    humanPlayerId ?? ("" as GameId<"players">)
  );
  const handleKeyUp = useCallback((event: KeyboardEvent) => {
    if (Object.keys(arrowKeys).includes(event.key)) {
      if (humanPlayer && humanPlayerId) {
        console.log(`Stopping movement: ${event.key}`);
        stopPlayer(humanPlayer);
        setCurrentMovement(null);
      }
      setArrowKeys((prev) => ({ ...prev, [event.key]: false }));
    }
  }, []);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [handleKeyDown, handleKeyUp]);

  // useEffect(() => {
  //   if (humanPlayer && humanPlayerId) {
  //     const mapBoundary = props.game.worldMap;
  //     let destination = { ...humanPlayer.position };

  //     if (arrowKeys.ArrowUp) destination.y = 0;
  //     if (arrowKeys.ArrowDown) destination.y = mapBoundary.height;
  //     if (arrowKeys.ArrowLeft) destination.x = 0;
  //     if (arrowKeys.ArrowRight) destination.x = mapBoundary.width;

  //     if (Object.values(arrowKeys).some((key) => key)) {
  //       if (currentMovement?.destination !== destination) {
  //         moveTo({ playerId: humanPlayerId, destination });
  //         setCurrentMovement({ destination });
  //       }
  //     } else {
  //       if (currentMovement) {
  //         stopPlayer(humanPlayer);
  //         setCurrentMovement(null);
  //       }
  //     }
  //   }
  // }, [arrowKeys, humanPlayer, humanPlayerId, props.game.worldMap]);

  const onMapPointerUp = async (e: any) => {
    if (dragStart.current) {
      const { screenX, screenY } = dragStart.current;
      dragStart.current = null;
      const [dx, dy] = [screenX - e.screenX, screenY - e.screenY];
      const dist = Math.sqrt(dx * dx + dy * dy);
      if (dist > 10) {
        // console.log(`Skipping navigation on drag event (${dist}px)`);
        return;
      }
    }
    if (!humanPlayerId) {
      return;
    }
    const viewport = viewportRef.current;

    if (!viewport) {
      return;
    }
    const gameSpacePx = viewport.toWorld(e.screenX, e.screenY);
    const tileDim = props.game.worldMap.tileDim;
    const gameSpaceTiles = {
      x: gameSpacePx.x / tileDim,
      y: gameSpacePx.y / tileDim,
    };
    setLastDestination({ t: Date.now(), ...gameSpaceTiles });
    const roundedTiles = {
      x: Math.floor(gameSpaceTiles.x),
      y: Math.floor(gameSpaceTiles.y),
    };
    console.log(`Moving to ${JSON.stringify(roundedTiles)}`);
    await toastOnError(
      moveTo({ playerId: humanPlayerId, destination: roundedTiles })
    );
  };
  const { width, height, tileDim } = props.game.worldMap;
  const players = [...props.game.world.players.values()];

  // Zoom on the user’s avatar when it is created
  useEffect(() => {
    if (!viewportRef.current || humanPlayerId === undefined) return;

    const humanPlayer = props.game.world.players.get(humanPlayerId)!;
    viewportRef.current.animate({
      position: new PIXI.Point(
        humanPlayer.position.x * tileDim,
        humanPlayer.position.y * tileDim
      ),
      scale: 1.0,
    });
  }, [humanPlayerId]);

  // Gymnastics to zoom on the focused player
  useEffect(() => {
    if (!viewportRef.current || !props.focusedPlayerId) return;

    const player = props.game.world.players.get(props.focusedPlayerId);
    if (!player) return;

    viewportRef.current.animate({
      position: new PIXI.Point(
        player.position.x * props.game.worldMap.tileDim,
        player.position.y * props.game.worldMap.tileDim
      ),
      scale: 1.5,
    });
  }, [props.focusedPlayerId]);

  // useEffect(() => {
  //   if (!viewportRef.current || !props.focusedPlayerId) return;

  //   const player = props.game.world.players.get(props.focusedPlayerId);
  //   if (!player) return;

  //   // Track the previous position to detect changes
  //   let previousPosition = { x: player.position.x, y: player.position.y };

  //   // Function to update the viewport if the position has changed
  //   const updateViewport = () => {
  //     if (
  //       viewportRef.current &&
  //       (player.position.x !== previousPosition.x || player.position.y !== previousPosition.y)
  //     ) {
  //       viewportRef.current.animate({
  //         position: new PIXI.Point(
  //           player.position.x * props.game.worldMap.tileDim,
  //           player.position.y * props.game.worldMap.tileDim
  //         ),
  //         scale: 1.5,
  //       });
  //       // Update the previous position
  //       previousPosition = { x: player.position.x, y: player.position.y };
  //     }
  //   };

  //   // Set an interval to check for position changes
  //   const intervalId = setInterval(updateViewport, 100); // Check every 100ms

  //   // Cleanup the interval on unmount or dependency change
  //   return () => clearInterval(intervalId);
  // }, [props.focusedPlayerId, props.game.worldMap.tileDim, viewportRef]);

  const handleCharacterClick = (e: any) => {
    amplitude.track("Spot Player Clicked", {
      town_id: props.worldId,
      player_id: e.id,
      origin: "pixi_game",
    });
    props.setSelectedElement(e);
  };

  // Add state for audio control
  const [isPlaying, setIsPlaying] = useState(true);

  // Toggle play/pause function
  const toggleAudio = () => {
    if (isPlaying) {
      Sound.sound.pause("background");
    } else {
      Sound.sound.resume("background");
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <>
      <PixiViewport
        app={pixiApp}
        screenWidth={props.width}
        screenHeight={props.height}
        worldWidth={width * tileDim}
        worldHeight={height * tileDim}
        viewportRef={viewportRef}
      >
        <PixiStaticMap
          map={props.game.worldMap}
          onpointerup={onMapPointerUp}
          onpointerdown={onMapPointerDown}
        />
        {players.map(
          (p) =>
            // Only show the path for the human player in non-debug mode.
            (SHOW_DEBUG_UI || p.id === humanPlayerId) && (
              <DebugPath key={`path-${p.id}`} player={p} tileDim={tileDim} />
            )
        )}
        {lastDestination && (
          <PositionIndicator destination={lastDestination} tileDim={tileDim} />
        )}
        {/* {players.map((p) => (
          <Player
            key={`player-${p.id}`}
            worldId={props.worldId}
            engineId={props.engineId}
            game={props.game}
            player={p}
            isViewer={p.id === humanPlayerId}
            onClick={handleCharacterClick}
            historicalTime={props.historicalTime}
          />
        ))} */}
        {players
          .slice() // Create a copy to avoid mutating the original array
          .sort((a, b) => a.position.y - b.position.y) // Sort by y-coordinate for layering
          .map((p) => (
            <Player
              key={`player-${p.id}`}
              worldId={props.worldId}
              engineId={props.engineId}
              game={props.game}
              player={p}
              isViewer={p.id === humanPlayerId}
              onClick={handleCharacterClick}
              historicalTime={props.historicalTime}
            />
          ))}
        {/* //////////////////// */}

        {/* Audio Control Button */}
      </PixiViewport>
    </>
  );
};
export default PixiGame;
