import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { CharacterProfile } from "../Character/CharacterProfile";
import { PlatformContext } from "../../contexts/PlatformContext";
import { CircularProgress } from "@mui/material";
import useRequest from "../../apis/useRequest";
import { getUserAssets } from "../../apis/request";
import { UserAssets } from "../../types";

export interface CreatedCharactersProps {
  character_id: string;
  name: string;
  interactions: number;
  is_public: boolean;
  num_saves: number;
  image_url: string;
}

function CreatedCharacters({ isOther, otherUsername }: any) {
  const [characters, setCharacters] = useState<CreatedCharactersProps[]>([]);
  const [pageLoading, setPageLoading] = useState<boolean>(true);
  const context = React.useContext(PlatformContext);
  if (!context) {
    throw new Error(
      "useContext must be used within a CharactersContext.Provider"
    );
  }
  const { userAssets, setUserAssets } = context;
  const { user, isAuthenticated, isLoading } = useAuth0();
  const makeRequest = useRequest();
  const fetchCharacters = async () => {
    if (isAuthenticated && user) {
      if (!isOther) {
        setCharacters(userAssets.characters);
      } else {
        (async () => {
          const userRequest = getUserAssets(
            encodeURIComponent(otherUsername),
            "true"
          );
          const response = await makeRequest<UserAssets, void>(userRequest);
          setCharacters(response.characters);
        })();
      }
    }
  };
  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      window.location.href = "/";
    } else if (!isLoading && isAuthenticated) {
      fetchCharacters();
    }
    setPageLoading(false);
  }, []);

  if (pageLoading) {
    return (
      <div className="loading-centered">
        {" "}
        <CircularProgress color="inherit" />
      </div>
    );
  }
  return (
    <div className="mt-4 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-1">
      {characters.map((char) => (
        <CharacterProfile
          key={char.character_id}
          {...char}
          isOther={isOther}
          setCharacters={setCharacters}
          setUserAssets={setUserAssets}
        />
      ))}
    </div>
  );
}

export default CreatedCharacters;
