import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { MotionProps, motion } from "framer-motion";
import { twMerge } from "tailwind-merge";

import { FiArrowRight, FiMail, FiMapPin } from "react-icons/fi";
import { SiX, SiTiktok, SiDiscord, SiReddit, SiYoutube } from "react-icons/si";
import { PlatformContext } from "../../contexts/PlatformContext";
import { AvatarGroup, Avatar } from "@mui/joy";
import { UserPlus } from "lucide-react";
import * as amplitude from "@amplitude/analytics-browser";
export const RevealBento = () => {
  return (
    <div className=" px-0 py-4 text-zinc-50">
      <motion.div
        initial="initial"
        animate="animate"
        transition={{
          staggerChildren: 0.05,
        }}
        className="mx-auto grid max-w-full grid-flow-dense grid-cols-12 gap-4"
      >
        <HeaderBlock />
        {/* <AvatarCloudBlock /> */}

        <SocialsBlock />

        <CreateBlock />
      </motion.div>
      {/* <Footer /> */}
    </div>
  );
};

type BlockProps = {
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onClick?: () => void;
  className?: string;
} & MotionProps;

const Block = ({ className, ...rest }: BlockProps) => {
  return (
    <motion.div
      variants={{
        initial: {
          scale: 0.5,
          y: 50,
          opacity: 0,
        },
        animate: {
          scale: 1,
          y: 0,
          opacity: 1,
        },
      }}
      transition={{
        type: "spring",
        mass: 3,
        stiffness: 400,
        damping: 50,
      }}
      className={twMerge(
        "col-span-4 rounded-lg border-none  bg-zinc-800 p-6",
        className
      )}
      {...rest}
    />
  );
};

const ButtonBlock = () => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Block
      className="col-span-3 h-4 flex items-center justify-center font-main text-md bg-fourwall-orange"
      onClick={() => navigate("/create")}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <motion.div
        initial={{ x: 0, opacity: 0 }}
        animate={{ x: isHovered ? 5 : 0, opacity: isHovered ? 1 : 0 }}
        transition={{ duration: 0.2 }}
      >
        <FiArrowRight className="ml-2" />
      </motion.div>
    </Block>
  );
};

const CreateBlock = () => {
  const navigate = useNavigate();
  const context = useContext(PlatformContext);
  if (!context) {
    throw new Error(
      "useContext must be used within a CharactersContext.Provider"
    );
  }
  const { featuredLists, username } = context;
  const isMobile = window.innerWidth < 768;
  return (
    <Block
      whileHover={{
        scale: 1.05,
        boxShadow: "0 0 10px rgba(255, 165, 0, 0.5)",
      }}
      className="p-2 !font-main bg-orange-500 col-span-6 md:col-span-6 flex flex-row items-center justify-center gap-2 md:text-lg text-[11px]"
      onClick={() => {
        amplitude.track("Create Character Clicked", {
          origin: "landing_bento",
          username: username,
        });
        navigate("/create");
      }}
    >
      <UserPlus className="mr-0" size={isMobile ? 24 : 32} />
      <p>Create a Character</p>
      {!isMobile && (
        <AvatarGroup>
          {featuredLists.anime
            .slice(0, isMobile ? 3 : 5)
            .map((character: any) => (
              <Avatar
                src={`${process.env.REACT_APP_ASSETS_BUCKET}${character.character_id}/display_picture.jpg`}
                alt={character.name}
              />
            ))}
          <Avatar>...</Avatar>
        </AvatarGroup>
      )}
    </Block>
  );
};

const HeaderBlock = () => {
  const [isHovered, setIsHovered] = useState(false);
  const isMobile = window.innerWidth < 768;
  const context = useContext(PlatformContext);
  if (!context) {
    throw new Error(
      "useContext must be used within a CharactersContext.Provider"
    );
  }
  const { isAlphaTester } = context;
  const navigate = useNavigate();
  return (
    <Block
      className="col-span-12 row-span-2 md:col-span-6 relative overflow-hidden h-[10rem] md:h-[15rem] border-2 "
      onMouseEnter={() => !isMobile && setIsHovered(true)}
      onMouseLeave={() => !isMobile && setIsHovered(false)}
      whileHover={{
        scale: 1.01,
      }}
      style={{
        boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.5)",
      }}
      whileTap={{
        scale: 0.95,
      }}
      onClick={() => navigate("/spot")}
    >
      <img
        src={`/spotMain.gif`}
        alt="Background"
        className="absolute inset-0 w-full h-full object-cover"
      />
      <div
        className="absolute inset-0 rounded-lg"
        style={{
          background: "linear-gradient(to top, rgba(0,0,0,0.7), transparent)",
        }}
      />
      <motion.div
        className={`relative z-20 ${isMobile ? "p-2" : "p-6"} flex flex-row  font-main justify-between items-end h-full`}
        initial={{ y: 0 }}
        animate={{ y: !isMobile && isHovered ? -60 : 0 }}
        transition={{ type: "spring", stiffness: 300, damping: 30 }}
      >
        <div className={`flex flex-col md:flex-row md:items-center`}>
          <h1
            className={`font-bold m-0 md:mb-4 font-main text-fourwall-orange ${isMobile ? "text-4xl" : "text-5xl"} relative`}
          >
            <span className="absolute -inset-0.5 text-white" aria-hidden="true">
              SPOT
            </span>
            SPOT
          </h1>
        </div>
        {isMobile && (
          <div
            className="text-fourwall-orange !py-2 !rounded-full !font-main !text-xs flex  items-center gap-1"
            onClick={() => navigate("/spot")}
          >
            {isAlphaTester ? "GET STARTED" : "JOIN WAITLIST"}
            <FiArrowRight />
          </div>
        )}
      </motion.div>
      {!isMobile && (
        <motion.div
          initial={{
            opacity: 0,
            y: 20,
            boxShadow: "0 0 0 rgba(255, 165, 0, 0)",
          }}
          animate={{
            opacity: isHovered ? 1 : 0,
            y: isHovered ? 0 : 20,
          }}
          transition={{ delay: 0.1 }}
          className=" absolute bottom-6 left-10 px-4 py-2  text-orange-500 font-bold rounded-full font-main text-lg"
          onClick={() => navigate("/spot")}
          style={{
            animation: isHovered ? "pulse 1.5s infinite" : "none",
          }}
        >
          {isAlphaTester ? "Get Started" : "Join Waitlist"} <FiArrowRight />
        </motion.div>
      )}
    </Block>
  );
};

const SocialsBlock = () => {
  const context = useContext(PlatformContext);
  if (!context) {
    throw new Error(
      "useContext must be used within a CharactersContext.Provider"
    );
  }
  const { socialUrls } = context;
  const isMobile = window.innerWidth < 768;
  return (
    <>
      <Block
        whileHover={{
          rotate: "2.5deg",
          scale: 1.1,
        }}
        onClick={() => window.open(socialUrls.Discord)}
        className="p-3 col-span-6 bg-[#5865F2]/90 md:col-span-6 cursor-pointer  flex flex-row items-center justify-center gap-2 !font-main md:text-xl text-md"
      >
        <a
          href={socialUrls.discord}
          target="_blank"
          rel="noopener noreferrer"
          className="grid h-1/2 place-content-center md:text-5xl text-3xl text-white "
        >
          <SiDiscord />
        </a>
        {!isMobile && "Join our Discord Community!"}
      </Block>
      {/* <Block
        whileHover={{
          rotate: "-2.5deg",
          scale: 1.1,
        }}
        className="col-span-6 bg-fourwall-orange md:col-span-3"
      >
        <a
          href="https://www.reddit.com/r/4WallAI/"
          target="_blank"
          rel="noopener noreferrer"
          className="grid h-full place-content-center text-3xl text-white"
        >
          <SiReddit />
        </a>
      </Block> */}
      {/* <Block
        whileHover={{
          rotate: "-2.5deg",
          scale: 1.1,
        }}
        className="col-span-6 bg-zinc-50 md:col-span-3"
      >
        <a
          href="https://www.tiktok.com/@4wallai"
          target="_blank"
          rel="noopener noreferrer"
          className="grid h-full place-content-center text-3xl text-black"
        >
          <SiTiktok />
        </a>
      </Block>
      <Block
        whileHover={{
          rotate: "2.5deg",
          scale: 1.1,
        }}
        className="col-span-6 bg-blue-500 md:col-span-3"
      >
        <a
          href="https://x.com/4WallAI_"
          target="_blank"
          rel="noopener noreferrer"
          className="grid h-full place-content-center text-3xl text-white"
        >
          <SiX />
        </a>
      </Block> */}
    </>
  );
};

// const AboutBlock = () => (
//   <Block className="col-span-12 text-3xl leading-snug">
//     <p>
//       My passion is building cool stuff.{" "}
//       <span className="text-zinc-400">
//         I build primarily with React, Tailwind CSS, and Framer Motion. I love
//         this stack so much that I even built a website about it. I've made over
//         a hundred videos on the subject across YouTube and TikTok.
//       </span>
//     </p>
//   </Block>
// );

// const LocationBlock = () => (
//   <Block className="col-span-12 flex flex-col items-center gap-4 md:col-span-3">
//     <FiMapPin className="text-3xl" />
//     <p className="text-center text-lg text-zinc-400">Cyberspace</p>
//   </Block>
// );

// const EmailListBlock = () => (
//   <Block className="col-span-12 md:col-span-9">
//     <p className="mb-3 text-lg">Join my mailing list</p>
//     <form
//       onSubmit={(e) => e.preventDefault()}
//       className="flex items-center gap-2"
//     >
//       <input
//         type="email"
//         placeholder="Enter your email"
//         className="w-full rounded border border-zinc-700 bg-zinc-800 px-3 py-1.5 transition-colors focus:border-red-300 focus:outline-0"
//       />
//       <button
//         type="submit"
//         className="flex items-center gap-2 whitespace-nowrap rounded bg-zinc-50 px-3 py-2 text-sm font-medium text-zinc-900 transition-colors hover:bg-zinc-300"
//       >
//         <FiMail /> Join the list
//       </button>
//     </form>
//   </Block>
// );

const Logo = () => {
  // Temp logo from https://logoipsum.com/
  return (
    <svg
      width="40"
      height="auto"
      viewBox="0 0 50 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="mx-auto mb-12 fill-zinc-50"
    >
      <path
        d="M16.4992 2H37.5808L22.0816 24.9729H1L16.4992 2Z"
        stopColor="#000000"
      ></path>
      <path
        d="M17.4224 27.102L11.4192 36H33.5008L49 13.0271H32.7024L23.2064 27.102H17.4224Z"
        stopColor="#000000"
      ></path>
    </svg>
  );
};

// const Footer = () => {
//   return (
//     <footer className="mt-12">
//       <p className="text-center text-zinc-400">
//         Made with ❤️ by{" "}
//         <a href="#" className="text-red-300 hover:underline">
//           @tomisloading
//         </a>
//       </p>
//     </footer>
//   );
// };
