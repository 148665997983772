import React, { useState, useContext } from "react";
import {
  Modal,
  ModalDialog,
  DialogContent,
  DialogTitle,
  Button,
  ModalProps,
} from "@mui/joy";
import { PlatformContext } from "../../contexts/PlatformContext";
import { SocialIcon } from "react-social-icons";
import { CircleChevronRight, CircleChevronLeft } from "lucide-react";
import { motion } from "framer-motion";
import { useMediaQuery } from "@mui/material";
import Videobox from "../common/Videobox";

const Welcome = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const context = useContext(PlatformContext);
  const isMobile = useMediaQuery("(max-width:640px)");

  if (!context) {
    throw new Error(
      "useContext must be used within a PlatformContext.Provider"
    );
  }
  const { socialUrls } = context;
  const slides = [
    {
      title: "Create and explore virtual worlds with AI characters",
      image: "/spot_welcome-min.png",
    },
    {
      title: "1. Add your favorite characters.",
      image: "/add_char.gif",
    },
    {
      title: "2. Choose a map.",
      image: "/maps.png",
    },
    {
      title: "3. Explore and interact",
      image: "/walk.gif",
    },
    {
      title: "Take a look at what's to come!",
      image: "/discord_logo.png",
    },
  ];

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
  };

  const handleClose: ModalProps["onClose"] = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <ModalDialog
        className={`!bg-neutral-900 !border-neutral-800 ${isMobile ? "w-full h-[80%]" : "w-[954px] h-[600px]"} relative items-center`}
      >
        <DialogTitle className="text-center justify-center ">
          <h1
            className={`font-bold font-main text-fourwall-orange ${isMobile ? "text-2xl" : "text-5xl"} relative`}
          >
            <span className="absolute -inset-0.5 text-white" aria-hidden="true">
              Welcome to Spot
            </span>
            Welcome to Spot
          </h1>
          <span className="text-sm text-orange-500 font-main">v1.0</span>
        </DialogTitle>
        <DialogContent className="flex flex-col items-center justify-center">
          <div
            className={`w-full ${isMobile ? "h-60" : "h-80"} flex items-center justify-center relative`}
          >
            {currentSlide < slides.length - 1 ? (
              <img
                src={`/spot_welcome_gifs/${slides[currentSlide].image}`}
                alt={slides[currentSlide].title}
                className={`${isMobile ? "max-w-full" : "max-w-[40rem]"} h-full object-cover mb-4 rounded-xl`}
              />
            ) : (
              <div className="flex flex-wrap justify-center">
                {/* <SocialIcon
                  url={socialUrls.Reddit}
                  className={`!${isMobile ? "w-20 h-20" : "w-40 h-40"} m-2`}
                  target="_blank"
                  fgColor="var(--orange-brand-accent)"
                  bgColor="transparent"
                />
                <SocialIcon
                  url={socialUrls.Discord}
                  target="_blank"
                  className={`!${isMobile ? "w-20 h-20" : "w-40 h-40"} m-2`}
                  network="discord"
                  fgColor="var(--orange-brand-accent)"
                  bgColor="transparent"
                />
                <SocialIcon
                  url={socialUrls.X}
                  className={`!${isMobile ? "w-20 h-20" : "w-40 h-40"} m-2`}
                  target="_blank"
                  fgColor="var(--orange-brand-accent)"
                  bgColor="transparent"
                /> */}
                <Videobox />
              </div>
            )}
          </div>

          <h2
            className={`text-white ${isMobile ? "text-xl" : "text-2xl"} mb-2 font-main text-center`}
          >
            {slides[currentSlide].title}
          </h2>

          {currentSlide === slides.length - 1 && (
            <motion.div
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="flex flex-col items-center"
            >
              <Button
                onClick={onClose}
                size={isMobile ? "md" : "lg"}
                variant="solid"
                color="warning"
                className="mt-4 !font-main !bg-fourwall-orange"
              >
                Begin
              </Button>
              <p className="text-sm text-orange-500">
                Let's make a sample spot for you to explore !
              </p>
            </motion.div>
          )}
          <div className="flex justify-center mt-4 space-x-2">
            {slides.map((_, index) => (
              <div
                key={index}
                className={`w-2 h-2 rounded-full ${
                  index === currentSlide ? "bg-white" : "bg-gray-600"
                }`}
              />
            ))}
          </div>
        </DialogContent>
        {currentSlide > 0 && (
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="cursor-pointer absolute left-4 top-1/2 -translate-y-1/2 text-orange-500"
          >
            <CircleChevronLeft size={isMobile ? 30 : 40} onClick={prevSlide} />
          </motion.div>
        )}
        {currentSlide < slides.length - 1 && (
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="cursor-pointer absolute right-4 top-1/2 -translate-y-1/2 text-orange-500"
          >
            <CircleChevronRight size={isMobile ? 30 : 40} onClick={nextSlide} />
          </motion.div>
        )}
      </ModalDialog>
    </Modal>
  );
};

export default Welcome;
