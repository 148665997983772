import React from "react";
import { SelectorProps } from "./CreateBotcast";
import "./Botcasts.css";
import CharacterImage from "../Misc/CharacterImage";
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";
import { CharacterSubProps } from "./CreateBotcast";
interface SelectorCharacterCardProps {
  data: SelectorProps;
  handleCharacterDeselect: (character: CharacterSubProps) => void;
}

function SelectorCharacterCard({
  data,
  handleCharacterDeselect,
}: SelectorCharacterCardProps) {
  const isPlaceholder =
    data.image_url ===
    "https://4thwall-assets.s3.amazonaws.com/placeholder_botcast.png";

  return (
    <div className="selected-character-card">
      {!isPlaceholder && (
        <CancelTwoToneIcon
          onClick={() =>
            handleCharacterDeselect({
              character_id: data.character_id,
              name: data.text,
              image_url: data.image_url,
            })
          }
          className="cancel-icon"
        />
      )}
      <img src={data.image_url} alt={data.text} />
      {/* <CharacterImage image_url={data.image_url} name={data.text} /> */}
      <h3>{data.text}</h3>
    </div>
  );
}
export default SelectorCharacterCard;
