import React from "react";
import { Avatar, Box } from "@mui/joy";

type AvatarSize = "small" | "medium" | "large";

const sizeMap = {
  small: { outer: 48, inner: 44, emoji: 16 },
  medium: { outer: 68, inner: 62, emoji: 22 },
  large: { outer: 88, inner: 80, emoji: 28 },
};

export const StyledAvatar = ({
  src,
  alt,
  emoji,
  size = "medium",
}: {
  src: string;
  alt: string;
  emoji?: string;
  size?: AvatarSize;
}) => {
  const { outer, inner, emoji: emojiSize } = sizeMap[size];

  return (
    <Box
      sx={{
        position: "relative",
        width: outer,
        height: outer,
        borderRadius: "50%",
        background: "linear-gradient(45deg, #FF4500, #FF4500, #F4AA20)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        isolation: "isolate",
        zIndex: 0,
      }}
    >
      <Avatar
        src={src}
        alt={alt}
        sx={{
          width: inner,
          height: inner,
          zIndex: 1,
          minWidth: inner,
          minHeight: inner,
          maxWidth: inner,
          maxHeight: inner,
        }}
      />
      {emoji && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            borderRadius: "50%",
            width: emojiSize,
            height: emojiSize,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: `${emojiSize * 1.2}px`,
            zIndex: 2,
            backgroundColor: "transparent",
          }}
        >
          <span>{emoji}</span>
        </Box>
      )}
    </Box>
  );
};
