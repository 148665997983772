import React from "react";
import {
  Modal,
  ModalDialog,
  Typography,
  DialogTitle,
  DialogContent,
  ModalClose,
  CircularProgress,
  Tooltip,
} from "@mui/joy";
import { ChevronDown, Play, Pause, ScanEye } from "lucide-react";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import { useContext } from "react";
import { TownContext } from "../../contexts/TownContext";
import { api } from "../../convex/_generated/api";
import { useQuery } from "convex/react";
import { Id } from "../../convex/_generated/dataModel";
import { useMediaQuery } from "usehooks-ts";

interface SpotlightProps {
  worldId: Id<"worlds">;
  open: boolean;
  setOpen: (open: boolean) => void;
}

// Add this helper function near your other utility functions
const formatTime = (timestamp: number) => {
  return new Date(timestamp).toLocaleString("en-US", {
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });
};

// Add this test function near the top of the file
const createTestSpotlight = () => ({
  _id: `test-${Date.now()}`,
  _creationTime: Date.now(),
  imageUrl: "https://picsum.photos/400/400", // Random test image
  uiText: {
    title: `Test Spotlight ${new Date().toLocaleTimeString()}`,
    article:
      "This is a test spotlight generated for development purposes. It includes some sample text to demonstrate the auto-play functionality.",
  },
});

// Add this helper function to truncate text
const truncateText = (text: string, limit: number) => {
  if (text.length <= limit) return text;
  return text.slice(0, limit) + "...";
};

// Move these to be standalone components outside the main Spotlight component
const SpotlightHeader = () => {
  return (
    <>
      <Typography className="!text-fourwall-orange !font-bold !text-5xl !text-center !py-4 !font-main">
        SpotLight
      </Typography>
    </>
  );
};

// Update SpotlightContent props interface
interface SpotlightContentProps {
  sortedSpotlight: any[];
  expandedSpotlights: Set<string>;
  toggleExpanded: (spotlightId: string, e: React.MouseEvent) => void;
  handlePlayAudio: (spotlightId: string) => Promise<void>;
  currentlyPlaying: string | null;
  isPaused: boolean;
  worldId: Id<"worlds">;
}

const SpotlightContent = React.memo(
  ({
    sortedSpotlight,
    expandedSpotlights,
    toggleExpanded,
    handlePlayAudio,
    currentlyPlaying,
    isPaused,
    worldId,
  }: SpotlightContentProps) => {
    return (
      <div className="space-y-4 pb-4">
        {sortedSpotlight.length === 0 ? (
          <div className="flex justify-center items-center my-40 py-4">
            <Typography className="!text-gray-400 !text-xl">
              If something of importance happens, you'll see it here.
            </Typography>
          </div>
        ) : (
          sortedSpotlight.map((spotlight: any, index: number) => (
            <React.Fragment key={spotlight._id}>
              {/* Mobile Layout */}
              <div className="md:hidden bg-neutral-900 items-center overflow-hidden flex flex-col border border-neutral-800 rounded-lg p-2">
                <img
                  loading="lazy"
                  src={spotlight.imageUrl}
                  alt="Event"
                  className="w-96 h-auto object-cover rounded-lg shadow-lg shadow-white/10"
                />
                <div className="flex flex-col space-y-2 mt-2">
                  <div className="flex items-center justify-between w-full">
                    <Typography
                      className="!text-gray-100 !font-main !text-sm font-bold break-words"
                      sx={{ wordBreak: "break-word", flex: 1 }}
                    >
                      {spotlight.uiText.title}
                    </Typography>
                    <Typography className="!text-gray-400 !text-sm ml-2">
                      {formatTime(spotlight._creationTime)}
                    </Typography>
                  </div>
                  <div className="flex items-center gap-2">
                    <Typography
                      className="!text-gray-300 !font-chat !text-xs break-words"
                      sx={{ wordBreak: "break-word" }}
                    >
                      {expandedSpotlights.has(spotlight._id)
                        ? spotlight.uiText.article
                        : truncateText(spotlight.uiText.article, 250)}
                    </Typography>
                    {spotlight.uiText.article.length > 250 && (
                      <Typography
                        onClick={(e) => toggleExpanded(spotlight._id, e)}
                        className="!text-fourwall-orange hover:!text-fourwall-orange/80 !text-xs !cursor-pointer"
                      >
                        {expandedSpotlights.has(spotlight._id)
                          ? "See less"
                          : "See more"}
                      </Typography>
                    )}
                  </div>
                  <div className="flex justify-end">
                    <AudioToolbar
                      worldId={worldId}
                      spotlight={spotlight}
                      currentlyPlaying={currentlyPlaying}
                      handlePlayAudio={handlePlayAudio}
                      isPaused={isPaused}
                    />
                  </div>
                </div>
              </div>

              {/* Desktop Layout */}
              <div
                className={`hidden md:flex bg-neutral-900 overflow-hidden gap-4 border border-neutral-800 rounded-lg p-2 ${index % 2 === 0 ? "flex-row" : "flex-row-reverse"}`}
              >
                <img
                  loading="lazy"
                  src={spotlight.imageUrl}
                  alt="Event"
                  className="w-24 h-24 md:w-72 md:h-72 object-cover flex-shrink-0 rounded-lg shadow-lg shadow-white/10"
                />
                <div className="flex flex-col justify-between space-y-2">
                  <div className="flex items-center justify-between w-full mb-2">
                    <Typography
                      className="!text-gray-100 !font-main !text-lg font-bold break-words"
                      sx={{ wordBreak: "break-word", flex: 1 }}
                    >
                      {spotlight.uiText.title}
                    </Typography>
                    <Typography className="!text-gray-400 !text-sm ml-2">
                      {formatTime(spotlight._creationTime)}
                    </Typography>
                  </div>
                  <div className="flex flex-col gap-1">
                    <Typography
                      className="!text-gray-300 !font-chat !text-md break-words"
                      sx={{ wordBreak: "break-word" }}
                    >
                      {spotlight.uiText.article}
                    </Typography>
                  </div>
                  <div className="flex justify-end mt-auto">
                    <AudioToolbar
                      worldId={worldId}
                      spotlight={spotlight}
                      currentlyPlaying={currentlyPlaying}
                      handlePlayAudio={handlePlayAudio}
                      isPaused={isPaused}
                    />
                  </div>
                </div>
              </div>
            </React.Fragment>
          ))
        )}
      </div>
    );
  }
);

export default function Spotlight({ worldId, open, setOpen }: SpotlightProps) {
  const isMobile = useMediaQuery("(max-width: 768px)");

  const spotLight = useQuery(api.world.getSpotLight, {
    worldId: worldId,
  });

  const audioRef = React.useRef<HTMLAudioElement | null>(null);
  const [currentlyPlaying, setCurrentlyPlaying] = React.useState<string | null>(
    null
  );
  const [isPaused, setIsPaused] = React.useState<boolean>(false);

  const townContext = useContext(TownContext);
  if (!townContext) {
    throw new Error("TownContext not found");
  }
  const { cryptoSpot } = townContext;

  // Add this state for managing expanded text
  const [expandedSpotlights, setExpandedSpotlights] = React.useState<
    Set<string>
  >(new Set());

  const lastPlayedSpotlightRef = React.useRef<string | null>(null);
  const [testSpotlights, setTestSpotlights] = React.useState<any[]>([]);

  React.useEffect(() => {
    console.log("Spotlight", spotLight);
    if (!spotLight?.length) return;

    const latestSpotlight = sortedSpotlight[0];
    if (
      latestSpotlight &&
      latestSpotlight._id !== lastPlayedSpotlightRef.current
    ) {
      lastPlayedSpotlightRef.current = latestSpotlight._id;

      // Show new spotlight toast
      toast.info(
        <div className="flex flex-col">
          <span className="font-bold">New Spotlight!</span>
          <span className="text-sm">{latestSpotlight.uiText.title}</span>
        </div>,
        {
          position: "bottom-right",
          autoClose: 5000,
        }
      );
    }
  }, [spotLight]);

  const toggleExpanded = (spotlightId: string) => {
    setExpandedSpotlights((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(spotlightId)) {
        newSet.delete(spotlightId);
      } else {
        newSet.add(spotlightId);
      }
      return newSet;
    });
  };

  const sortedSpotlight = React.useMemo(() => {
    const allSpotlights = [...(spotLight || []), ...testSpotlights];
    return allSpotlights.sort((a, b) => b._creationTime - a._creationTime);
  }, [spotLight, testSpotlights]);

  const handlePlayAudio = async (spotlightId: string) => {
    try {
      // If already playing this spotlight, stop it completely
      if (currentlyPlaying === spotlightId) {
        if (audioRef.current) {
          audioRef.current.pause();
          audioRef.current = null;
          setCurrentlyPlaying(null);
          setIsPaused(false);
        }
        return;
      }

      // Start fresh with new audio
      const audioUrl = `${process.env.REACT_APP_ASSETS_BUCKET}spots/${worldId}/${spotlightId}/audio.mp3`;
      console.log("AUDIO URL: " + audioUrl);
      const audio = new Audio(audioUrl);
      audioRef.current = audio;

      // Add ended event listener to reset states when audio finishes
      audio.addEventListener("ended", () => {
        setCurrentlyPlaying(null);
        setIsPaused(false);
        audioRef.current = null;
      });

      await audio.play();
      setCurrentlyPlaying(spotlightId);
      setIsPaused(false);
    } catch (error) {
      console.error("Audio playback error:", error);
      toast.error("Unable to play audio. Please try again later.");
      setCurrentlyPlaying(null);
      setIsPaused(false);
      audioRef.current = null;
    }
  };

  // Cleanup on unmount
  React.useEffect(() => {
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
      }
    };
  }, []);

  // Add helper function to stop current audio
  // const stopCurrentAudio = React.useCallback(() => {
  //   if (audioRef.current) {
  //     audioRef.current.pause();
  //     audioRef.current.currentTime = 0;
  //     audioRef.current = null;
  //     setCurrentlyPlaying(null);
  //     setProgress(0);
  //     setIsPaused(false);
  //   }
  // }, []);

  // Defer modal opening
  const handleOpen = () => {
    requestAnimationFrame(() => {
      setOpen(true);
    });
  };

  return (
    <>
      <div
        className={`absolute  border-2 border-solid border-black bg-fourwall-orange p-1 !rounded-full  flex gap-4 mx-2 px-4 ${cryptoSpot && !isMobile ? "top-3 left-3" : "left-1/2 -translate-x-1/2"}`}
      >
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="!text-white !cursor-pointer"
          onClick={handleOpen}
        >
          <ScanEye size={24} />
        </motion.button>

        <Tooltip
          title={
            currentlyPlaying
              ? isPaused
                ? "Play"
                : "Stop playing"
              : "Play latest Spotlight"
          }
        >
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="!text-white !cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              if (sortedSpotlight?.length) {
                const latestSpotlight = sortedSpotlight[0];
                handlePlayAudio(latestSpotlight.spotlightId);

                toast.info(
                  <div className="flex flex-col">
                    <span className="font-bold">Playing Latest Spotlight</span>
                    <span className="text-sm">
                      {latestSpotlight.uiText.title}
                    </span>
                  </div>,
                  {
                    position: "bottom-right",
                    autoClose: 3000,
                  }
                );
              }
            }}
          >
            {currentlyPlaying ? (
              isPaused ? (
                <Play size={24} />
              ) : (
                <Pause size={24} />
              )
            ) : (
              <Play size={24} />
            )}
          </motion.button>
        </Tooltip>
      </div>

      {isMobile ? (
        open && (
          <div className="fixed inset-0 bottom-[64px] bg-neutral-900 z-50 flex flex-col">
            <button
              onClick={() => setOpen(false)}
              className="absolute top-4 right-4 text-white"
            >
              <ChevronDown size={24} />
            </button>
            <div className="flex flex-col h-full overflow-hidden">
              <SpotlightHeader />

              <div className="flex-1 overflow-y-auto  [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-track]:transparent [&::-webkit-scrollbar-thumb]:bg-fourwall-orange [&::-webkit-scrollbar-thumb]:rounded-full">
                <SpotlightContent
                  sortedSpotlight={sortedSpotlight}
                  expandedSpotlights={expandedSpotlights}
                  toggleExpanded={toggleExpanded}
                  handlePlayAudio={handlePlayAudio}
                  currentlyPlaying={currentlyPlaying}
                  isPaused={isPaused}
                  worldId={worldId}
                />
              </div>
            </div>
          </div>
        )
      ) : (
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          className={`!mx-auto ${!cryptoSpot ? "sm:!ml-4 md:!ml-8 lg:!ml-16 xl:!ml-40" : ""}`}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            // backdropFilter: "blur(0px)",
          }}
          slotProps={{
            backdrop: {
              sx: {
                backgroundColor: "transparent",
              },
            },
          }}
        >
          <ModalDialog className="max-w-[600px] w-[60%] !h-[600px] !bg-neutral-900 !border-neutral-800 !rounded-3xl mt-8 p-0 overflow-hidden relative">
            <ModalClose onClick={() => setOpen(false)} />
            <DialogTitle className="!rounded-t-3xl !flex flex-col items-center !min-h-fit flex-shrink-0">
              <SpotlightHeader />
            </DialogTitle>

            <DialogContent className="!bg-neutral-900 p-4 overflow-y-auto overscroll-contain flex-grow [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-track]:transparent [&::-webkit-scrollbar-thumb]:bg-fourwall-orange [&::-webkit-scrollbar-thumb]:rounded-full">
              <SpotlightContent
                sortedSpotlight={sortedSpotlight}
                expandedSpotlights={expandedSpotlights}
                toggleExpanded={toggleExpanded}
                handlePlayAudio={handlePlayAudio}
                currentlyPlaying={currentlyPlaying}
                isPaused={isPaused}
                worldId={worldId}
              />
            </DialogContent>
          </ModalDialog>
        </Modal>
      )}
    </>
  );
}

interface AudioToolbarProps {
  worldId: Id<"worlds">;
  spotlight: {
    _id: string;
    _creationTime: number;
    imageUrl: string;
    uiText: {
      title: string;
      article: string;
    };
    spotlightId: string;
  };
  currentlyPlaying: string | null;
  handlePlayAudio: (spotlightId: string) => Promise<void>;
  isPaused: boolean;
}

const AudioToolbar = React.memo(
  ({
    worldId,
    spotlight,
    currentlyPlaying,
    handlePlayAudio,
    isPaused,
  }: AudioToolbarProps) => {
    return (
      <div className="flex items-center gap-2">
        <button
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            handlePlayAudio(spotlight.spotlightId);
          }}
          className="text-white hover:text-fourwall-orange p-2 cursor-pointer"
        >
          {currentlyPlaying === spotlight.spotlightId ? (
            isPaused ? (
              <Play size={16} />
            ) : (
              <Pause size={16} />
            )
          ) : (
            <Play size={16} />
          )}
        </button>
      </div>
    );
  }
);
