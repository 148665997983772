import React, { useContext } from "react";
import "./styles.css";
import { RiTwitterXFill } from "react-icons/ri";
import EmailIcon from "@mui/icons-material/Email";
import { SocialIcon } from "react-social-icons";
import { useNavigate } from "react-router-dom";
import { PlatformContext } from "../contexts/PlatformContext";
// diff for git sake
const Footer: React.FC = () => {
  const context = useContext(PlatformContext);
  if (!context) {
    throw new Error("PlatformContext not found");
  }
  const { socialUrls } = context;
  const navigate = useNavigate();
  return (
    <div className="footer">
      <div className="footer-content">
        {/* <a href="/faq" style={{ margin: "0.4rem" }}> */}
        <p onClick={() => navigate("/faq")}>FAQ</p>
        <p onClick={() => navigate("/privacypolicy")}>Privacy Policy</p>
        <p onClick={() => navigate("/communitystandards")}>
          Community Standards
        </p>
        <SocialIcon
          url={socialUrls.Discord}
          target="_blank"
          style={styles.socialIconStyle}
          fgColor="var(--orange-brand-accent)"
          bgColor="transparent"
        />
        <SocialIcon
          url={socialUrls.Reddit}
          target="_blank"
          style={styles.socialIconStyle}
          fgColor="var(--orange-brand-accent)"
          bgColor="transparent"
        />
        <SocialIcon
          url={socialUrls.X}
          target="_blank"
          style={styles.socialIconStyle}
          fgColor="var(--orange-brand-accent)"
          bgColor="transparent"
        />
      </div>
    </div>
  );
};
const styles = {
  socialIconStyle: {
    height: 40,
    width: 40,
    margin: "0 0.5rem",
    bgColor: "green",
    fgColor: "blue",
  },
  otherPageStyles: {
    color: "var(--primary-text-color)",
    cursor: "pointer",
    fontFamily: "var(--font_b)",
    fontSize: window.innerWidth < 768 ? "0.6rem" : "0.9rem",
    margin: "10px",
    transition: "color 0.3s ease",
  },
};

export default Footer;
