import axios, { AxiosError } from "axios";
import { useRequestContext } from "../contexts/RequestContext";
import { RequestDefinition } from "./types";
import { useCallback, useContext } from "react";

function useRequest() {
  const { addRequest } = useRequestContext();
  const api_url = process.env.REACT_APP_4WALL_REST_API;
  const makeRequest = useCallback(
    async <T, A>(request: RequestDefinition, requestBody?: A): Promise<T> => {
      const promise = axios<T>({
        baseURL: api_url,
        method: request.method,
        url: request.path,
        params: request.queryParams,
        data: requestBody,
      }); //redeploy

      // console.log(
      //   "backendRequest",
      //   request.name,
      //   api_url,
      //   request.path,
      //   request.queryParams,
      //   requestBody
      // ); //push comments

      // Return the promise for the response data
      try {
        const response = await promise;
        return response.data as T;
      } catch (error) {
        console.error("useRequest::error::", error);
        if (error instanceof AxiosError) {
          if (error.response?.status === 403) {
            try {
              console.error("logging out due to 403 error");
              //   await clearSession();
            } catch (e) {
              console.log("error in logging out after 403 error", e);
            }
          }
        }
        throw error;
      }
    },
    [addRequest]
  );

  return makeRequest;
}

export default useRequest;
