import React from "react";
import ReactPlayer from "react-player";

function Videobox() {
  return (
    <div className="w-[40rem] h-[16rem]">
      <ReactPlayer
        controls={true}
        className="react-player"
        url="https://www.youtube.com/watch?v=qyRTmTPbGkE"
        width="100%"
        height="100%"
        config={{
          youtube: {
            playerVars: { modestbranding: 1 },
          },
        }}
        playbackRate={1}
        muted={true}
      />
    </div>
  );
}

export default Videobox;
