import { GameId } from "../convex/aiTown/ids";
import { AgentDescription } from "../convex/aiTown/agentDescription";
import { PlayerDescription } from "../convex/aiTown/playerDescription";
import { World } from "../convex/aiTown/world";
import { WorldMap } from "../convex/aiTown/worldMap";
import { Id } from "../convex/_generated/dataModel";
import { useMemo } from "react";
import { useQuery } from "convex/react";
import { api } from "../convex/_generated/api";
import { parseMap } from "../convex/util/object";
// import { parseMap } from "../../convex/util/object";

export type ServerGame = {
  world: World;
  playerDescriptions: Map<GameId<"players">, PlayerDescription>;
  agentDescriptions: Map<GameId<"agents">, AgentDescription>;
  worldMap: WorldMap;
};

// TODO: This hook reparses the game state (even if we're not rerunning the query)
// when used in multiple components. Move this to a context to only parse it once.
export function useServerGame(
  worldId: Id<"worlds"> | undefined
): ServerGame | undefined {
  const worldState = useQuery(
    api.world.worldState,
    worldId ? { worldId } : "skip"
  );
  const descriptions = useQuery(
    api.world.gameDescriptions,
    worldId ? { worldId } : "skip"
  );
  // console.log("DEBUG", worldState, descriptions);
  const game = useMemo(() => {
    if (!worldState || !descriptions) {
      return undefined;
    }
    return {
      world: new World(worldState.world),
      agentDescriptions: parseMap(
        descriptions.agentDescriptions,
        AgentDescription,
        (p: any) => p.agentId
      ),
      playerDescriptions: parseMap(
        descriptions.playerDescriptions,
        PlayerDescription,
        (p: any) => p.playerId
      ),
      worldMap: new WorldMap(descriptions.worldMap),
    };
  }, [worldState, descriptions]);
  return game;
}
