import { useState, useContext, useEffect } from "react";
import { Character } from "../../types";
import "./Character.css";
import { PlatformContext } from "../../contexts/PlatformContext";
import { Skeleton } from "@mui/joy";
import { useNavigate } from "react-router-dom";
import { MessageSquareText } from "lucide-react";
import { useAuth0 } from "@auth0/auth0-react";
import { motion, AnimatePresence } from "framer-motion";
import { Avatar } from "@mui/joy";
export interface CharacterProps {
  character: Character;
  onClick?: () => void;
  theme?: boolean;
  explore?: boolean;
}

function CharacterTrending({
  character,
  onClick,
  theme,
  explore,
}: CharacterProps) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleOtherUserClick = (event: React.MouseEvent) => {
    if (!isAuthenticated) {
      loginWithRedirect({
        appState: {
          returnTo: `/profile/${encodeURIComponent(character.creator)}`,
        },
      });
    }

    event.stopPropagation();
    navigate(`/profile/${encodeURIComponent(character.creator)}`);
  };

  const context = useContext(PlatformContext);
  if (!context) {
    throw new Error(
      "useContext must be used within a PlatformContext.Provider"
    );
  }

  function formatInteractionsCount(count: number) {
    if (count < 1000) return count;
    return (count / 1000).toFixed(count % 1000 === 0 ? 0 : 1) + "k";
  }

  return (
    <motion.div
      whileHover={{ scale: 1.05 }}
      style={{ perspective: 1000 }}
      whileTap={{ scale: 0.95 }}
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
    >
      <div
        className="trending-character-card fadeInUp !w-full relative overflow-hidden"
        key={character.character_id}
        onClick={onClick}
      >
        <img
          src={`${process.env.REACT_APP_ASSETS_BUCKET}${character.character_id}/display_picture.jpg`}
          alt={character.name}
          onLoad={() => setIsLoaded(true)}
          onError={(e) => {
            const target = e.target as HTMLImageElement;
            target.onerror = null;
            target.src =
              "https://4thwall-assets.s3.amazonaws.com/default_assets/display_picture.jpg";
          }}
          className="w-full h-full object-cover "
          style={{
            filter: "contrast(1.1) saturate(1.1) brightness(1.04)",
          }}
        />
        <div className="absolute inset-0 z-20 font-main">
          <div className="flex flex-col justify-end h-full p-3">
            <h3 className="md:text-[1rem] text-[0.8rem] font-bold truncate m-0 text-orange-100">
              {character.name}
            </h3>
            <AnimatePresence>
              {(isMobile || isHovered) && (
                <motion.p
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: "auto" }}
                  exit={{ opacity: 0, height: 0 }}
                  transition={{ duration: 0.3 }}
                  className="md:text-[0.6rem] text-[0.5rem] text-orange-300 overflow-hidden"
                >
                  {character.bio || "\u00A0"}
                </motion.p>
              )}
            </AnimatePresence>
            <div className="flex justify-between items-center md:text-[0.6em] text-[0.5rem] text-orange-400 ">
              <div
                className="flex items-center space-x-1 cursor-pointer"
                onClick={handleOtherUserClick}
              >
                <motion.div
                  whileHover={{ rotate: 360 }}
                  transition={{ duration: 0.5 }}
                >
                  <Avatar
                    className="!w-3 !h-3 bg-neutral-700 !my-2 !text-white"
                    style={{
                      background:
                        "linear-gradient(45deg, #FF8A65, #FF8A65, #FFB74D)",
                    }}
                    src={`${process.env.REACT_APP_ASSETS_BUCKET}users/${character.creator}/display_picture.jpg`}
                  />
                </motion.div>
                <p className="hover:font-bold">{character.creator}</p>
              </div>

              <div className="flex items-center space-x-1">
                <MessageSquareText size={14} />
                <span>{formatInteractionsCount(character.interactions)}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default CharacterTrending;
