import React, { useState, useEffect, useContext } from "react";
import {
  Textarea,
  FormControl,
  FormLabel,
  Button,
  Box,
  FormHelperText,
  Divider,
  Alert,
  Snackbar,
} from "@mui/joy";
import InfoBubble from "./InfoBubble";
import { CircleMinus, CirclePlus } from "lucide-react";
import { PlatformContext } from "../../contexts/PlatformContext";
interface ConversationTurn {
  char: string;
  user: string;
}

interface ExampleConvosProps {
  label: string;
  infoText: string;
  charKey: string;
  size: number;
  maxLength: number;
  value: string;
  subText: string;
  onChange: (value: string) => void;
  errors: { [key: string]: boolean };
}

const ExampleConvoInput: React.FC<ExampleConvosProps> = ({
  label,
  infoText,
  charKey,
  size,
  maxLength,
  value,
  subText,
  onChange,
  errors,
}) => {
  const [turns, setTurns] = useState<ConversationTurn[]>([]);
  const [newTurn, setNewTurn] = useState<ConversationTurn>({
    char: "",
    user: "",
  });
  const [totalLength, setTotalLength] = useState(0);
  //   const [showAlert, setShowAlert] = useState(false);
  const context = useContext(PlatformContext);
  if (!context) {
    throw new Error(
      "usePlatformContext must be used within a PlatformProvider"
    );
  }
  const { showAlert, closeAlert, alertInfo } = context;
  useEffect(() => {
    if (value) {
      const parsedTurns = value
        .split("\n\n")
        .map((turn) => {
          const [char, user] = turn.split("\n");
          return {
            char: char?.replace("{{character}}: ", "").trim() || "",
            user: user?.replace("{{user}}: ", "").trim() || "",
          };
        })
        .filter((turn) => turn.char !== "" || turn.user !== "");
      setTurns(parsedTurns.slice(0, 10));
      setTotalLength(value.length);
    } else {
      setTurns([]);
      setTotalLength(0);
    }
  }, [value]);

  const addTurn = () => {
    if (newTurn.char.trim() || newTurn.user.trim()) {
      const updatedTurns = [...turns, newTurn].slice(0, 10);
      setTurns(updatedTurns);
      setNewTurn({ char: "", user: "" });
      updateValue(updatedTurns);
    }
  };

  const removeTurn = (index: number) => {
    const updatedTurns = turns.filter((_, i) => i !== index);
    setTurns(updatedTurns);
    updateValue(updatedTurns);
  };

  const updateTurn = (index: number, field: "char" | "user", value: string) => {
    const updatedTurns = turns.map((turn, i) =>
      i === index ? { ...turn, [field]: value } : turn
    );
    setTurns(updatedTurns);
    updateValue(updatedTurns);
  };

  const updateValue = (updatedTurns: ConversationTurn[]) => {
    const updatedValue = updatedTurns
      .map((turn) => `{{character}}: ${turn.char}\n{{user}}: ${turn.user}`)
      .join("\n\n");

    if (updatedValue.length <= maxLength) {
      onChange(updatedValue);
      setTotalLength(updatedValue.length);
      closeAlert();
    } else {
      const truncatedValue = updatedValue.slice(0, maxLength);
      onChange(truncatedValue);
      setTotalLength(maxLength);
      showAlert(
        "You've reached the maximum character limit. Some content may have been truncated.",
        "danger"
      );
    }
  };

  return (
    <Box className="!mx-1 !my-3 !font-main !text-white">
      <FormLabel className="!text-lg !font-main !font-bold !text-white !my-2">
        {label}
        {infoText !== "" && <InfoBubble title={label} infoText={infoText} />}
      </FormLabel>

      <Snackbar
        open={alertInfo.open}
        autoHideDuration={3000}
        onClose={closeAlert}
      >
        <Alert color="warning" className="mb-4">
          {alertInfo.message}
        </Alert>
      </Snackbar>

      {turns.length > 0 && (
        <>
          <Box className="!bg-neutral-900/70 !p-4 !rounded-md">
            {turns.map((turn, index) => (
              <Box key={index} className="mb-4 flex ">
                <Box className="!flex-grow ">
                  <Box className="!mb-2 !flex !items-center">
                    <Box className="w-16 mr-2 text-orange-300">User:</Box>
                    <Textarea
                      className="!text-white !bg-neutral-700 !shadow-md !shadow-white-200 !flex-grow !border-none"
                      color="warning"
                      minRows={1}
                      value={turn.user}
                      onChange={(e) =>
                        updateTurn(index, "user", e.target.value)
                      }
                    />
                  </Box>
                  <Box className="!flex !items-center">
                    <Box className="w-16 mr-2 text-orange-300">Char:</Box>
                    <Textarea
                      className="!text-white !bg-neutral-700 !shadow-md !shadow-white-200 !flex-grow !border-none"
                      color="warning"
                      minRows={1}
                      value={turn.char}
                      onChange={(e) =>
                        updateTurn(index, "char", e.target.value)
                      }
                    />
                  </Box>
                </Box>
                <Button
                  className="!bg-red-500 !text-white ml-2 h-full items-center !m-4"
                  onClick={() => removeTurn(index)}
                >
                  <CircleMinus />
                </Button>
              </Box>
            ))}
          </Box>
          <Divider color="warning" className="!my-4 !bg-orange-300" />
        </>
      )}

      <Box className="mb-4 flex">
        <Box className="flex-grow">
          <Box className="!mb-2 !flex !items-center">
            <Box className="w-16 mr-2 text-orange-100">User:</Box>
            <Textarea
              className="!text-white !bg-neutral-700 !shadow-md !shadow-white-200 !flex-grow !border-none"
              color="warning"
              minRows={1}
              value={newTurn.user}
              onChange={(e) => setNewTurn({ ...newTurn, user: e.target.value })}
            />
          </Box>
          <Box className="!flex !items-center">
            <Box className="w-16 mr-2 text-orange-100">Char:</Box>
            <Textarea
              className="!text-white !bg-neutral-700 !shadow-md !shadow-white-200 !flex-grow !border-none"
              color="warning"
              minRows={1}
              value={newTurn.char}
              onChange={(e) => setNewTurn({ ...newTurn, char: e.target.value })}
            />
          </Box>
        </Box>
        <Button
          className="!bg-orange-500 !text-white ml-2 h-full items-center !m-4 disabled:opacity-50 disabled:cursor-not-allowed"
          onClick={addTurn}
          disabled={
            newTurn.char.trim() === "" ||
            newTurn.user.trim() === "" ||
            turns.length >= 10 ||
            totalLength >= maxLength
          }
        >
          <CirclePlus />
        </Button>
      </Box>

      <FormHelperText className="!text-sm !text-orange-100 !mt-1">
        {subText} (Maximum 10 turns allowed, {totalLength}/{maxLength}{" "}
        characters used)
      </FormHelperText>

      {errors[charKey] && (
        <FormHelperText className="text-red-500 text-sm mt-1">
          Example conversations cannot be empty
        </FormHelperText>
      )}
    </Box>
  );
};

export default ExampleConvoInput;
