import { SpritesheetData } from './types';

export const data: SpritesheetData = {
  frames: {
    left: {
      frame: { x: 0, y: 576, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 },
    },
    left2: {
      frame: { x: 64, y: 576, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 },
    },
    left3: {
      frame: { x: 128, y: 576, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 },
    },
    left4: {
      frame: { x: 192, y: 576, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 },
    },
    left5: {
      frame: { x: 256, y: 576, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 },
    },
    left6: {
      frame: { x: 320, y: 576, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 },
    },
    left7: {
      frame: { x: 384, y: 576, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 },
    },
    left8: {
      frame: { x: 384, y: 576, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 },
    },
    right: {
      frame: { x: 0, y: 704, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 },
    },
    right2: {
      frame: { x: 64, y: 704, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 },
    },
    right3: {
      frame: { x: 128, y: 704, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 },
    },
    right4: {
      frame: { x: 192, y: 704, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 },
    },
    right5: {
      frame: { x: 256, y: 704, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 },
    },
    right6: {
      frame: { x: 320, y: 704, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 },
    },
    right7: {
      frame: { x: 384, y: 704, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 },
    },
    right8: {
      frame: { x: 384, y: 704, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 },
    },
    up: {
      frame: { x: 0, y: 512, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 },
    },
    up2: {
      frame: { x: 64, y: 512, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 },
    },
    up3: {
      frame: { x: 128, y: 512, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 },
    },
    up4: {
      frame: { x: 192, y: 512, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 },
    },
    up5: {
      frame: { x: 256, y: 512, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 },
    },
    up6: {
      frame: { x: 320, y: 512, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 },
    },
    up7: {
      frame: { x: 384, y: 512, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 },
    },
    up8: {
      frame: { x: 384, y: 512, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 },
    },
    down: {
      frame: { x: 0, y: 640, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 },
    },
    down2: {
      frame: { x: 64, y: 640, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 },
    },
    down3: {
      frame: { x: 128, y: 640, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 },
    },
    down4: {
      frame: { x: 192, y: 640, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 },
    },
    down5: {
      frame: { x: 256, y: 640, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 },
    },
    down6: {
      frame: { x: 320, y: 640, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 },
    },
    down7: {
      frame: { x: 384, y: 640, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 },
    },
    down8: {
      frame: { x: 384, y: 640, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 },
    },
    idleUp:{
      frame: { x: 0, y: 0, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 },
    },
    idleUp2:{
      frame: { x: 64, y: 0, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 },
    },
    idleLeft:{
      frame: { x: 0, y: 64, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 },
    },
    idleLeft2:{
      frame: { x: 64, y: 64, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 },
    },
    idleDown:{
      frame: { x: 0, y: 128, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 },
    },
    idleDown2:{
      frame: { x: 32, y: 128, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 },
    },
    idleRight:{
      frame: { x: 0, y: 192, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 },
    },
    idleRight2:{
      frame: { x: 64, y: 192, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 },
    },
    sit1:{
      frame: { x: 0, y: 2048, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 },
    },
    idleStandDown1:{
      frame: { x: 128, y: 2048, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 },
    },
    idleStandDown2:{
      frame: { x: 192, y: 2048, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 },
    },
    idleStandDown3:{
      frame: { x: 256, y: 2048, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 },
    },
    idleStandUp1:{
      frame: { x: 128, y: 1920, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 },
    },
    idleStandUp2:{
      frame: { x: 192, y: 1920, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 },
    },
    idleStandUp3:{
      frame: { x: 256, y: 1920, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 },
    },
    idleStandLeft1:{
      frame: { x: 128, y: 1984, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 },
    },
    idleStandLeft2:{
      frame: { x: 192, y: 1984, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 },
    },
    idleStandLeft3:{
      frame: { x: 256, y: 1984, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 },
    },
    idleStandRight1:{
      frame: { x: 128, y: 2112, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 },
    },
    idleStandRight2:{
      frame: { x: 192, y: 2112, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 },
    },
    idleStandRight3:{
      frame: { x: 256, y: 2112, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 },
    },///////////////////////////////
    magicUp0: {
      frame: { x: 0, y: 0, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 },
    },
    magicUp1: {
      frame: { x: 64, y: 0, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 },
    },
    magicUp2: {
      frame: { x: 128, y: 0, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 },
    },
    magicUp3: {
      frame: { x: 192, y: 0, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 },
    },
    magicUp4: {
      frame: { x: 256, y: 0, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 },
    },
    magicUp5: {
      frame: { x: 320, y: 0, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 },
    },
    magicUp6: {
      frame: { x: 384, y: 0, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 },
    },
    magicLeft0:{
      frame: { x: 0, y: 64, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    magicLeft1:{
      frame: { x: 64, y: 64, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    magicLeft2:{
      frame: { x: 128, y: 64, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    magicLeft3:{
      frame: { x: 192, y: 64, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    magicLeft4:{
      frame: { x: 256, y: 64, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    magicLeft5:{
      frame: { x: 320, y: 64, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    magicLeft6:{
      frame: { x: 384, y: 64, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    magicDown0:{
      frame: { x: 0, y: 128, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    magicDown1:{
      frame: { x: 64, y: 128, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    magicDown2:{
      frame: { x: 128, y: 128, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    magicDown3:{
      frame: { x: 192, y: 128, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    magicDown4:{
      frame: { x: 256, y: 128, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    magicDown5:{
      frame: { x: 320, y: 128, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    magicDown6:{
      frame: { x: 384, y: 128, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    magicRight0:{
      frame: { x: 0, y: 192, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    magicRight1:{
      frame: { x: 64, y: 192, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    magicRight2:{
      frame: { x: 128, y: 192, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    magicRight3:{
      frame: { x: 192, y: 192, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    magicRight4:{
      frame: { x: 256, y: 192, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    magicRight5:{
      frame: { x: 320, y: 192, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    magicRight6:{
      frame: { x: 384, y: 192, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    wateringUp0:{
      frame: { x: 0, y: 256, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    wateringUp1:{
      frame: { x: 64, y: 256, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    wateringUp2:{
      frame: { x: 128, y: 256, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    wateringUp3:{
      frame: { x: 192, y: 256, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    wateringUp4:{
      frame: { x: 256, y: 256, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    wateringUp5:{
      frame: { x: 320, y: 256, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    wateringUp6:{
      frame: { x: 384, y: 256, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    wateringUp7:{
      frame: { x: 448, y: 256, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    wateringLeft0:{
      frame: { x: 0, y: 320, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    wateringLeft1:{
      frame: { x: 64, y: 320, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    wateringLeft2:{
      frame: { x: 128, y: 320, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    wateringLeft3:{
      frame: { x: 192, y: 320, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    wateringLeft4:{
      frame: { x: 256, y: 320, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    wateringLeft5:{
      frame: { x: 320, y: 320, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    wateringLeft6:{
      frame: { x: 384, y: 320, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    wateringLeft7:{
      frame: { x: 448, y: 320, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    wateringDown0:{
      frame: { x: 0, y: 384, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    wateringDown1:{
      frame: { x: 64, y: 384, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    wateringDown2:{
      frame: { x: 128, y: 384, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    wateringDown3:{
      frame: { x: 192, y: 384, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    wateringDown4:{
      frame: { x: 256, y: 384, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    wateringDown5:{
      frame: { x: 320, y: 384, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    wateringDown6:{
      frame: { x: 384, y: 384, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    wateringDown7:{
      frame: { x: 448, y: 384, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    wateringRight0:{
      frame: { x: 0, y: 448, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    wateringRight1:{
      frame: { x: 64, y: 448, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    wateringRight2:{
      frame: { x: 128, y: 448, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    wateringRight3:{
      frame: { x: 192, y: 448, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    wateringRight4:{
      frame: { x: 256, y: 448, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    wateringRight5:{
      frame: { x: 320, y: 448, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    wateringRight6:{
      frame: { x: 384, y: 448, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    wateringRight7:{
      frame: { x: 448, y: 448, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    slashUp0:{
      frame: { x: 0, y: 768, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    slashUp1:{
      frame: { x: 64, y: 768, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    slashUp2:{
      frame: { x: 128, y: 768, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    slashUp3:{
      frame: { x: 192, y: 768, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    slashUp4:{
      frame: { x: 256, y: 768, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    slashUp5:{
      frame: { x: 320, y: 768, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    slashLeft0:{
      frame: { x: 0, y: 832, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    slashLeft1:{
      frame: { x: 64, y: 832, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    slashLeft2:{
      frame: { x: 128, y: 832, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    slashLeft3:{
      frame: { x: 192, y: 832, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    slashLeft4:{
      frame: { x: 256, y: 832, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    slashLeft5:{
      frame: { x: 320, y: 832, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    slashDown0:{
      frame: { x: 0, y: 896, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    slashDown1:{
      frame: { x: 64, y: 896, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    slashDown2:{
      frame: { x: 128, y: 896, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    slashDown3:{
      frame: { x: 192, y: 896, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    slashDown4:{
      frame: { x: 256, y: 896, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    slashDown5:{
      frame: { x: 320, y: 896, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    slashRight0:{
      frame: { x: 0, y: 960, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    slashRight1:{
      frame: { x: 64, y: 960, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    slashRight2:{
      frame: { x: 128, y: 960, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    slashRight3:{
      frame: { x: 192, y: 960, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    slashRight4:{
      frame: { x: 256, y: 960, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    slashRight5:{
      frame: { x: 320, y: 960, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    shootUp0:{
      frame: { x: 0, y: 1024, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    shootUp1:{
      frame: { x: 64, y: 1024, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    shootUp2:{
      frame: { x: 128, y: 1024, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    shootUp3:{
      frame: { x: 192, y: 1024, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    shootUp4:{
      frame: { x: 256, y: 1024, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    shootUp5:{
      frame: { x: 320, y: 1024, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    shootUp6:{
      frame: { x: 384, y: 1024, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    shootUp7:{
      frame: { x: 448, y: 1024, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    shootUp8:{
      frame: { x: 512, y: 1024, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    shootUp9:{
      frame: { x: 576, y: 1024, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    shootUp10:{
      frame: { x: 640, y: 1024, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    shootUp11:{
      frame: { x: 704, y: 1024, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    shootUp12:{
      frame: { x: 768, y: 1024, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    shootLeft0:{
      frame: { x: 0, y: 1088, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    shootLeft1:{
      frame: { x: 64, y: 1088, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    shootLeft2:{
      frame: { x: 128, y: 1088, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    shootLeft3:{
      frame: { x: 192, y: 1088, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    shootLeft4:{
      frame: { x: 256, y: 1088, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    shootLeft5:{
      frame: { x: 320, y: 1088, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    shootLeft6:{
      frame: { x: 384, y: 1088, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    shootLeft7:{
      frame: { x: 448, y: 1088, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    shootLeft8:{
      frame: { x: 512, y: 1088, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    shootLeft9:{
      frame: { x: 576, y: 1088, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    shootLeft10:{
      frame: { x: 640, y: 1088, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    shootLeft11:{
      frame: { x: 704, y: 1088, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    shootLeft12:{
      frame: { x: 768, y: 1088, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    shootDown0:{
      frame: { x: 0, y: 1152, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    shootDown1:{
      frame: { x: 64, y: 1152, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    shootDown2:{
      frame: { x: 128, y: 1152, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    shootDown3:{
      frame: { x: 192, y: 1152, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    shootDown4:{
      frame: { x: 256, y: 1152, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    shootDown5:{
      frame: { x: 320, y: 1152, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    shootDown6:{
      frame: { x: 384, y: 1152, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    shootDown7:{
      frame: { x: 448, y: 1152, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    shootDown8:{
      frame: { x: 512, y: 1152, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    shootDown9:{
      frame: { x: 576, y: 1152, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    shootDown10:{
      frame: { x: 640, y: 1152, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    shootDown11:{
      frame: { x: 704, y: 1152, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    shootDown12:{
      frame: { x: 768, y: 1152, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    shootRight0:{
      frame: { x: 0, y: 1216, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    shootRight1:{
      frame: { x: 64, y: 1216, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    shootRight2:{
      frame: { x: 128, y: 1216, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    shootRight3:{
      frame: { x: 192, y: 1216, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    shootRight4:{
      frame: { x: 256, y: 1216, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    shootRight5:{
      frame: { x: 320, y: 1216, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    shootRight6:{
      frame: { x: 384, y: 1216, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    shootRight7:{
      frame: { x: 448, y: 1216, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    shootRight8:{
      frame: { x: 512, y: 1216, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    shootRight9:{
      frame: { x: 576, y: 1216, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    shootRight10:{
      frame: { x: 640, y: 1216, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    shootRight11:{
      frame: { x: 704, y: 1216, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    shootRight12:{
      frame: { x: 768, y: 1216, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    hurt0:{
      frame: { x: 0, y: 1280, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    hurt1:{
      frame: { x: 64, y: 1280, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    hurt2:{
      frame: { x: 128, y: 1280, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    hurt3:{
      frame: { x: 192, y: 1280, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    hurt4:{
      frame: { x: 256, y: 1280, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    hurt5:{
      frame: { x: 320, y: 1280, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    climb0:{
      frame: { x: 0, y: 1344, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    climb1:{
      frame: { x: 64, y: 1344, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    climb2:{
      frame: { x: 128, y: 1344, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    climb3:{
      frame: { x: 192, y: 1344, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    climb4:{
      frame: { x: 256, y: 1344, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    climb5:{
      frame: { x: 320, y: 1344, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    jumpUp0:{
      frame: { x: 0, y: 1664, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    jumpUp1:{
      frame: { x: 64, y: 1664, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    jumpUp2:{
      frame: { x: 128, y: 1664, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    jumpUp3:{
      frame: { x: 192, y: 1664, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    jumpUp4:{
      frame: { x: 256, y: 1664, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    jumpLeft0:{
      frame: { x: 0, y: 1728, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    jumpLeft1:{
      frame: { x: 64, y: 1728, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    jumpLeft2:{
      frame: { x: 128, y: 1728, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    jumpLeft3:{
      frame: { x: 192, y: 1728, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    jumpLeft4:{
      frame: { x: 256, y: 1728, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    jumpDown0:{
      frame: { x: 0, y: 1792, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    jumpDown1:{
      frame: { x: 64, y: 1792, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    jumpDown2:{
      frame: { x: 128, y: 1792, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    jumpDown3:{
      frame: { x: 192, y: 1792, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    jumpDown4:{
      frame: { x: 256, y: 1792, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    jumpRight0:{
      frame: { x: 0, y: 1856, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    jumpRight1:{
      frame: { x: 64, y: 1856, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    jumpRight2:{
      frame: { x: 128, y: 1856, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    jumpRight3:{
      frame: { x: 192, y: 1856, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    jumpRight4:{
      frame: { x: 256, y: 1856, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
    invisible:{
      frame: { x: 448, y: 64, w: 64, h: 64 },
      sourceSize: { w: 64, h: 64 },
      spriteSourceSize: { x: 0, y: 0 }
    },
        
      
        
        
        
        
        
        
    },
  meta: {
    scale: '1',
  },
  animations: {
    left: ['left', 'left2', 'left3', 'left4', 'left5', 'left6', 'left7', 'left8'],
    right: ['right', 'right2', 'right3', 'right4', 'right5', 'right6', 'right7', 'right8'],
    up: ['up', 'up2', 'up3', 'up4', 'up5', 'up6', 'up7', 'up8' ],
    down: ['down', 'down2', 'down3', 'down4', 'down5', 'down6','down7', 'down8' ],
    fall: ['fall', 'fall2', 'fall3','fall4','fall5','fall6'],
    idleup:['up'],
    idleleft:['left'],
    idleright:['right'],
    idledown:['down'],
    idlestanddown1:['idleStandDown1'],
    idlestanddown2:['idleStandDown2'],
    idlestanddown3:['idleStandDown3'],
    idlestandup1:['idleStandUp1'],
    idlestandup2:['idleStandUp2'],
    idlestandup3:['idleStandUp3'],
    idlestandleft1:['idleStandLeft1'],
    idlestandleft2:['idleStandLeft2'],
    idlestandleft3:['idleStandLeft3'],
    idlestandright1:['idleStandRight1'],
    idlestandright2:['idleStandRight2'],
    idlestandright3:['idleStandRight3'],
    magicup:['magicUp0', 'magicUp1', 'magicUp2', 'magicUp3', 'magicUp4', 'magicUp5', 'magicUp6'],
    magicdown:['magicDown0', 'magicDown1', 'magicDown2', 'magicDown3', 'magicDown4', 'magicDown5', 'magicDown6'],
    magicleft:['magicLeft0', 'magicLeft1', 'magicLeft2', 'magicLeft3', 'magicLeft4', 'magicLeft5', 'magicLeft6'],
    magicright:['magicRight0', 'magicRight1', 'magicRight2', 'magicRight3', 'magicRight4', 'magicRight5', 'magicRight6'],
    wateringup: ['wateringUp0', 'wateringUp1', 'wateringUp2', 'wateringUp3', 'wateringUp4', 'wateringUp5', 'wateringUp6', 'wateringUp7'],
    wateringleft: ['wateringLeft0', 'wateringLeft1', 'wateringLeft2', 'wateringLeft3', 'wateringLeft4', 'wateringLeft5', 'wateringLeft6', 'wateringLeft7'],
    wateringdown: ['wateringDown0' ,'wateringDown1', 'wateringDown2', 'wateringDown3', 'wateringDown4', 'wateringDown5', 'wateringDown6', 'wateringDown7'],
    wateringright: ['wateringRight0', 'wateringRight1', 'wateringRight2', 'wateringRight3', 'wateringRight4', 'wateringRight5', 'wateringRight6', 'wateringRight7'],
    slashup: ['slashUp0', 'slashUp1', 'slashUp2', 'slashUp3', 'slashUp4', 'slashUp5'],
    slashleft: ['slashLeft0', 'slashLeft1', 'slashLeft2', 'slashLeft3', 'slashLeft4', 'slashLeft5'],
    slashdown: ['slashDown0', 'slashDown1', 'slashDown2', 'slashDown3', 'slashDown4', 'slashDown5'],
    slashright: ['slashRight0', 'slashRight1', 'slashRight2', 'slashRight3', 'slashRight4', 'slashRight5'],
    shootup: ['shootUp0', 'shootUp1', 'shootUp2', 'shootUp3', 'shootUp4', 'shootUp5', 'shootUp6', 'shootUp7', 'shootUp8', 'shootUp9', 'shootUp10', 'shootUp11', 'shootUp12'],
    shootleft: ['shootLeft0', 'shootLeft1', 'shootLeft2', 'shootLeft3', 'shootLeft4', 'shootLeft5', 'shootLeft6', 'shootLeft7', 'shootLeft8', 'shootLeft9', 'shootLeft10', 'shootLeft11', 'shootLeft12'],
    shootdown: ['shootDown0', 'shootDown1', 'shootDown2', 'shootDown3', 'shootDown4', 'shootDown5', 'shootDown6', 'shootDown7', 'shootDown8', 'shootDown9', 'shootDown10', 'shootDown11', 'shootDown12'],
    shootright: ['shootRight0', 'shootRight1', 'shootRight2', 'shootRight3', 'shootRight4', 'shootRight5', 'shootRight6', 'shootRight7', 'shootRight8', 'shootRight9', 'shootRight10', 'shootRight11', 'shootRight12'],
    hurt: ['hurt0', 'hurt1', 'hurt2', 'hurt3', 'hurt4', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5','hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5','hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5', 'hurt5'],
    climb: ['climb0', 'climb1', 'climb2', 'climb3', 'climb4', 'climb5'],
    jumpup: ['jumpUp0', 'jumpUp1', 'jumpUp2', 'jumpUp3', 'jumpUp4'],
    jumpleft: ['jumpLeft0', 'jumpLeft1', 'jumpLeft2', 'jumpLeft3', 'jumpLeft4'],
    jumpdown: ['jumpDown0', 'jumpDown1', 'jumpDown2', 'jumpDown3', 'jumpDown4'],
    jumpright: ['jumpRight0', 'jumpRight1', 'jumpRight2', 'jumpRight3', 'jumpRight4'],
    dance: ['magicDown0', 'magicDown1', 'magicDown2', 'magicDown3', 'magicDown4', 'magicDown5', 'magicDown6', 'wateringDown0' ,'wateringDown1', 'wateringDown2', 'wateringDown3', 'wateringDown4', 'wateringDown5', 'wateringDown6', 'wateringDown7','wateringLeft0', 'wateringLeft1', 'wateringLeft2', 'wateringLeft3', 'wateringLeft4', 'wateringLeft5', 'wateringLeft6', 'wateringLeft7', 'wateringRight0', 'wateringRight1', 'wateringRight2', 'wateringRight3', 'wateringRight4', 'wateringRight5', 'wateringRight6', 'wateringRight7','magicLeft0', 'magicLeft1', 'magicLeft2', 'magicLeft3', 'magicLeft4', 'magicLeft5', 'magicLeft6', 'magicRight0', 'magicRight1', 'magicRight2', 'magicRight3', 'magicRight4', 'magicRight5', 'magicRight6', 'slashRight0', 'slashRight1', 'slashUp0', 'slashUp1', 'slashLeft0', 'slashLeft1', 'slashDown0', 'slashDown1', 'slashDown2', 'slashDown3', 'slashDown4', 'slashDown5', 'slashDown0', 'slashDown1', 'slashDown2', 'slashDown3', 'slashDown4', 'slashDown5', 'down', 'down2', 'down3', 'down4', 'hurt0', 'hurt1', 'hurt2', 'hurt3', 'hurt4', 'hurt5', 'hurt4', 'hurt3', 'hurt2','hurt1', 'hurt0', 'shootUp0', 'shootUp1', 'shootUp2', 'shootUp3', 'shootUp4', 'shootUp5', 'shootUp6', 'shootUp7', 'shootUp8', 'shootUp9', 'shootUp10', 'shootUp11', 'shootUp12', 'shootLeft0', 'shootLeft1', 'shootLeft2', 'shootLeft3', 'shootLeft4', 'shootLeft5', 'shootLeft6', 'shootLeft7', 'shootLeft8', 'shootLeft9', 'shootLeft10', 'shootLeft11', 'shootLeft12', 'shootRight0', 'shootRight1', 'shootRight2', 'shootRight3', 'shootRight4', 'shootRight5', 'shootRight6', 'shootRight7', 'shootRight8', 'shootRight9', 'shootRight10', 'shootRight11', 'shootRight12', 'shootDown0', 'shootDown1', 'shootDown2', 'shootDown3', 'shootDown4', 'shootDown5', 'shootDown6', 'shootDown7', 'shootDown8', 'shootDown9', 'shootDown10', 'shootDown11', 'shootDown12', 'hurt0', 'hurt1', 'hurt2', 'hurt3', 'hurt4', 'hurt3', 'hurt2', 'hurt3', 'hurt4', 'hurt3', 'hurt2', 'hurt1', 'hurt0'],
    invisible: ['invisible']
  },
};
