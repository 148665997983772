import React, { useState } from "react";
import { PersonaDataProps } from "../types";
export interface ChatContextState {
  chatId: string;
  setChatId: (chat_id: string) => void;
  character_id: string;
  activePersonaId: string;
  setActivePersonaId: (persona_id: string) => void;
  activePersona: PersonaDataProps;
  setActivePersona: (persona: PersonaDataProps) => void;
  scenario: string;
  setScenario: (scenario: string) => void;
}
export const ChatContext = React.createContext<ChatContextState | undefined>(
  undefined
);
